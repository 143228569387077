import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-six',
  templateUrl: './merfx-sub-page-six.component.html',
  styleUrls: ['./merfx-sub-page-six.component.css']
})
export class MerfxSubPageSixComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageSixComponent) {
  context.title = 'Elevate Customer Engagement and Optimize Online Experience';
  context.description = 'Enhance customer interactions and drive growth with our Customer Management System. Seamlessly manage leads, contracts, and data for improved sales efficiency and satisfaction. Optimize the online journey with user-friendly interfaces and streamline incident resolution with our Incident Management System.';
  context.keywords = 'customer engagement, online experience, customer management, lead management, contract handling, customer data, sales efficiency, customer satisfaction, business growth, CRM tools, personalized interactions, seamless experience, customer retention, lead nurturing, contract workflows, customer feedback, incident management, operational efficiency, issue resolution, customer journey, website optimization, intuitive interface, user-friendly, customer portal.';
  context.seoTags();
}
