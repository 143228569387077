import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-big-data-consultant',
  templateUrl: './big-data-consultant.component.html',
  styleUrls: ['./big-data-consultant.component.css']
})
export class BigDataConsultantComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BigDataConsultantComponent) {  
  context.title = 'Big Data Consultant Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `Join SignitiveAi as a Big Data Consultant. Join hands-on projects, mentor a dynamic team, and engage in high-level client discussions. 7+ years of Informatica tools experience required. Elevate your career with us!`;
  context.keywords = 'SignitiveAi ,Big Data Consultant, Think Big initiative, Informatica tools, ETL/MDM/DQ, MR1 YARN applications, Java, Scala, Python, Hive and Impala scripts, Business Intelligence, Business Analytics, Global environment, Financial data, Equal opportunity employer, Career growth, Technical mastery, Business Digital Transformation';
  context.seoTags();
}