import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Paths } from '@constants';
import { FeaturesEntryComponent } from './components';
import { AboutUsComponent, EsgComponent, OurValuesComponent, WhoWeAreComponent } from './modules/about/components';
import { ContactUsComponent, ThankYouComponent } from './modules/contact/components';
import { PrivacyPolicyComponent } from './modules/privacy/components';
import { TermsOfUseComponent } from './modules/terms/components';
import { CookiesPolicyComponent } from './modules/cookies/components';
import { BlogDetailsB10822Component, BlogDetailsB11123Component, BlogDetailsB1223Component, BlogDetailsB12522Component, BlogDetailsB12723Component, BlogDetailsB1323Component, BlogDetailsB141222Component, BlogDetailsB15223Component, BlogDetailsB15323Component, BlogDetailsB15522Component, BlogDetailsB15622Component, BlogDetailsB161122Component, BlogDetailsB1622Component, BlogDetailsB17822Component, BlogDetailsB18123Component, BlogDetailsB191022Component, BlogDetailsB19522Component, BlogDetailsB21922Component, BlogDetailsB22323Component, BlogDetailsB25123Component, BlogDetailsB26522Component, BlogDetailsB28922Component, BlogDetailsB301122Component, BlogDetailsB4123Component, BlogDetailsB4522Component, BlogDetailsB4823Component, BlogDetailsB51022Component, BlogDetailsB71222Component, BlogDetailsB8223Component, BlogDetailsB8522Component, BlogDetailsB91222Component, BlogsComponent } from './modules/blogs/components';
import { NotFoundComponent } from './modules/home/components';
import { MerfxComponent, MerfxSubPageFiveComponent, MerfxSubPageFourComponent, MerfxSubPageOneComponent, MerfxSubPageSevenComponent, MerfxSubPageSixComponent, MerfxSubPageThreeComponent, MerfxSubPageTwoComponent, MortgageSolutionComponent, ProductxComponent, ProductxSubPageFourComponent, ProductxSubPageOneComponent, ProductxSubPageThreeComponent, ProductxSubPageTwoComponent } from './modules/solutions/components';
import { CareerComponent } from './modules/careers/components/career/career.component';
import { BigDataConsultantComponent } from './modules/careers/components/big-data-consultant/big-data-consultant.component';
import { BlogDetailsB30823Component } from './modules/blogs/components/blog-details-b30823/blog-details-b30823.component';
import { BlogDetailsB14823Component } from './modules/blogs/components/blog-details-b14823/blog-details-b14823.component';
import { BlogDetailsB19623Component } from './modules/blogs/components/blog-details-b19623/blog-details-b19623.component';
import { BlogDetailsB8623Component } from './modules/blogs/components/blog-details-b8623/blog-details-b8623.component';
import { BlogDetailsB17523Component } from './modules/blogs/components/blog-details-b17523/blog-details-b17523.component';
import { BlogDetailsB5523Component } from './modules/blogs/components/blog-details-b5523/blog-details-b5523.component';
import { BlogDetailsB19423Component } from './modules/blogs/components/blog-details-b19423/blog-details-b19423.component';
import { BlogDetailsB29323Component } from './modules/blogs/components/blog-details-b29323/blog-details-b29323.component';
import { BlogDetailsB8323Component } from './modules/blogs/components/blog-details-b8323/blog-details-b8323.component';
import { BlogDetailsB22223Component } from './modules/blogs/components/blog-details-b22223/blog-details-b22223.component';
import { BlogDetailsB21122Component } from './modules/blogs/components/blog-details-b21122/blog-details-b21122.component';
import { BlogDetailsB261022Component } from './modules/blogs/components/blog-details-b261022/blog-details-b261022.component';
import { BlogDetailsB7922Component } from './modules/blogs/components/blog-details-b7922/blog-details-b7922.component';
import { BlogDetailsB3822Component } from './modules/blogs/components/blog-details-b3822/blog-details-b3822.component';
import { BlogDetailsB20722Component } from './modules/blogs/components/blog-details-b20722/blog-details-b20722.component';
import { BlogDetailsB6722Component } from './modules/blogs/components/blog-details-b6722/blog-details-b6722.component';
import { BlogDetailsB28422Component } from './modules/blogs/components/blog-details-b28422/blog-details-b28422.component';
import { BlogDetailsB24422Component } from './modules/blogs/components/blog-details-b24422/blog-details-b24422.component';
import { AllJobOpeningsComponent, GolangSoftwareEngineerComponent } from './modules/careers/components';
import { QualityEngineerComponent } from './modules/careers/components/quality-engineer/quality-engineer.component';
import { UiEngineersReactjsNodejsComponent } from './modules/careers/components/ui-engineers-reactjs-nodejs/ui-engineers-reactjs-nodejs.component';
import { SrEngineerDevopsComponent } from './modules/careers/components/sr-engineer-devops/sr-engineer-devops.component';
import { ProductOwnerUkComponent } from './modules/careers/components/product-owner-uk/product-owner-uk.component';
import { DotNetDeveloperComponent } from './modules/careers/components/dot-net-developer/dot-net-developer.component';
import { SrJavaDeveloperComponent } from './modules/careers/components/sr-java-developer/sr-java-developer.component';
import { JavaDeveloperComponent } from './modules/careers/components/java-developer/java-developer.component';
import { ChaosResiliencyTestEngineerComponent } from './modules/careers/components/chaos-resiliency-test-engineer/chaos-resiliency-test-engineer.component';
import { BusinessAnalystComponent } from './modules/careers/components/business-analyst/business-analyst.component';
import { DotNetTechnicalArchitectComponent } from './modules/careers/components/dot-net-technical-architect/dot-net-technical-architect.component';
import { TraineeProjectManagerComponent } from './modules/careers/components/trainee-project-manager/trainee-project-manager.component';
import { TraineeDataAnalystComponent } from './modules/careers/components/trainee-data-analyst/trainee-data-analyst.component';
import { CustomerServiceAssociateComponent } from './modules/careers/components/customer-service-associate/customer-service-associate.component';
import { ELearningDeveloperComponent } from './modules/careers/components/e-learning-developer/e-learning-developer.component';


const routes: Routes = [
  {
    path: Paths.PATH_BLANK, component: FeaturesEntryComponent, children: [
      { path: Paths.PATH_BLANK, redirectTo: Paths.PATH_HOME, pathMatch: 'full' },
      { path: Paths.PATH_PRODUCTX_SUB_PAGE_ONE, component: ProductxSubPageOneComponent },
      { path: Paths.PATH_PRODUCTX_SUB_PAGE_TWO, component: ProductxSubPageTwoComponent },
      { path: Paths.PATH_PRODUCTX_SUB_PAGE_THREE, component: ProductxSubPageThreeComponent },
      { path: Paths.PATH_PRODUCTX_SUB_PAGE_FOUR, component: ProductxSubPageFourComponent },
      { path: Paths.PATH_HOME, loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
      { path: Paths.PATH_WHO_WE_ARE, component: WhoWeAreComponent },
      { path: Paths.PATH_OUR_VALUES, component: OurValuesComponent },
      { path: Paths.PATH_ESG, component: EsgComponent},           
      { path: Paths.ABOUT_US, component: AboutUsComponent},   
      { path: Paths.PATH_CONTACT, component: ContactUsComponent },
      { path: Paths.THANK_YOU, component: ThankYouComponent },
      { path: Paths.PATH_PRIVACY_POLICY, component: PrivacyPolicyComponent },
      { path: Paths.PATH_TERMS_OF_USE, component: TermsOfUseComponent },
      { path: Paths.PATH_COOKIES_POLICY, component: CookiesPolicyComponent },

      { path: Paths.PATH_MERFX, component: MerfxComponent },
      { path: Paths.PATH_PRODUCTX, component: ProductxComponent },
      { path: Paths.PATH_MORTGAGE_SOLUTION, component: MortgageSolutionComponent },

      { path: Paths.PATH_MERFX_ONE, component: MerfxSubPageOneComponent },
      { path: Paths.PATH_MERFX_TWO, component: MerfxSubPageTwoComponent },
      { path: Paths.PATH_MERFX_THREE, component: MerfxSubPageThreeComponent },
      { path: Paths.PATH_MERFX_FOUR, component: MerfxSubPageFourComponent },
      { path: Paths.PATH_MERFX_FIVE, component: MerfxSubPageFiveComponent },
      { path: Paths.PATH_MERFX_SIX, component: MerfxSubPageSixComponent },
      { path: Paths.PATH_MERFX_SEVEN, component: MerfxSubPageSevenComponent },

      { path: Paths.PATH_BLOGS, component: BlogsComponent },
      { path: Paths.PATH_BLOG_DETAILS1, component: BlogDetailsB30823Component },
      { path: Paths.PATH_BLOG_DETAILS2, component: BlogDetailsB14823Component },
      { path: Paths.PATH_BLOG_DETAILS3, component: BlogDetailsB4823Component },
      { path: Paths.PATH_BLOG_DETAILS4, component: BlogDetailsB12723Component },
      { path: Paths.PATH_BLOG_DETAILS5, component: BlogDetailsB19623Component },
      { path: Paths.PATH_BLOG_DETAILS6, component: BlogDetailsB8623Component },
      { path: Paths.PATH_BLOG_DETAILS7, component: BlogDetailsB17523Component },
      { path: Paths.PATH_BLOG_DETAILS8, component: BlogDetailsB5523Component },
      { path: Paths.PATH_BLOG_DETAILS9, component: BlogDetailsB19423Component },
      { path: Paths.PATH_BLOG_DETAILS10, component: BlogDetailsB29323Component },
      { path: Paths.PATH_BLOG_DETAILS11, component: BlogDetailsB22323Component },
      { path: Paths.PATH_BLOG_DETAILS12, component: BlogDetailsB15323Component },
      { path: Paths.PATH_BLOG_DETAILS13, component: BlogDetailsB8323Component },
      { path: Paths.PATH_BLOG_DETAILS14, component: BlogDetailsB1323Component },
      { path: Paths.PATH_BLOG_DETAILS15, component: BlogDetailsB22223Component },
      { path: Paths.PATH_BLOG_DETAILS16, component: BlogDetailsB15223Component },
      { path: Paths.PATH_BLOG_DETAILS17, component: BlogDetailsB8223Component },
      { path: Paths.PATH_BLOG_DETAILS18, component: BlogDetailsB1223Component },
      { path: Paths.PATH_BLOG_DETAILS19, component: BlogDetailsB25123Component },
      { path: Paths.PATH_BLOG_DETAILS20, component: BlogDetailsB18123Component },
      { path: Paths.PATH_BLOG_DETAILS21, component: BlogDetailsB11123Component },
      { path: Paths.PATH_BLOG_DETAILS22, component: BlogDetailsB4123Component },
      { path: Paths.PATH_BLOG_DETAILS23, component: BlogDetailsB141222Component },
      { path: Paths.PATH_BLOG_DETAILS24, component: BlogDetailsB91222Component },
      { path: Paths.PATH_BLOG_DETAILS25, component: BlogDetailsB71222Component },
      { path: Paths.PATH_BLOG_DETAILS26, component: BlogDetailsB301122Component },
      { path: Paths.PATH_BLOG_DETAILS27, component: BlogDetailsB161122Component },
      { path: Paths.PATH_BLOG_DETAILS28, component: BlogDetailsB21122Component },
      { path: Paths.PATH_BLOG_DETAILS29, component: BlogDetailsB261022Component },
      { path: Paths.PATH_BLOG_DETAILS30, component: BlogDetailsB191022Component },
      { path: Paths.PATH_BLOG_DETAILS31, component: BlogDetailsB51022Component },
      { path: Paths.PATH_BLOG_DETAILS32, component: BlogDetailsB28922Component },
      { path: Paths.PATH_BLOG_DETAILS33, component: BlogDetailsB21922Component },
      { path: Paths.PATH_BLOG_DETAILS34, component: BlogDetailsB7922Component },
      { path: Paths.PATH_BLOG_DETAILS35, component: BlogDetailsB17822Component },
      { path: Paths.PATH_BLOG_DETAILS36, component: BlogDetailsB10822Component },
      { path: Paths.PATH_BLOG_DETAILS37, component: BlogDetailsB3822Component },
      { path: Paths.PATH_BLOG_DETAILS38, component: BlogDetailsB20722Component },
      { path: Paths.PATH_BLOG_DETAILS39, component: BlogDetailsB6722Component },
      { path: Paths.PATH_BLOG_DETAILS40, component: BlogDetailsB15622Component },
      { path: Paths.PATH_BLOG_DETAILS41, component: BlogDetailsB1622Component },
      { path: Paths.PATH_BLOG_DETAILS42, component: BlogDetailsB26522Component },
      { path: Paths.PATH_BLOG_DETAILS43, component: BlogDetailsB19522Component },
      { path: Paths.PATH_BLOG_DETAILS44, component: BlogDetailsB15522Component },
      { path: Paths.PATH_BLOG_DETAILS45, component: BlogDetailsB12522Component },
      { path: Paths.PATH_BLOG_DETAILS46, component: BlogDetailsB8522Component },
      { path: Paths.PATH_BLOG_DETAILS47, component: BlogDetailsB4522Component },
      { path: Paths.PATH_BLOG_DETAILS48, component: BlogDetailsB28422Component },
      { path: Paths.PATH_BLOG_DETAILS49, component: BlogDetailsB24422Component },

      { path: Paths.PATH_CAREER, component: CareerComponent },
      { path: Paths.ALL_JOB_OPENINGS, component: AllJobOpeningsComponent},
      { path: Paths.PATH_QUALITY_ENGINEER, component: QualityEngineerComponent },
      { path: Paths.PATH_GOLANG_SOFTWARE_ENGINEER, component: GolangSoftwareEngineerComponent },
      { path: Paths.PATH_UI_ENGINEERS_REACTJS_NODEJS, component: UiEngineersReactjsNodejsComponent },
      { path: Paths.PATH_SR_ENGINEER_DEVOPS, component: SrEngineerDevopsComponent },
      { path: Paths.PATH_PRODUCT_OWNER_UK, component: ProductOwnerUkComponent },
      { path: Paths.BIG_DATA_CONSULTANT, component: BigDataConsultantComponent },
      { path: Paths.PATH_DOT_NET_DEVELOPER, component: DotNetDeveloperComponent },
      { path: Paths.PATH_SR_JAVA_DEVELOPER, component: SrJavaDeveloperComponent },
      { path: Paths.PATH_JAVA_DEVELOPER, component: JavaDeveloperComponent },
      { path: Paths.PATH_CHAOS_RESILIENCY_TEST_ENGINEER, component: ChaosResiliencyTestEngineerComponent },
      { path: Paths.PATH_BUSINESS_ANALYST, component: BusinessAnalystComponent },
      { path: Paths.PATH_DOT_NET_TECHNICAL_ARCHITECT, component: DotNetTechnicalArchitectComponent },
      { path: Paths.PATH_TRAINEE_PROJECT_MANAGER, component: TraineeProjectManagerComponent },
      { path: Paths.PATH_TRAINEE_DATA_ANALYST, component: TraineeDataAnalystComponent },
      { path: Paths.PATH_CUSTOMER_SERVICE_ASSOCIATE, component: CustomerServiceAssociateComponent },
      { path: Paths.PATH_E_LEARNING_DEVELOPER, component: ELearningDeveloperComponent },
      
      { path: Paths.PATH_NOT_FOUND, component: NotFoundComponent },      
    ]
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
