<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>AI-Driven Process Revolution</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
        <div class="itemsTwo">
          <p>Lead the charge in process optimization with our AI-Powered Process Cognition. Harness the transformative power of
            AI to receive personalized recommendations and simulate process changes. Propel your business into the future with
            precision and agility, achieving unparalleled efficiency and innovation.</p>
        </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/artificial-intelligence-concept.webp" alt="Software developer" width="278"
                        height="262" onerror="this.src='assets/images/artificial-intelligence-concept.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-workflow-icon.svg" alt="Intuitive Navigation" width="90" height="90">
                        <p>Seamless Workflow Configuration</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/derive-optimal-icon.svg" alt="Effortless Data" width="90" height="90">
                        <p>Derive Optimal Pathways for Enhanced Efficiency</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/unlock-insights-icon.svg" alt="Granular Data" width="90" height="90">
                        <p>Unlock Insights into Process Performance</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/customized-process-icon.svg" alt="Seamless Exploration" width="90" height="90">
                        <p>Customized Process Improvement Recommendations</p>
                    </div>
                </div>
            </div>
  
        </div>   
  
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Simplified configuration of workflows with monitoring parameters and scheduling options.</li>
                            <li>Deriving optimum paths for process efficiency and performance optimization.</li>
                            <li>Visual representation of process performance, with insights into diversions, delays, and stoppages.</li>
                            <li>Customized recommendations for improving process efficiency, based on industry best practices and organizational parameters.</li>
                            <li>Simulation of process changes to test efficiency improvements before implementation, with real-time adjustments and outcome observations.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-four.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
       
  
  
    </div>
  </section>
  
  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>