<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Gain Actionable Insights</h1>                    
            </div>
            <div class="">                    
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button> 
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Unlock actionable insights with our customizable Reporting System. Track performance metrics, key indicators, and
                    make informed decisions based on comprehensive data analysis. Drive business growth through data-driven
                    decision-making and strategic planning.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/hands-of-young-businessman.webp" alt="Business Digital Transformation" width="278"
                        height="262" onerror="this.src='assets/images/hands-of-young-businessman.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/actionable-icon.svg" alt="Actionable" width="90" height="90">
                        <p>Gain Actionable Insights with Our Customizable Reporting System</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/decisions-icon.svg" alt="Activities" width="90" height="90">
                        <p>Make Informed Decisions with Comprehensive Data Analysis</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/performance-icon.svg" alt="Communication" width="90" height="90">
                        <p>Track Performance Metrics and Key Indicators</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/drive-business-icon.svg" alt="Business" width="90" height="90">
                        <p>Drive Business Growth with Data-Driven Decision Making</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>                   

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Utilize our customizable reporting system to gain actionable insights.</li>
                            <li>Access real-time data analysis to identify trends and opportunities for improvement.</li>
                            <li>Analyze data across various dimensions to uncover patterns and correlations.</li>
                            <li>Monitor performance metrics and key indicators to track progress.</li>
                            <li>Stay informed about sales performance, customer satisfaction, and operational efficiency.</li>
                            <li>Identify opportunities for growth and improvement based on data insights.</li>
                            <li>Set up customizable dashboards to visualize data and track KPIs.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-two.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>