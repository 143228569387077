import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  constructor() { }
  public showErrorMessageSubject: Subject<string> = new Subject();
  public showErrorMessageObservable = this.showErrorMessageSubject.asObservable();
  private shareDataSubject = new Subject<any>();
  shareDataObservable = this.shareDataSubject.asObservable();

  public shareErrorMessage(message: string) {
    this.showErrorMessageSubject.next(message);
  }
  shareData(data: any) {
    this.shareDataSubject.next(data);
  }
}
