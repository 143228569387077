export class ErrorMessages {
    public static errorNotSelectedRadioButton = "Please select one option";
    public static errorEmpty = "cannot be empty";
    public static zeroError = "cannot be zero"
    public static errorMinLengthTwo = " Input must be at least 2 characters long.";
    public static errorMinLengthFive = " Input must be at least 5 characters long.";
    public static errorMinLengthTen = " Input must be at least 10 characters long.";
    public static errorMaxLength100 = " Input cannot be greater than 100 characters.";
    public static errorMaxLength200 = " Input cannot be greater than 200 characters.";
    public static errorMaxLength500 = "Input cannot be greater than 500 characters.";
    public static errorMaxLength4 = "Input cannot be greater than 4 characters.";
    public static errorMinLength1 = "Input must be at least 1 character long.";
    public static errorMaxLength2 = "Input cannot be greater than 2 character.";
    public static errorMaxLength10 = "Input cannot be greater than 10 character.";
    public static errorMaxLength16 = "Input cannot be greater than 16 character.";
    public static negetiverestrict = "Enter proper values";
    public static nospecialchars = "no special charecters are allowed";
    public static errorMinLengthFiveDigits = " Input must be at least 5 digits long.";
    public static errorBooleanCheck = " must be checked";
  
    public static patternErrorSpecialCharacters = " No Special characters allowed.";
  
    public static errorDecimal = "After decimal only two numbers are allowed"
    public static errorMaxLength4000 = "Input cannot be greater than 4000 characters.";
    public static errorInvalidEmailAddress = "Invalid email address.";
    public static errorInvalidPhoneNumber = "Invalid Phone Number."
    public static errorInvalidText = "No Special Characters are allowed in the input"
    public static errorNumberText = "No Numbers are allowed in the input"
    public static onlyCharacters = "Only Characters are allowed in the input"
    public static onlyNumbers = "Only Numbers are allowed in the input";
    public static maxlengthExceeded = "The length of input exceeds the limit"
    public static negativeNumberError = "Negative Number not allowed"
    public static errorNoInternetConnectionAvailable = "No internet connection or you are not connected with VPN";
    public static errorPasswordNotMatched = "Password did not matched";
    public static errorTermAndConditions = "Please Check term and conditions";
    public static errorWhiteSpace = "No space allowed";
    public static errorsdatesmall = "end date should not be less then start date";
    public static errorinvalidyear = "less then 18 years not allowed";
  
    public static onlyNumeric = "Only Numerics are allowed in the input";
    public static maxLengthexceed = "Max length should not exceed";
    public static period = "invalid length";
    public static year = "invalid length";
    public static errorNegativeValue = "No negative value allowed";
    public static errorMaxLength11 = " Input cannot be greater than 11 characters.";
    public static errormaxLength4 = " Input cannot be greater than 4 .";
  
  
    public static errorMsgUnauthorized = "Username and password are not matched";
    public static erroMsgNotFound = "Unable to find api";
    public static errorMsgForDefault = "Some unidentified error occured";
    public static errorMsgForZero = "Value cannot be less than zero";
  
  
    public static errorInputEmpty = "Please enter a ";
    public static errorSelectWithoutA = "Please select ";
    public static errorSelectEmpty = "Please select a ";
    public static errorSelectAnEmpty = "Please select an ";
    public static errorValidEmpty = "Please enter a valid ";
    public static errorEnterSelectValid = "Please enter/select a valid";
    public static errorBankruptcy = "Please select bankruptcy status";
    public static errorTerms = 'You must agree to the terms and conditions to submit an application';
    public static agenoteligible = "You must be atleast 18 to apply for car."
  
    public static errorPrefferedType = "Please select a preferred vehicle type";
    public static errorLicenceType = "Please select a licence type";
    public static erroryearsAtAddressType = "Please select time at address";
    public static errorYearsAtJob = "Please select time in occupation"
    public static errorTotalMonthlyIncome = "Please select monthly take home pay";
  
    public static errorFirstName = "Please Enter The First Name";
    public static errorLastName = "Please Enter The Last Name";
    public static errorPhoneNumber = "Please Enter A Valid Phone Number";
    public static errorEmail = "Please Enter A Valid Email Address";
    public static errorRegistration = "Please provide your registration.";
    public static errorMilage = "Please provide your mileage.";
    public static errorQuestion = "Please Enter Your Query";
    public static fileSizeError = "File size can not be more that 2MB.";
    public static fileTpeError = "File type should be Image, Word, Text, Excel or PDF.";
    public static fileAlreadyPresentError = "File already exists.";
    public static totalFileSizeError = "Total Filesize can not be more that 9MB.";
  
  
    // Error Text Get from api call
    public static errorUnknown = "Unknown Error";
  
    // Error title
    public static titleNoInternet = "Network Issue";
    public static errorDataMsg = "No result found";
    public static noOtherUnlistedPlants = "No other plant is available to configure";
    public static invalidFileFormatCSV = "Only csv file can be uploaded";
    public static duplicateRecords = "duplicate records has been skipped and their quantity has been added to respective item number";
    public static itemsNotAvailable = "items are not available";
    public static itemNotAvailable = "item is not available";
    public static quantityInDecimal = "item(s) has invalid quantity";
    public static duplicateRecordConfirmMsg = "Duplicate records found, do you want to combine the quantity? - "
    public static clearingPolines = 'Changing vendor will remove all items. Do you want to continue?';
  
    public static errorItemNumberExist = "Item already exists. Please update the quantity against the item if required";
  
    public static noReleventDataFound = "No data found or csv file not supported";
  
    public static itemNumberMinLength = "Item number length should be between ";
  
    public static formNotValid = "At least one required field was empty/invalid. Please fill in the required fields before continuing";
    public static duplicateFile = "This file has already been uploaded. Do you wish to continue?";
    public static uploadFilesText = "Upload File: ";
    public static rejectValidationMsg = "Please add comment";
    public static vendorNotFound = "Vendor of this po is not available";
  
    public static documentNotFound = "No document found with name ";

}
