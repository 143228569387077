import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-one',
  templateUrl: './merfx-sub-page-one.component.html',
  styleUrls: ['./merfx-sub-page-one.component.css']
})
export class MerfxSubPageOneComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageOneComponent) {
  context.title = 'Streamline Post-Contract Activities with Our Advanced Collection System';
  context.description = 'Effortlessly manage post-contract activities with our innovative Collection System. Ensure seamless communication and transaction tracking through a dedicated customer portal for payments and contract details. Automate workflows, enhance customer communication, and streamline payment management for increased efficiency and reliability.';
  context.keywords = 'Collection system, post-contract activities, payment management, customer portal, streamline collections, automate workflows, payment processing, contract renewals, customer communication, transaction tracking, payment reminders, real-time reporting, secure payment processing, arrear management, collection dashboard, customer activation.';
  context.seoTags();
}
