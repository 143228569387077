<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">                
                <div class="">
                  <h1>Trainee Project Manager (Intern)</h1>
                </div>
          
                <div class="job-details">
                  <ul>
                    <li>Location: <span>Essex ,Basildon</span></li>
                    <li>Reports to: <span>Project Operations Manager</span></li>
                    <li>Salary: <span>Competitive</span></li>
                    <li>Work Mode: <span>30 hrs per week</span></li>
                    <li>Salary: <span>Up to £10.00 per hour</span></li>
                    <li>Duration: <span>6-12 months</span></li>
                    <li>Update On: <span>12 Jul, 2021</span></li>
                  </ul>
                </div>
      
              <div class="work-mode">
                <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
              </div>
              </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">SignitiveAi is a business consulting firm based in London offering research services to
                    a diverse range of clients around the world.
                </p>

                <hr class="border mb-20">

                <div class="mb-20">
                    <h2 class="text-black mb-5">Introduction</h2>
                    <p>We specialise in business and IT consulting specifically in Learning and Organisational
                      Development services. Our bespoke learning solutions are highly recognised by clients across the world at
                      the forefront of their business operations. Our focus is on retaining our prestigious key accounts, through
                      the efficiency and quality of our service.</p>
                  </div>
          
                  <div class="mb-20">
                    <h2 class="text-black mb-5">The Role</h2>
                    <p>To support the growing volume of business, we are offering internships within project, we are searching for
                      candidates who enjoy change and pace. The candidates should possess entrepreneurial spirit and ambition.</p>
                    <p>The role requires a team player with lots of initiative to manage the multiple elements involved in
                      delivery of projects, particularly when it comes to delivering high quality within agreed timelines.</p>
                    <p>You will be working on a range of traditional and non-traditional projects, covering online methodologies,
                      and focus groups. You will be highly organised, confident in liaising with clients and external resources,
                      as well as providing flexibility in-field.</p>
                    <p>This is a 6 to 12 months internship with the possibility of a full-time position post-completion.</p>
                  </div>
          
                  <div class="">
                    <h2 class="text-black">Duties & Responsibilities</h2>
                    <p class="mb-5">Project Management:</p>
                    <ul class="regular">
                      <li>Responsible for managing and coordinating a range of projects (being flexible and organised are key
                        skills)</li>
                      <li>Implementing and overseeing projects, to ensure the right procedures are followed and goals are achieved
                      </li>
                      <li>Client briefings, input into project design and implementation, day-to-day managing of projects,
                        providing feedback to Director</li>
                      <li>Costing projects and writing persuasive proposals (with input from the senior team)</li>
                      <li>Working with both internal and external sources, ensuring that projects are delivered on time and to the
                        agreed specifications</li>
                      <li>Scheduling project communications and schedules</li>
                      <li>Organising invoices, standing orders, purchase numbers, and receipts</li>
                      <li>Organising contracts, NDAs, and project documents</li>
                      <li>Managing social media channels and surveys</li>
                    </ul>
          
                    <p class="mb-5">People/Line Management:</p>
                    <ul class="regular">
                      <li>Creating rotas, ensuring there is enough staff available for upcoming projects.</li>
                      <li>Supervising junior staff’s day-to-day activities initially under the supervision of the Project
                        Managers, developing and implementing company procedures.</li>
                      <li>Motivate and support team.</li>
                      <li>Ensure all team members receive appropriate training and are up to date with the industry’s regulations.
                      </li>
                      <li>Arrange briefing and de-briefing sessions and 1-to-1 appointments to monitor individual progress.</li>
                      <li>Managing project participation history.</li>
                      <li>Be proactively involved in non-project related activities.</li>
                      <li>Support the senior team with last-minute requests.</li>
                    </ul>
                    <p>Must study or have graduated in IT/Business Related Subjects/ Instructional Design or related undergraduate degree.</p>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">Part-Time Hours</h2>
                    <p>30 per week</p>
                  </div>
                  <div class="">
                    <h2 class="text-black mb-5">Expected Start Date</h2>
                    <p>01/08/2021</p>
                  </div>
                  <div class="">
                    <h2 class="text-black mb-5">Job Types</h2>
                    <p>Full-time, Part-time, Internship.</p>
                  </div>
                  <div class="">
                    <h2 class="text-black mb-5">Salary</h2>
                    <p>Up to £10.00 per hour</p>
                  </div>
                  <div class="">
                    <h2 class="text-black mb-5">Covid-19 Considerations</h2>
                    <p>Remote working</p>
                  </div>
            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>