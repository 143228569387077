import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-mortgage-solution',
  templateUrl: './mortgage-solution.component.html',
  styleUrls: ['./mortgage-solution.component.css']
})
export class MortgageSolutionComponent extends BaseClassUi {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MortgageSolutionComponent) {
  context.title = 'Mortgage Management Solutions: Streamline Lending with Advanced Automation and Compliance';
  context.description = 'Optimize your mortgage operations with our comprehensive suite of solutions, including digital mortgage applications, automated underwriting, and robust compliance management. Enhance customer engagement with our CRM system, streamline document handling, and efficiently manage loan servicing and payments. Safeguard your operations with proactive risk management and advanced analytics for informed decision-making.';
  context.keywords = 'Mortgage management solutions, digital mortgage applications, automated underwriting, regulatory compliance, CRM system, document management, loan servicing, payment processing, risk management, data analytics, borrower engagement, financial institutions integration, compliance management, customer satisfaction, online account management, e-signatures, mortgage lending, mortgage industry, seamless document handling, efficient mortgage process.';
  context.seoTags();
}
