import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b24422',
  templateUrl: './blog-details-b24422.component.html',
  styleUrls: ['./blog-details-b24422.component.css']
})
export class BlogDetailsB24422Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB24422Component) {  
  context.title = 'Emerging Trends in Big Data and Analytics | Latest Insights';
  context.description = 'Stay ahead in the data-driven world by exploring the latest trends in big data and analytics. Discover augmented analytics, real-time data processing, edge computing, cloud-based analytics, data ethics and privacy, and explainable AI';
  context.keywords = 'Big data, analytics, augmented analytics, real-time data processing, edge computing, cloud-based analytics, data privacy, data ethics, explainable AI';
  context.seoTags();
}

