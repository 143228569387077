import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b71222',
  templateUrl: './blog-details-b71222.component.html',
  styleUrls: ['./blog-details-b71222.component.css']
})
export class BlogDetailsB71222Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB71222Component) {  
  context.title = 'New Trends in Product Engineering | Latest Developments in IT Product Engineering';
  context.description = 'Discover the latest trends in IT product engineering, from Artificial Intelligence and Machine Learning to Cloud-Native Development, DevOps and Agile Development, Low-Code and No-Code Development, and IoT. Learn how these trends are transforming the software development landscape and driving business success.';
  context.keywords = 'IT product engineering, Artificial Intelligence, Machine Learning, Cloud-Native Development, DevOps, Agile Development, Low-Code Development, No-Code Development, IoT, software development, business success, software solutions, innovation';
  context.seoTags();
}
