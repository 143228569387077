import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-follow-us-on',
  templateUrl: './follow-us-on.component.html',
  styleUrls: ['./follow-us-on.component.css']
})
export class FollowUsOnComponent extends BaseClassUi {

  constructor() {
    super();
  }

}
