import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerfxComponent } from './components/merfx/merfx.component';
import { CoreModule } from '@modules/core';
import { ProductxComponent } from './components/productx/productx.component';
import { MerfxSubPageOneComponent } from './components/merfx-sub-page-one/merfx-sub-page-one.component';
import { MerfxSubPageTwoComponent } from './components/merfx-sub-page-two/merfx-sub-page-two.component';
import { MerfxSubPageThreeComponent } from './components/merfx-sub-page-three/merfx-sub-page-three.component';
import { MerfxSubPageFourComponent } from './components/merfx-sub-page-four/merfx-sub-page-four.component';
import { MerfxSubPageFiveComponent } from './components/merfx-sub-page-five/merfx-sub-page-five.component';
import { MerfxSubPageSixComponent } from './components/merfx-sub-page-six/merfx-sub-page-six.component';
import { MerfxSubPageSevenComponent } from './components/merfx-sub-page-seven/merfx-sub-page-seven.component';
import { ProductxSubPageOneComponent } from './components/productx-sub-page-one/productx-sub-page-one.component';
import { ProductxSubPageTwoComponent } from './components/productx-sub-page-two/productx-sub-page-two.component';
import { ProductxSubPageThreeComponent } from './components/productx-sub-page-three/productx-sub-page-three.component';
import { ProductxSubPageFourComponent } from './components/productx-sub-page-four/productx-sub-page-four.component';
import { MortgageSolutionComponent } from './components/mortgage-solution/mortgage-solution.component';



@NgModule({
  declarations: [
    MerfxComponent,
    ProductxComponent,
    MerfxSubPageOneComponent,
    MerfxSubPageTwoComponent,
    MerfxSubPageThreeComponent,
    MerfxSubPageFourComponent,
    MerfxSubPageFiveComponent,
    MerfxSubPageSixComponent,
    MerfxSubPageSevenComponent,
    ProductxSubPageOneComponent,
    ProductxSubPageTwoComponent,
    ProductxSubPageThreeComponent,
    ProductxSubPageFourComponent,
    MortgageSolutionComponent
  ],
  imports: [
    CommonModule,
    CoreModule
  ]
})
export class SolutionsModule { }
