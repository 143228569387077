import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-four',
  templateUrl: './merfx-sub-page-four.component.html',
  styleUrls: ['./merfx-sub-page-four.component.css']
})
export class MerfxSubPageFourComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageFourComponent) {
  context.title = 'Optimize Procurement Processes with Our Purchase Management Solution';
  context.description = 'Enhance inventory control and optimize costs with our Purchase Management solution. Streamline asset acquisition, automate procurement workflows, and centralize cost management for improved financial control. Drive operational efficiency and achieve better financial management through optimized purchase processes.';
  context.keywords = 'purchase management, procurement optimization, inventory control, cost optimization, asset acquisition, cost management, procurement workflows, financial management, operational efficiency, real-time inventory tracking, purchasing patterns, supplier negotiation, budget adherence, vendor management, approval workflows, spend controls, analytics, reporting tools, purchase performance, asset visibility.';
  context.seoTags();
}
