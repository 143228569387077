import { Component } from '@angular/core';

@Component({
  selector: 'app-more-blogs',
  templateUrl: './more-blogs.component.html',
  styleUrls: ['./more-blogs.component.css']
})
export class MoreBlogsComponent {

}
