import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { WhoWeAreComponent } from './components/who-we-are/who-we-are.component';
import { OurValuesComponent } from './components/our-values/our-values.component';
import { EsgComponent } from './components/esg/esg.component';
import { BlogsComponent } from '../blogs/components/blogs/blogs.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CoreModule } from '@modules/core';


@NgModule({
  declarations: [
    WhoWeAreComponent,
    OurValuesComponent,
    EsgComponent,
    BlogsComponent,
    AboutUsComponent
  ],
  imports: [
    CommonModule,
    AboutRoutingModule,
    CoreModule,
  ]
})
export class AboutModule { }
