import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b261022',
  templateUrl: './blog-details-b261022.component.html',
  styleUrls: ['./blog-details-b261022.component.css']
})
export class BlogDetailsB261022Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB261022Component) {  
  context.title = 'Latest Practices in IT Product Development and Testing | Trends to Watch';
  context.description = 'Stay ahead of the competition with the latest trends in IT product development and testing. From Agile and DevOps to AI, ML, and test automation, learn how to deliver high-quality products quickly and efficiently';
  context.keywords = 'IT product development, IT product testing, Agile, DevOps, artificial intelligence, machine learning, cloud-based testing, shift-left testing, test automation, continuous integration and delivery, testing as a service, software development, software testing';
  context.seoTags();
}

