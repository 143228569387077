import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b91222',
  templateUrl: './blog-details-b91222.component.html',
  styleUrls: ['./blog-details-b91222.component.css']
})
export class BlogDetailsB91222Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB91222Component) {  
  context.title = 'Importance of ERP Systems in Modern Business Operations ';
  context.description = 'Discover how ERP systems can increase efficiency, improve data management, enhance customer service, and ensure regulatory compliance in your business';
  context.keywords = 'ERP systems, business operations, efficiency, productivity, data management, customer service, supply chain management, regulatory compliance';
  context.seoTags();
}
