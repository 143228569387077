import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-quality-engineer',
  templateUrl: './quality-engineer.component.html',
  styleUrls: ['./quality-engineer.component.css']
})
export class QualityEngineerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: QualityEngineerComponent) {
  context.title = 'Quality Engineer Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are looking for experienced QA professional with 5-9 years' expertise in diverse testing methodologies, automation, Agile environments, database proficiency, andCI/CD tools for efficient quality assurance as a Quality Engineer.`;
  context.keywords = 'SignitiveAi, Quality Engineer, QA, Testing, Automation, Agile, Database, PostgreSQL, Cassandra, Elasticsearch, Golang, Scrum, Test Types, Cucumber, Godog, Kubernetes, Docker, Rest Assured, Resty, Wiremock, Mountebank, CI/CD, Jenkins, Rally, JIRA, GIT, SVN.';
  context.seoTags();
}
