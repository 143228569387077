<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Simplify Post-Contract Activities</h1>                    
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Effortlessly manage post-contract activities with our Collection System. Our solution provides customers with
                    access to a dedicated portal for payments and contract details, ensuring seamless communication and transaction
                    tracking. Stay organized and responsive with streamlined payment management tools.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/happy-woman-shopping.webp" alt="Business Digital Transformation" width="278"
                        height="262" onerror="this.src='assets/images/happy-woman-shopping.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/process-icon.svg" alt="Process" width="90" height="90">
                        <p>Simplify Your Collection Process with Our Integrated Solution</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/activities-icon.svg" alt="Activities" width="90" height="90">
                        <p>Streamline Post-Contract Activities for Increased Efficiency</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/communication-icon.svg" alt="Communication" width="90" height="90">
                        <p>Enhance Customer Communication and Payment Tracking</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/explore-icon.svg" alt="Transaction" width="90" height="90">
                        <p>Explore Our Collection System for Seamless Transaction Management</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="">
                        <h3 class="mb-10">Collection Dashboard</h3>
                        <ul class="regular mb-20">
                            <li>Track arrear payments and manage customer payment details.</li>
                            <li>Streamline follow-up processes for outstanding payments.</li>
                        </ul>
                    </div>

                    <div class="">
                        <h3 class="mb-10">Customer Activation</h3>
                        <ul class="regular mb-20">
                            <li>Automatically send login credentials and website links for customer portal activation.</li>
                        </ul>
                    </div>

                    <div class="">
                        <h3 class="mb-10">Customer Portal</h3>
                        <ul class="regular mb-20">
                            <li>Provide customers with a dedicated website for payments and contract-related information.</li>
                        </ul>
                    </div>

                    <div class="mb-30">
                        <h3 class="mb-10">Contract</h3>
                        <ul class="regular">
                            <li>Manage arrear payments, view contract details, and handle customer requests efficiently.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-one.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>