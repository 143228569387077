import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClassUi } from '@modules/core/base';
import { IApiResponseCallback } from '@modules/core/interfaces';
import { ContactUsService } from '@modules/features/modules/contact/services';
import { EmailContactUs } from '@modules/features/modules/contact/models';

@Component({
  selector: 'app-quick-chat',
  templateUrl: './quick-chat.component.html',
  styleUrls: ['./quick-chat.component.css']
})

export class QuickChatComponent extends BaseClassUi implements OnInit, IApiResponseCallback<any> {

  public contactUsFormGroup: FormGroup;
  emailContactUs: EmailContactUs = new EmailContactUs();
  submitted: boolean = false;
  constructor(public contactUsService: ContactUsService) { super() }
  ngOnInit(): void {
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  handleResponse(response: any) {
    this.dataTransferService.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }




  closeQuickChatModal() {
    (document.querySelector('.siteBackdrop') as HTMLElement).style.display = "none";
    document.querySelector('.slide-quick-chat')!.classList.remove("show-chat");
  }

  ngAfterViewInit(): void {

    //When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) { 
      var modalQuickChat = document.querySelector(".slide-quick-chat");
      //Close quick chat slider
      if (event.target == modalQuickChat) {
        modalQuickChat.classList.remove("show-chat");
        (document.querySelector('.siteBackdrop') as HTMLElement).style.display = "none";
      }

    }

  }

}




function init(context: QuickChatComponent) {
  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);

}

function addFormValidation(context: QuickChatComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    // lastName: new FormControl(context.emailContactUs.lastName),
    phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
    term: new FormControl(false, Validators.requiredTrue),
  });

}