import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-two',
  templateUrl: './productx-sub-page-two.component.html',
  styleUrls: ['./productx-sub-page-two.component.css']
})
export class ProductxSubPageTwoComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ProductxSubPageTwoComponent) {
  context.title = 'Unlock Data Insights with Our Transparent Lineage Hub';
  context.description = "Discover the evolution of data management with our Lineage Hub. Trace your data's journey effortlessly, gain comprehensive insights, and empower decision-making with transparency and compliance.";
  context.keywords = 'transparent data evolution, data management, Lineage Hub, data journey, data traceability, data discovery, granular data lineage, seamless exploration, intuitive navigation, data insights, data transparency, compliance, strategic foresight, data tracking, data analysis, decision-making.';
  context.seoTags();
  }

