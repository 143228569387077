import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b161122',
  templateUrl: './blog-details-b161122.component.html',
  styleUrls: ['./blog-details-b161122.component.css']
})
export class BlogDetailsB161122Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB161122Component) {  
  context.title = 'New Trends in DevOps and Agile Development for Efficient Software Development';
  context.description = 'DevOps, Agile development, AIOps, low-code, no-code development, DevSecOps, value stream management, software development, collaboration, automation, efficiency, quality, cloud-native development.';
  context.keywords = 'DevOps and Agile Development, New trends in DevOps and Agile Development, AI in DevOps, Low-code No-code development';
  context.seoTags();
}

