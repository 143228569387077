<div class="slide-quick-chat">
    <div class="container-chat">
        <div class="header">
            <h2>Quick Chat</h2>
            <a href="javascript:void(0)" role="button" (click)="closeQuickChatModal()" class="close" title="Close"><img
                    src="assets/images/icons/cancel-icon.svg" alt="cancel" width="24" height="24"></a>
        </div>

        <form [formGroup]="contactUsFormGroup" *ngIf="contactUsFormGroup">
        
            <div class="mb-20">
                <div class="floatingLabel">
                    <input type="text" id="firstName" required="" formControlName="firstName">
                    <label for="firstName">Name</label>
                </div>
                <span class="errorMsg" *ngIf="contactUsFormGroup.get('firstName').invalid && submitted">
                    <span *ngIf="contactUsFormGroup.get('firstName').errors['required']">{{constants.firstNameReq}}</span>
                    <span *ngIf="contactUsFormGroup.get('firstName').errors['pattern']">{{constants.firstNamePattern}}</span>
                </span>
            </div>
        
            <div class="mb-20">
                <div class="floatingLabel">
                    <input type="email" id="emailId" required="" formControlName="email">
                    <label for="emailId">Email Address</label>
                </div>
                <span class="errorMsg" *ngIf="contactUsFormGroup.get('email').invalid && submitted">
                    <span *ngIf="contactUsFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
                    <span *ngIf="contactUsFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
                </span>
            </div>
        
        
            <div class="mb-20">
                <div class="floatingLabel">
                    <input type="number" id="contactNumberId" required="" formControlName="phoneNumber">
                    <label for="contactNumberId">Contact Number</label>
                </div>
                <span class="errorMsg" *ngIf="contactUsFormGroup.get('phoneNumber').invalid && submitted">
                    <span *ngIf="contactUsFormGroup.get('phoneNumber').errors['required']">{{constants.phoneNumberReq}}</span>
                    <span *ngIf="contactUsFormGroup.get('phoneNumber').errors['phoneNumber']">{{constants.phoneNumberPattern}}</span>
                </span>
            </div>
        
        
            <div class="mb-20">
                <div class="floatingLabel">
                    <textarea placeholder="" id="queries" required="" rows="3" formControlName="message"></textarea>
                    <label for="queries">Your Query</label>
                </div>
                <span class="errorMsg" *ngIf="contactUsFormGroup.get('message').invalid  && submitted">
                    <span *ngIf="contactUsFormGroup.get('message').errors['required']">{{constants.messageReq}}</span>
                </span>
            </div>
        
            <div class="">
                <div class="checkboxflex">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="privacyPolicyId" id="privacyPolicyId" formControlName="term">
                        <label for=""></label>
                    </div>
                    <div class="itemContent">
                        <label for="privacyPolicyId">
                            You agree to our <a href="javascript:void(0)" role="button" routerLink="privacy-policy"
                                (click)="closeQuickChatModal()">privacy
                                policy</a> and <a href="javascript:void(0)" role="button" routerLink="terms-of-use"
                                (click)="closeQuickChatModal()">terms
                                and conditions</a>.
                        </label>
                        <span class="errorMsg" *ngIf="contactUsFormGroup.get('term').invalid  && submitted">
                            <span *ngIf="contactUsFormGroup.get('term').errors['required']">{{constants.termReq}}</span>
                        </span>                                
                    </div>
                </div>
        
                <div class="checkboxflex mb-20">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="youAgreeToId" id="youAgreeToId">
                        <label for=""></label>
                    </div>
                    <div class="itemContent">
                        <label for="youAgreeToId">You agree to receive future marketing communications for our products <a href="javascript:void(0)"
                            role="button" routerLink="privacy-policy" (click)="closeQuickChatModal()">(more info)</a>.</label>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary mb-0 btn-100" name="submit" aria-label="Submit"
                (click)="onSubmit()">Submit</button>
        </form>
    </div>
</div>
