import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b19623',
  templateUrl: './blog-details-b19623.component.html',
  styleUrls: ['./blog-details-b19623.component.css']
})
export class BlogDetailsB19623Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BlogDetailsB19623Component) {  
  context.title = 'Effective Technology Adoption: Leveraging Digital Transformation Frameworks';
  context.description = "Discover how businesses can use digital transformation frameworks to enhance technology adoption. Learn about digital maturity levels, key competencies, and strategic steps for successful implementation. Embrace digitalisation to drive innovation and improve customer experiences.";
  context.keywords = 'Discover how businesses can use digital transformation frameworks to enhance technology adoption. Learn about digital maturity levels, key competencies, and strategic steps for successful implementation. Embrace digitalisation to drive innovation and improve customer experiences.';
  context.seoTags();
}
