import { AppInjector } from "app/utils/app-injector";
import { BaseClassParent } from "./base-class-parent";
import { Meta, Title } from "@angular/platform-browser";

export class BaseClassUi extends BaseClassParent {

    private _title: string;
  private _description: string;
  private _keywords: string;
  private _tags: string;

  get titleService(): Title {
    return AppInjector.injector?.get(Title);
  }
  get metaTagService(): Meta {
    return AppInjector.injector?.get(Meta);
  }
  set title(title: string) {
    this._title = title;
  }
  set keywords(keywords: string) {
    this._keywords = keywords;
  }
  set description(description: string) {
    this._description = description;
  }
  set tags(tags: string) {
    this._tags = tags;
  }
  get title() {
    return this._title;
  }
  get keywords() {
    return this._keywords;
  }
  get description() {
    return this._description;
  }
  get tags() {
    return this._tags;
  }

  seoTags() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: this.keywords },
    );
  }

    // Read more click function here....
    onReadMoreClick(path: string) {
        this.commonFunctions.navigateWithoutReplaceUrl(path);
    }
    //show Popup modal menu function here
    showPopupModal() {
        (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "block";
        (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "block";
        document.querySelector('body').classList.add("openModal");
    }

    closePopupModal() {
        (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "none";
        (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "none";
        document.querySelector('body').classList.remove("openModal");
      }
    
    

    removeTransparentHeder() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            document.querySelector('header').classList.remove("transparentHeader");
        } else {
            document.querySelector('header').classList.add("transparentHeader");
        }
    }

    closeAll() {
        (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "none";
        (document.querySelector('.mobileMenuList') as HTMLElement)!.style.right = "-290px";
        document.querySelector('body').classList.remove("openModal");

        var isSmallModal = document.getElementsByClassName('pupupModal');
        if (isSmallModal.length > 0) {
            (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "none";
            document.querySelector('body').classList.remove("openModal");
        }


    }

    scrollInto(el: HTMLElement | string) {
      if (typeof el === 'string') {
        el = document.getElementById(el);
      }
      el.scrollIntoView();
    }






}
