import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b1223',
  templateUrl: './blog-details-b1223.component.html',
  styleUrls: ['./blog-details-b1223.component.css']
})
export class BlogDetailsB1223Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB1223Component) {  
  context.title = 'What Is AI and Its Business Applications: A Comprehensive Guide|SignitiveAi';
  context.description = "Discover what artificial intelligence (AI) is and how it's being used in various industries, including business. Learn about its applications in customer service, marketing, supply chain management, fraud detection, human resources, and financial services.";
  context.keywords = 'Artificial intelligence, AI, Business applications, Customer service, Marketing, Supply chain management, Fraud detection, Human resources, Financial services';
  context.seoTags();
}
