<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">                
                <div class="">
                  <h1>Trainee Data Analyst (Consulting Projects Division)</h1>
                </div>
          
                <div class="job-details">
                  <ul>
                    <li>Location: <span>Essex ,Basildon</span></li>
                    <li>Reports to: <span>Project Operations Manager</span></li>
                    <li>Salary: <span>Competitive</span></li>
                    <li>Work Mode: <span>35 hrs per week</span></li>
                    <li>Salary: <span>Up to £10.00 per hour</span></li>
                    <li>Update On: <span>22 Mar, 2021</span></li>
                  </ul>
                </div>
      
              <div class="work-mode">
                <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
              </div>
              </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">SignitiveAi is a business consulting firm based in London offering research services to
                    a diverse range of clients around the world.</p>

                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black mb-5">Introduction</h2>
                    <p>We specialise in business and IT consulting specifically in Learning and Organisational Development
                      services. Our bespoke learning solutions are highly recognised by clients across the world at the forefront
                      of their business operations. Our focus is on retaining our prestigious key accounts, through the efficiency
                      and quality of our service.</p>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">The Role</h2>
                    <p>To support the growing volume of business, we are offering internships within project, we are searching for
                      candidates who enjoy change and pace. The candidates should possess entrepreneurial spirit and ambition.</p>
                    <p>The role requires a team player with lots of initiative to manage the multiple elements involved in
                      delivery of projects, particularly when it comes to delivering meaningful results by pulling data from the
                      large data sets helping organisation and its clients make important decisions by identifying various facts
                      and trends.</p>
                    <p>You will be working on a range of traditional and non-traditional projects. You will be highly organised,
                      confident in liaising with clients and external resources, as well as providing flexibility in-field.</p>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">Duties & Responsibilities</h2>
                    <p class="mb-5">Project Management:</p>
                    <ul class="SignitiveAi">
                      <li>Ensure that all information is successfully updated into the system by monitoring and controlling all data upload applications (e.g., LEAP, Perfect Portal, SharePoint) as well as the data quality of individual projects.</li>
                      <li>Developing and executing processes for monitoring, maintaining, improving, and restoring quality in respective client database.</li>
                      <li>Integration of new data sources in cooperation with the development team (e.g., E-learning Projects).</li>
                      <li>Assisting with internal development projects.</li>
                      <li>Extend and continue to build out our team’s analysis dashboard (E.g., PowerBI).</li>
                      <li>Manage and monitor our automated tasks (E.g., MS Flow, Power Automate).</li>
                      <li>Monitor and audit data quality.</li>
                      <li>Create data dashboards, graphs, and visualisations.</li>
                      </ul>
          
                    <p class="mb-5">People/Line Management:</p>
                    <ul class="SignitiveAi">
                      <li>Be proactively involved in non-project related activities.</li>
                      <li>Support the senior team with data related requests.</li>
                      </ul>
          
                  </div>

            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>