import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClassUi } from '@modules/core/base';
import { IApiResponseCallback } from '@modules/core/interfaces';
import { EmailContactUs } from '@modules/features/modules/contact/models';
import { ContactUsService } from '@modules/features/modules/contact/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseClassUi implements OnInit, IApiResponseCallback<any> {
  public contactUsFormGroup: FormGroup;
  submitted: boolean = false;
  emailContactUs: EmailContactUs = new EmailContactUs();
  constructor(public contactUsService: ContactUsService) {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

  onSubmit(event:any) {
    event.preventDefault();
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  handleResponse(response: any) {
    this.dataTransferService.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }

  // onCognitoServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.PATH_COGNITIVE_MINING);
  // }
  // onLineageServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.PATH_CROSS_APP_LINEAGE);
  // }
  // onAutomationServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.INTELLIGENT_PROCESS_AUTOMATION);
  // }

}

function init(context: HomeComponent) {
  context.title = 'SignitiveAi Ltd - Digital Transformation Consulting & Custom Software Development Services';
  context.description = 'SignitiveAi Ltd specializes in digital transformation consulting, custom software development, and technology consulting services. We offer services in business process automation, cloud migration, software development outsourcing, IT modernization, and more.';
  context.keywords = 'SignitiveAi Technology Services, Software development services, Custom software development, Software development solutions, Software development agency, Software development consulting, Mobile app development services, Web application development services, Enterprise software development, Small business software development, Software development for startups, Software development for healthcare, Software development for finance, Software development for education, Software development for manufacturing, Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, IT Audit Services, IT Engineering Services';
  context.seoTags();
  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);
}


function addFormValidation(context: HomeComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    lastName: new FormControl(context.emailContactUs.lastName),
    // phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });
}
