<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Elevate Customer Engagement and Online Experience</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Enhance customer interactions and drive growth. Seamlessly manage leads, contracts, and data. Optimize online
                    journey with user-friendly interface for improved satisfaction and conversion.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/employee-working.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/employee-working.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/enhance-customer-icon.svg" alt="Improve Inventory " width="90" height="90">
                        <p>Enhance Customer Interactions with Our Customer Management Solution</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/improve-sales-icon.svg" alt="Streamline Asset" width="90" height="90">
                        <p>Improve Sales Efficiency and Customer Satisfaction</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/drive-customer-icon.svg" alt="Enhance Procurement" width="90" height="90">
                        <p>Drive Customer Engagement and Conversion with Our User-Friendly Interface</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/online-customer-icon.svg" alt="Optimize Purchase" width="90" height="90">
                        <p>Enhance the Online Customer Experience from Vehicle Selection to Contract Creation</p>
                    </div>

                </div>
            </div>
        </div>           
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Centralize customer data to ensure a 360-degree view of customer interactions and preferences.</li>
                            <li>Deliver personalized experiences to customers, leading to increased loyalty and satisfaction.</li>
                            <li>Utilize customer relationship management tools to personalize interactions and build stronger relationships.</li>
                            <li>Automate lead nurturing processes and streamline contract workflows for faster conversions.</li>
                            <li>Leverage customer feedback and insights to continually enhance products and services.</li>
                            <li>Drive Customer Engagement and Conversion with Our User-Friendly Interface</li>
                            <li>Optimize Website Navigation for Seamless Customer Journey</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-six.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>