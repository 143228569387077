<section class="sectionInnerBanner topMargin">
  <div class="container-main">

    <div class="topInnerBanner">
      <div class="itemTopContent">
        <div class="">
          <h1>Our Values</h1>
          <div class="onlyForMbl">
            <img src="assets/images/our-values-img.webp" alt="Esg img" width="336" height="336"
              onerror="this.src='assets/images/our-values-img.png'">
          </div>
          <p class="mb-0">At SignitiveAi, our values are more than just words on a page; they are the guiding principles
            that shape our culture
            and define who we are. We believe that a vibrant company culture, which supports and nurtures our employees,
            is key
            to our success and a unique and sustainable competitive advantage.</p>
        </div>
      </div>
      <div class="itemsImage">
        <div class="">
          <img src="assets/images/our-values-img.webp" alt="Esg img" width="336" height="336"
            onerror="this.src='assets/images/our-values-img.png'">
        </div>
      </div>
    </div>

  </div>
</section>


<section class="commonSectionInner border-b sectionTopPd bgLightBlue">

  <div class="container-main">

    <div class="oddEvenItems mb-60">
      <div class="web-aap-flex ourValuesText">
        <div class="cont-inner-flex ">
          <div class="item-content">
            <h2>Integrity and accountability</h2>
            <h3>"Honesty, transparency, and accountability are the cornerstones of our decisions and actions."</h3>
            <p>We believe in honest and transparent conversations leading to meaningful decisions. We take ownership of
              the decisions we make and are accountable for their outcomes.</p>
          </div>
          <div class="item-img">
            <img src="assets/images/integrity-and-accountability-img.webp" alt="Integrity and accountability"
              width="350" height="208" onerror="this.src='assets/images/integrity-and-accountability-img.png'">
          </div>
        </div>
      </div>

      <div class="web-aap-flex ourValuesText">
        <div class="cont-inner-flex">
          <div class="item-content">
            <h2>Customers Focus</h2>
            <h3>"Our customers are at the center of all we do, and their satisfaction is our ultimate goal."</h3>
            <p>Customer centricity is at the heart of everything we do. We are dedicated to enhancing customer
              satisfaction and building strong, lasting customer relationships.</p>
          </div>
          <div class="item-img">
            <img src="assets/images/customers-focus-img.webp" alt="Customers Focus" width="350" height="208"
              onerror="this.src='assets/images/customers-focus-img.png'">
          </div>
        </div>
      </div>

      <div class="web-aap-flex ourValuesText">
        <div class="cont-inner-flex">
          <div class="item-content">
            <h2>Continuous Learning and improvement</h2>
            <h3>"Perfection is the sum of continuous learning and embracing lessons as opportunities."</h3>
            <p>We wholeheartedly embrace the journey towards total excellence. We prioritize continuous learning and
              improvement, recognizing that perfection is achieved through the accumulation of smaller steps and the
              positive consumption of lessons learned.</p>
          </div>
          <div class="item-img">
            <img src="assets/images/continuous-learning-img.webp" alt="Continuous Learning and improvement" width="350"
              height="208" onerror="this.src='assets/images/continuous-learning-img.png'">
          </div>
        </div>
      </div>

      <div class="web-aap-flex ourValuesText">
        <div class="cont-inner-flex">
          <div class="item-content scroll-elem slide-right">
            <h2>Diversity and Inclusion</h2>
            <h3>"We celebrate the richness of diversity and foster an inclusive environment where every voice matters."
            </h3>
            <p>We value diversity in all its forms, from different lived experiences to a range of backgrounds. We
              create an inclusive environment where everyone has an equal opportunity to contribute and succeed.</p>
          </div>
          <div class="item-img scroll-elem slide-left">
            <img src="assets/images/diversity-and-inclusion-img.webp" alt="Diversity and Inclusion" width="350"
              height="208" onerror="this.src='assets/images/diversity-and-inclusion-img.png'">
          </div>
        </div>
      </div>

      <div class="web-aap-flex ourValuesText">
        <div class="cont-inner-flex no-border">
          <div class="item-content scroll-elem slide-left">
            <h2>Growing together</h2>
            <h3>"Our growth is intertwined with the growth of every individual within our company. Together, we achieve
              greatness."</h3>
            <p>We believe that every individual has something unique to offer, and there exists a profound connection
              between our company and our employees. Our growth is mutual, and we strive to nurture this relationship
              for the benefit of all.</p>
          </div>
          <div class="item-img scroll-elem slide-right">
            <img src="assets/images/growing-together-img.webp" alt="Growing together" width="350" height="208"
              onerror="this.src='assets/images/growing-together-img.png'">
          </div>
        </div>
      </div>
    </div>


    <div class="mb-20">
      <h2>Read More About Us</h2>
      <div class="moreAboutLinks scroll-elem slide-left">
        <ul>
          <li><a routerLink="/who-we-are">Who We Are</a></li>
          <li><a routerLink="/esg">ESG</a></li>
        </ul>
      </div>
    </div>

  </div>

</section>