<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1>Product Owner (UK)</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>London, UK</span></li>
                        <li>Experience: <span>3-5 Years</span></li>
                        <li>Skills: <span>JIRA, Rally, MS Office Suite, Product Mgt, Project Mgt</span></li>
                        <!-- <li>Update On: <span>19 September, 2023</span></li> -->
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>                
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">We are recruiting passionate Product Owners to join our expanding team
                    in London. You will work with business leaders to turn software visions into reality, guiding
                    designers and engineers to deliver exceptional user experiences, processes, and technologies.
                </p>

                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black">As Product Owner, your main responsibilities will be: </h2>
                    <ul class="regular">
                        <li>Prioritizing software products aligned to business goals</li>
                        <li>Managing product/capability roadmaps to improve time-to-market and create value
                        </li>
                        <li>Translating the roadmap into minimal viable product iterations</li>
                        <li>Creating well-defined user stories and acceptance criteria</li>
                        <li>Becoming an expert in the problem space by working with subject matter experts</li>
                        <li>Incorporating industry best practices into requirements</li>
                        <li>Partnering with peers to globalize capabilities where applicable</li>
                        <li>Building consensus amongst stakeholders when defining requirements</li>
                        <li>Collaborating closely with the technology team to rapidly deploy and iterate solutions</li>
                        <li>Implementing tracking to evaluate performance and guide future investment</li>
                        <li>Enabling data-driven decision making</li>
                        <li>Identifying risks, challenges, opportunities and integration needs</li>
                        <li>Reviewing existing products for modernization potential</li>
                        <li>Performing demos and announcements to demonstrate success</li>
                        <li>Championing agile principles</li>

                    </ul>

                    <h2 class="text-black">Requirements: </h2>
                    <ul class="regular">
                        <li>University degree in computer science, engineering or related field, or comparable
                            experience</li>
                        <li>Significant experience in product management, business analysis, or product ownership roles
                        </li>
                        <li>Balance of technical knowledge and business acumen</li>
                        <li>Strong problem solving, analytical and organizational skills</li>
                        <li>Quick learner driven to pick up new skills</li>
                        <li>Ability to break down abstract concepts into detailed, meaningful features</li>
                        <li>Ability to separate tech constraints from core business value</li>
                        <li>Customer-centric mindset</li>
                        <li>Passion for great products with the ability to articulate vision and functionality</li>
                        <li>Teamwork skills to build relationships at all levels</li>
                        <li>Excellent written and verbal communication</li>
                        <li>Experience with BDD, agile development methodologies, and scrum</li>
                        <li>Project management abilities like prioritization and task completion</li>
                        <li>Ability to scope work against team capacity</li>
                        <li>Creative thinking skills</li>

                    </ul>
                </div>

                <div class="">
                    <h2 class="text-black">What do we look for?</h2>
                    <ul class="regular">
                        <li>At least five years of experience as an SME in Business Intelligence disciplines.</li>
                        <li>At least two years of deep experience with Big Data solutions.</li>
                        <li>Basic expertise in working in a global environment.</li>
                        <li>Proven experience (minimum of 3 years) in managing complex, multi-dimensional demands.</li>
                        <li>Practical experience with customer onboarding, especially within the realm of financial
                            data.</li>
                    </ul>
                </div>

                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="regular mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career.
                            By merging hands-on
                            client projects with vast resources, SignitiveAi offers a unique platform for growth, learning,
                            and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant
                            experience. We thank all
                            applicants for their interest, but only those selected for an interview will be contacted.
                            SignitiveAi Ltd is an
                            equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>