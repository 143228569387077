import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b22323',
  templateUrl: './blog-details-b22323.component.html',
  styleUrls: ['./blog-details-b22323.component.css']
})
export class BlogDetailsB22323Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB22323Component) {  
  context.title = 'Stages in Business Digital Transformation: A Roadmap for SMEs | SignitiveAi';
  context.description = 'Learn about the stages of digital transformation for SMEs, including digitization, digital optimization, digital transformation, and continuous improvement. Discover how embracing digital technologies can increase efficiency, productivity, and competitiveness.';
  context.keywords = 'digital transformation, SMEs, digitization, digital optimization, enterprise resource planning, customer relationship management, data analytics, artificial intelligence, machine learning, blockchain, continuous improvement, digital technologies, roadmap, digital transformation experts';
  context.seoTags();
}


