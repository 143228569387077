import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b26522',
  templateUrl: './blog-details-b26522.component.html',
  styleUrls: ['./blog-details-b26522.component.css']
})
export class BlogDetailsB26522Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB26522Component) {  
  context.title = 'Latest Trends in Financial Software Development: What You Need to Know';
  context.description = 'Stay up to date with the latest trends in financial software development. Learn how cloud computing, mobile applications, AI and ML, blockchain, open banking, RPA, and digital wallets are changing the financial industry';
  context.keywords = 'financial software development, trends, cloud computing, mobile applications, AI, machine learning, blockchain, open banking, RPA, digital wallets, financial industry, software developers, financial data, transactions, fraud detection, automation, secure, efficient';
  context.seoTags();
}

