import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClassUi } from '@modules/core/base';
import { EmailJobApplicationForm, EmailJobOpened } from '../../modals';

@Component({
  selector: 'app-apply-form',
  templateUrl: './apply-form.component.html',
  styleUrls: ['./apply-form.component.css']
})

export class ApplyFormComponent extends BaseClassUi implements OnInit {

  applyNowFormGroup: FormGroup;
  jobTypesList: Array<EmailJobOpened> = []
  emailJobApplicationForm: EmailJobApplicationForm = new EmailJobApplicationForm();
  percentDone: number;
  uploadSuccess: boolean;
  submitted: boolean = false;

  constructor() { super() }

  ngOnInit(): void {
    init(this)
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.applyNowFormGroup);
    if (this.applyNowFormGroup.valid) {
     // this.applyFormService.submit(this);
    }else{
      console.log("form is not valid")
    }
  }


  onSuccess(response: any) {
    // thank you page
    // this.dataTransfer.shareData('applyNow');
    this.showPopupModal();
    this.applyNowFormGroup.reset();
    this.submitted = false;
  }

  onError(errorCode: number, errorMsg: string) {
    // error page
  }

  // upload(evt) {
  //   let files: File[] = evt.files
  //   this.uploadAndProgress(files);
  //   console.log(files);

  // }
  // uploadAndProgress(files: File[]) {
  //   console.log(files)
  //   var formData = new FormData();
  //   Array.from(files).forEach(f => formData.append('file', f))

  //   this.http.post('https://file.io', formData, { reportProgress: true, observe: 'events' })
  //     .subscribe(event => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //         this.percentDone = Math.round(100 * event.loaded / event.total);
  //       } else if (event instanceof HttpResponse) {
  //         this.uploadSuccess = true;
  //       }
  //     });
  // }
}

function init(context: ApplyFormComponent) {
  // context.applyFormService.getJobTypes().subscribe(res => {
  //   context.jobTypesList = res;
  // });
  addFormValidation(context);
  // context.applyFormService.setformGroup(context.applyNowFormGroup);

}

function addFormValidation(context: ApplyFormComponent) {
  context.applyNowFormGroup = new FormGroup({
    applyingForJobType: new FormControl(''),
    referal: new FormControl(context.emailJobApplicationForm.referal),
    referralCode: new FormControl(context.emailJobApplicationForm.referralCode),
    fullName: new FormControl(context.emailJobApplicationForm.fullName, Validators.required),
    email: new FormControl(context.emailJobApplicationForm.email, [Validators.required, Validators.email]),
    phoneNumber: new FormControl(context.emailJobApplicationForm.phoneNumber, [Validators.required]),
    linkedInIdLink: new FormControl(context.emailJobApplicationForm.linkedInIdLink),
    currentJobDesignation: new FormControl(context.emailJobApplicationForm.currentJobDesignation),
    currentEmployer: new FormControl(context.emailJobApplicationForm.currentEmployer),
    totalExperience: new FormControl(context.emailJobApplicationForm.totalExperience),
    relevantExperience: new FormControl(context.emailJobApplicationForm.relevantExperience),
    // attachment: new FormControl(context.emailJobApplicationForm.attachment),
  });
}

