<section class="sectionContact">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1">
        <h2 class="mb-60">To schedule a demo with one of our product consultants, please fill in your
          contact details</h2>
      </div>
    </div>

    <form [formGroup]="contactUsFormGroup" *ngIf="contactUsFormGroup">
      <div class="row-flex">
        <div class="items-col-2 mb-30">
          <div class="floating-label">
            <input type="text" id="firstName" placeholder="Name" formControlName="firstName">
            <label for="firstName">Name</label>
            <span class="error-msg" *ngIf="contactUsFormGroup.get('firstName').invalid && submitted">
              <span *ngIf="contactUsFormGroup.get('firstName').errors['required']">{{constants.firstNameReq}}</span>
              <span *ngIf="contactUsFormGroup.get('firstName').errors['pattern']">{{constants.firstNamePattern}}</span>
            </span>
          </div>
        </div>
        <div class="items-col-2 mb-30">
          <div class="floating-label">
            <input type="email" id="emailId" placeholder="Email" formControlName="email">
            <label for="emailId">Email</label>
            <span class="error-msg" *ngIf="contactUsFormGroup.get('email').invalid && submitted">
              <span *ngIf="contactUsFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
              <span *ngIf="contactUsFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
            </span>
          </div>
        </div>
        <div class="items-col-1 mb-30">
          <div class="floating-label">
            <textarea id="queries" placeholder="Type your message here" formControlName="message"></textarea>
            <label for="queries">Type your message here</label>
            <span class="error-msg" *ngIf="contactUsFormGroup.get('message').invalid  && submitted">
              <span *ngIf="contactUsFormGroup.get('message').errors['required']">{{constants.messageReq}}</span>
            </span>
          </div>
        </div>
        <div class="items-col-1">
          <div class="items-center">
            <button type="submit" class="btn btn-primary btn-primary" name="submit" aria-label="Submit" (click)="onSubmit()">Submit</button>
          </div>
        </div>
      </div> 

    </form>

  </div>
</section>