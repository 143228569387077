<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Transparent Data Evolution</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
        <div class="itemsTwo">
          <p>Experience the evolution of data management with our transparent Lineage Hub. Gain unprecedented insights into your
            data journey, from origin to transformation. Empower your decision-making with comprehensive data transparency,
            ensuring compliance and strategic foresight.</p>
        </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/blue-futuristic-stream-data.webp" alt="Software developer" width="278"
                        height="262" onerror="this.src='assets/images/blue-futuristic-stream-data.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/intuitive-navigation-icon.svg" alt="Intuitive Navigation" width="90" height="90">
                        <p>Intuitive Navigation & Data Traceability</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/effortless-data-icon.svg" alt="Effortless Data" width="90" height="90">
                        <p>Effortless Data Discovery</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/granular-data-lineage-icon.svg" alt="Granular Data" width="90" height="90">
                        <p>Granular Data Lineage</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-exploration-icon.svg" alt="Seamless Exploration" width="90" height="90">
                        <p>Seamless Exploration</p>
                    </div>
                </div>
            </div>
  
        </div>   
  
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Tracking the origin and journey of data to its current state, with detailed reporting and export functionalities.</li>
                            <li>Automated search features for discovering data across multiple systems, with indexing and de-normalization capabilities.</li>
                            <li>Tracking granular and aggregated data lineage, with drill-back functionality and integration options.</li>
                            <li>Ability to trace and audit computations, with qualitative and quantitative data analysis features.</li>
                            <li>Tools for tracking and analyzing data usage during processes, with drill-back functionality and detailed analytics.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-three.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
        
  
    </div>
  </section>
    
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>