<section class="sectionLogos">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-1 itemCenter">
          <h2 class="text-center mb-40">We are trusted by</h2>
        </div>
        <div class="items-col-1">
          <div class="logos-wrapper">
            <ul>
              <li>
                <img loading="lazy" src="assets/images/logos/sapiens-logo.webp" alt="sapiens logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/sapiens-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/stl-logo.webp" alt="stl logo" width="238"
                  height="142" onerror="this.src='assets/images/logos/stl-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/siksha-logo.webp" alt="siksha logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/siksha-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/locus-logo.webp" alt="locus logo logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/locus-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/mercedes-benz-logo.webp"
                  alt="mercedes benz logo" width="238" height="142"
                  onerror="this.src='assets/images/logos/mercedes-benz-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/salesforce-logo.webp" alt="salesforce logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/salesforce-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/swiggy-logo.webp" alt="swiggy logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/swiggy-logo.png'">
              </li>
              <li>
                <img loading="lazy" src="assets/images/logos/accenture-logo.webp" alt="accenture-logo"
                  width="238" height="142" onerror="this.src='assets/images/logos/accenture-logo.png'">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
