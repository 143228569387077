<section class="contactSectionTop topMargin">
  <div class="container-main">
    <div class="page-title">
      <h1>Contact Us</h1>     
    </div>
  </div>
</section>

<section class="gradientBg border-b">

  <section class="commonSection marginNone border-b bottomBg">

    <div class="container-main mb-20">      
      <div class="row-flex contact-card">
        <div class="items-col-8">
          <!-- <h2 class="large">Contact Form</h2> -->
          <div class="mb-10">
            <p class="mb-10">Please complete the contact form below and our team would be happy to help with your inquiry!</p>
            <p class="small"><span>* </span>Required Information</p>
          </div>

          <form [formGroup]="contactUsFormGroup" *ngIf="contactUsFormGroup">
            <div class="row-flex">
              <div class="items-col-2 mb-0">
                <div class="form-group">
                  <label for="firstNameId" class="form-label"><span>*</span> First Name</label>
                  <input type="text" class="form-control" name="firstName" formControlName="firstName"
                    placeholder="Type your first name here" required>
                  <span class="error-msg" *ngIf="contactUsFormGroup.get('firstName').invalid && submitted">
                    <span
                      *ngIf="contactUsFormGroup.get('firstName').errors['required']">{{constants.firstNameReq}}</span>
                    <span
                      *ngIf="contactUsFormGroup.get('firstName').errors['pattern']">{{constants.firstNamePattern}}</span>
                  </span>
                </div>
              </div>
              <div class="items-col-2 mb-0">
                <div class="form-group">
                  <label for="lastNameId" class="form-label">Last Name</label>
                  <input type="text" class="form-control" id="lastNameId" name="lastName" formControlName="lastName"
                    placeholder="Type your last name here">
                </div>
              </div>              

              <!-- <div class="items-col-2 mb-0">
                <div class="form-group">
                  <label for="phoneNoId" class="form-label">Phone Number</label>
                  <input type="text" class="form-control" id="phoneNoId" name="phoneNumber"
                    formControlName="phoneNumber" placeholder="Type your contact number" required>
                  <span class="error-msg" *ngIf="contactUsFormGroup.get('phoneNumber').invalid  && submitted">
                    <span
                      *ngIf=" contactUsFormGroup.get('phoneNumber').errors['required']">{{constants.phoneNumberReq}}</span>
                    <span
                      *ngIf=" contactUsFormGroup.get('phoneNumber').errors['pattern']">{{constants.phoneNumberPattern}}</span>
                  </span>
                </div>
              </div> -->

              <div class="items-col-1">
                <div class="form-group">
                  <label for="emailId2" class="form-label"><span>*</span> E-mail</label>
                  <input type="email" class="form-control" id="emailId2" name="email" formControlName="email"
                    placeholder="Type your email address here" required email>
                  <span class="error-msg" *ngIf="contactUsFormGroup.get('email').invalid && submitted">
                    <span *ngIf="contactUsFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
                    <span *ngIf="contactUsFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
                  </span>
                </div>
              </div>
              <div class="items-col-1 mb-20">
                <div class="form-group">
                  <label for="messageId2"><span>*</span> Message</label>
                  <textarea class="form-control" id="messageId2" name="message" aria-label="" formControlName="message"
                    rows="4" required placeholder="Type your message here"></textarea>
                  <span class="error-msg" *ngIf="contactUsFormGroup.get('message').invalid  && submitted">
                    <span *ngIf="contactUsFormGroup.get('message').errors['required']">{{constants.messageReq}}</span>
                  </span>
                </div>
              </div>
              <div class="items-col-1">
                <div class="form-group">
                  <button type="submit" class="btn btn-orange" name="submit" aria-label="Submit" (click)="onSubmit()" style="width: 100%;">
                    <span>Submit</span>
                    <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
                  </button>
                  <!-- <button type="submit" class="btn btn-blue" name="submit" aria-label="Submit"
                    (click)="onSubmit()">Submit</button> -->
                </div>
                <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone, email or text message.</p>
              </div>
            </div>
          </form>
  
        </div>
        <div class="items-col-3">
         <div class="contactImgBg"></div>        
        </div>
      </div>
    </div>

    <hr class="border mb-30">




    <div class="container-main mb-30">
      <h2 class="large">Where we are</h2>
      <div class="row-flex ourOffices">
        <div class="items-col-4">
          <div class="card-white">
            <h3 class="text-black">United Kingdom</h3>
            <p>124 City Road,<br>
              London EC1V 2NX</p>
          </div>
        </div>
        <div class="items-col-4">
          <div class="card-white">
            <h3 class="text-black">USA</h3>
            <p>4155 E Jewell Ave Suite 601 <br>
              Denver CO 80222</p>
          </div>
        </div>
        <div class="items-col-4">
          <div class="card-white">
            <h3 class="text-black">Singapore</h3>
            <p>201 Ang Mo Kio Avenue 3,<br>
              Unit 10 - 1650, 560201</p>
          </div>
        </div>
        <div class="items-col-4">
          <div class="card-white">
            <h3 class="text-black">India</h3>
            <p>15/643, Meenangadi Wayanad <br>
              Kerala 673591</p>
          </div>
        </div>
      </div>
    </div>

  
    

  </section>

</section>

<app-popup-modal></app-popup-modal>