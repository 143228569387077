import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-dot-net-technical-architect',
  templateUrl: './dot-net-technical-architect.component.html',
  styleUrls: ['./dot-net-technical-architect.component.css']
})
export class DotNetTechnicalArchitectComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetTechnicalArchitectComponent) {  
  context.title = '.NET Technical Architect - SignitiveAi';
  context.description = 'We are looking for a dot Net Architect who can understand the customer business in Fintech and Retail industry and provide a scalable business solution that can add value to the business.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
