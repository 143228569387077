import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-base',
  templateUrl: './ui-base.component.html',
  styleUrls: ['./ui-base.component.css']
})
export class UiBaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
