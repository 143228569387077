import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-two',
  templateUrl: './merfx-sub-page-two.component.html',
  styleUrls: ['./merfx-sub-page-two.component.css']
})
export class MerfxSubPageTwoComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageTwoComponent) {
  context.title = 'Unlock Actionable Insights with Our Customizable Reporting System';
  context.description = 'Gain valuable insights and drive business growth with our customizable Reporting System. Track performance metrics, key indicators, and make informed decisions through comprehensive data analysis. Enhance your strategic planning with real-time data and optimize your operations for maximum ROI.';
  context.keywords = 'Reporting system, actionable insights, data analysis, performance metrics, key indicators, informed decision-making, customizable reports, real-time data, data-driven decision-making, business growth, strategic planning, dashboard views, customer behaviour analysis, market trends, operational performance, KPI tracking, ROI optimization, comprehensive reporting tools.';
  context.seoTags();
}
