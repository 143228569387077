import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b28422',
  templateUrl: './blog-details-b28422.component.html',
  styleUrls: ['./blog-details-b28422.component.css']
})
export class BlogDetailsB28422Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB28422Component) {  
  context.title = 'Latest Trends in Backend Technologies in Software Development';
  context.description = 'Explore the latest trends in backend technologies in software development, including serverless computing, GraphQL, Kubernetes, event-driven architecture, microservices, and AI and ML';
  context.keywords = 'backend technologies, software development, serverless computing, GraphQL, Kubernetes, event-driven architecture, microservices, AI, machine learning';
  context.seoTags();
}

