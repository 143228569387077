import { AbstractControl, ValidationErrors } from '@angular/forms';

export class WhiteSpaceValidator {

    static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if (control.value && typeof control.value == "string") {
            let value: string = control.value;
            if (value[0] == ' ')
                return { cannotContainSpace: "can not contain space at start and end" }
            else if (value[value.length - 1] == ' ') {
                return { cannotContainSpace: "can not contain space at start and end" }
            }
        }
        return null;
    }
}