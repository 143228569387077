<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Maximize Operational Efficiency</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Resolve issues promptly with our Incident Management System. Maximize operational efficiency by streamlining
                    incident resolution and tracking. Prioritize and manage incidents for minimal business disruption and improved
                    customer satisfaction.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/courier-man-outdoor.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/courier-man-outdoor.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/resolve-issues-icon.svg" alt="Improve Inventory " width="90" height="90">
                        <p>Resolve Issues Promptly with Our Incident Management System</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/maximize-operational-icon.svg" alt="Streamline Asset" width="90" height="90">
                        <p>Maximize Operational Efficiency with Streamlined Incident Resolution</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/prioritize-icon.svg" alt="Enhance Procurement" width="90" height="90">
                        <p>Prioritize and Track Incidents for Minimal Business Disruption</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/improve-customer-icon.svg" alt="Optimize Purchase" width="90" height="90">
                        <p>Improve Customer Satisfaction Through Timely Issue Resolution</p>
                    </div>
                </div>
            </div>

        </div>   
        

        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Centralize incident reporting and tracking for efficient resolution.</li>
                            <li>Automate notifications to stakeholders for timely action.</li>
                            <li>Implement automation and escalation protocols to expedite resolution.</li>
                            <li>Automate notifications to stakeholders for timely action.</li>
                            <li>Prioritize and track incidents to minimize business disruption.</li>
                            <li>Monitor incident status and progress through centralized dashboards.</li>
                            <li>Gather feedback to continually improve incident handling procedures.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-five.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>