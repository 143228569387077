import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b25123',
  templateUrl: './blog-details-b25123.component.html',
  styleUrls: ['./blog-details-b25123.component.css']
})
export class BlogDetailsB25123Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB25123Component) {  
  context.title = 'Business Applications of Machine Learning - How ML can improve your operations';
  context.description = 'Discover how machine learning algorithms can help your business leverage data to gain insights, reduce costs, and improve customer satisfaction. Explore applications of machine learning in businesses';
  context.keywords = 'machine learning, business applications, predictive analytics, fraud detection, personalization, inventory management, quality control, risk management, data analysis, artificial intelligence';
  context.seoTags();
}
