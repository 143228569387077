<p>merfx-sub-page-seven works!</p>
<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Optimize Vehicle Management</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Optimize vehicle management and maintenance processes with our Garage Module. Improve productivity and customer
                    service with efficient task management. Streamline service requests and enhance client communication for better
                    operational efficiency.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/car-repair-and-maintenance.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/car-repair-and-maintenance.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/optimize-vehicle-icon.svg" alt="Optimize Vehicle" width="90" height="90">
                        <p>Optimize Vehicle Management and Maintenance Processes with Our Garage Module</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/improve-productivity-icon.svg" alt="Improve Productivity" width="90" height="90">
                        <p>Improve Productivity and Customer Service with Efficient Task Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/streamline-Service-icon.svg" alt="Streamline Service" width="90" height="90">
                        <p>Streamline Service Requests and Enhance Client Communication</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/simplify-garage-icon.svg" alt="Simplify Garage" width="90" height="90">
                        <p>Simplify Garage Operations for Better Operational Efficiency</p>
                    </div>
                </div>

            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Centralize vehicle records, maintenance schedules, and service histories for efficient management.</li>
                            <li>Automate maintenance reminders and scheduling to ensure vehicles are well-maintained.</li>
                            <li>Assign tasks to technicians, track progress, and prioritize jobs for optimal efficiency.</li>
                            <li>Facilitate seamless communication between customers, technicians, and service advisors.</li>
                            <li>Standardize processes and workflows to minimize errors and reduce turnaround times.</li>
                            <li>Utilize analytics to identify bottlenecks and areas for improvement in garage operations.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-seven.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>