import { Component } from '@angular/core';

@Component({
  selector: 'app-share-on',
  templateUrl: './share-on.component.html',
  styleUrls: ['./share-on.component.css']
})
export class ShareOnComponent {

}
