import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b1622',
  templateUrl: './blog-details-b1622.component.html',
  styleUrls: ['./blog-details-b1622.component.css']
})
export class BlogDetailsB1622Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB1622Component) {  
  context.title = 'Effective Use of Data Science in Financial Underwriting | Advanced Analytics, Machine Learning, and More';
  context.description = 'Learn how data science is transforming financial underwriting by improving risk assessment, identifying fraudulent behavior, personalizing loan offers, streamlining the underwriting process, and optimizing performance';
  context.keywords = 'data science, financial underwriting, advanced analytics, machine learning, risk assessment, fraud detection, loan personalization, underwriting process, performance optimization';
  context.seoTags();
}

