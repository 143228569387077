import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClassUi } from '@modules/core/base';
import { IApiResponseCallback } from '@modules/core/interfaces';
import { ContactUsService } from '@modules/features/modules/contact/services';
import { EmailContactUs } from '@modules/features/modules/contact/models';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent extends BaseClassUi implements OnInit, IApiResponseCallback<any>{
  public contactUsFormGroup: FormGroup;
  emailContactUs: EmailContactUs = new EmailContactUs();
  submitted: boolean = false;
  constructor(public contactUsService: ContactUsService) { super() }
  ngOnInit(): void {
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  handleResponse(response: any) {
    this.dataTransferService.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }
}
function init(context: ContactFormComponent) {
  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);

}

function addFormValidation(context: ContactFormComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    lastName: new FormControl(context.emailContactUs.lastName),
    // phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });

}
