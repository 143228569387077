import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b12522',
  templateUrl: './blog-details-b12522.component.html',
  styleUrls: ['./blog-details-b12522.component.css']
})
export class BlogDetailsB12522Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB12522Component) {  
  context.title = 'What is Blockchain Technology? A Comprehensive Guide - SignitiveAi';
  context.description = 'Learn about the revolutionary innovation of Blockchain technology and its potential to transform industries. Discover how it works, its features, benefits, and challenges, and explore its various applications beyond cryptocurrencies.';
  context.keywords = 'Blockchain technology, distributed ledger, decentralized, trustless system, immutability, transparency, security, cryptographic algorithms, consensus mechanisms, scalability, supply chain management, digital identity verification, voting systems, cryptocurrencies';
  context.seoTags();
}
