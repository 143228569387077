<!-- main container beginning here -->
<section class="section-santriDoc-banner topPadding">
    <div class="innerSpace forMortgageOnly">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-lg-col-2 itemTitle">
            <h1>Mortgage Management Solutions for Seamless Operations</h1>
            <p>Optimize your lending process with our comprehensive suite of solutions.</p>
            <div class="btn-flex">
              <button type="button" class="btn btn-rounded-white" aria-label="Request Demo">Request Demo</button>
              <button type="button" class="btn btn-rounded-navyBlue" aria-label="Get Started">Get Started</button>
            </div>
          </div>
          <div class="items-lg-col-2 itemImg">
            <div class="img-wp">
              <img src="assets/images/mortgage-solution-login-img.svg" alt="Mortgage Management Solutions for Seamless Operations" width="270"
                height="560">
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="section-details">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-1">
          <div class="card-details">
            <div class="items">
              <p>Digital Mortgage Applications</p>
            </div>
            <div class="items">
              <p>Automated Underwriting</p>
            </div>
            <div class="items">
              <p>Regulatory Compliance</p>
            </div>
            <div class="items">
              <p>Easy Integration</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="sectionPrimaryCard pb-0">
    <div class="card-inner">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-col-3 flexVcenter">
            <div class="titleFixed">
              <h2>Our Systems</h2>
            </div>
            <div class="card-primary cardBlue card-next-level--">
              <h3>Revolutionizing Mortgage Processes for Efficiency and Compliance</h3>
              <p>Streamline your mortgage operations with our comprehensive suite of solutions. Manage customer
                interactions with
                our CRM system, securely store and retrieve documents using our document management platform, and
                efficiently handle
                loan servicing and payment processing. Mitigate risks, gain valuable insights with data analytics, and
                support
                borrowers throughout the mortgage process with our customer education and support services.</p>
            </div>
          </div>
          <div class="items-col-8">
            <div class="leftSpace">
              <div class="row-flex">
                <div class="items-col-2">
                  <div class="card-primary bluebg mb-20 cardMarginTop">
                    <h3>Elevate Customer Engagement with Dynamic CRM Solutions</h3>
                    <p>Seamlessly manage customer interactions across the mortgage journey with our cutting-edge CRM
                      system. From lead
                      management to communication tracking and automated follow-ups, our CRM empowers you to enhance...</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
                    </div>
                  </div>
                  <div class="card-primary">
                    <h3>Streamline Document Handling with our Robust Management System</h3>
                    <p>Simplify your document management process with our robust system designed specifically for the
                      mortgage industry.
                      Securely store, organize, and retrieve mortgage-related documents with ease. Featuring advanced...</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="items-col-2 cardFlex">
                  <div class="card-primary orderOne mb-20">
                    <h3>Optimize Loan Management and Payments with Our Advanced Platform</h3>
                    <p>Take control of loan servicing and payment processing with our advanced platform tailored for
                      mortgage
                      professionals. Efficiently manage loan accounts, process payments, and handle escrow accounts with...</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div>
                  </div>
                  <div class="card-primary blueLight bluebg orderTwo cardMarginBtm">
                    <h3>Safeguard Your Mortgage Operations with Proactive Risk Management</h3>
                    <p>Mitigate risks associated with mortgage lending using our proactive risk management strategies and
                      tools. From
                      credit and operational risks to market fluctuations, our comprehensive approach includes risk...</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="sectionDashboard">
    <div class="container-main">
      <div class="row-flex imgOrder">
        <div class="items-lg-col-5 textVCenter">
          <div class="">
            <h2>Digital Mortgage Applications</h2>
            <div class="imgForMobile">
              <img src="assets/images/digital-mortgage-applications.svg" alt="Digital Mortgage Applications" width="480" height="470">
            </div>
            <p class="mb-25">Experience a seamless mortgage application process like never before with our digital
              solutions. Say goodbye to paper-based hassles and embrace the convenience of online form submission,
              document uploads, and e-signatures. Our platform streamlines the entire application journey, empowering
              borrowers with a convenient and efficient experience from start to finish. </p>
            <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
          </div>
        </div>
        <div class="items-lg-col-7">
          <div class="imgWrapper">
            <img src="assets/images/digital-mortgage-applications.svg" alt="Digital Mortgage Applications" width="480" height="470">
          </div>
        </div>
      </div>
    </div>
  
  
  </section>
  
  
  
  
  
  <section class="sectionSecondaryCard">
    <div class="cardInner">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-col-8 customCol70 relative">
            <div class="itemInner">
              <div class="titleFixed">
                <h2>Features</h2>
              </div>
              <div class="row-flex">
                <div class="items-col-2">
                  <div class="card-primary blueLight mb-20">
                    <h3>Swift Loan Approvals</h3>
                    <p>Expedite loan approvals with our automated underwriting. Utilizing advanced algorithms and
                      analytics, our system
                      ensures quick decisions, reduced processing times, and precise eligibility determinations, all while
                      maintaining
                      regulatory compliance.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read
                        More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary">
                    <h3>Compliance Made Simple</h3>
                    <p>Stay compliant effortlessly with our comprehensive compliance management system. From TILA to ECOA,
                      our platform ensures adherence to regulatory standards, minimizing the risk of penalties and legal
                      issues.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read
                        More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary mb-20 forCardBg1">
                    <h3>Effortless Verifications</h3>
                    <p>Seamlessly verify borrower information with our integrated financial institutions. Securely access
                      essential
                      financial data, from bank statements to tax returns, streamlining the verification process for
                      lenders and borrowers
                      alike.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read
                        More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary blueLight forCardBg2">
                    <h3>Engage and Retain Customers</h3>
                    <p>Enhance customer interactions throughout the mortgage journey with our advanced CRM system. From
                      lead management to
                      personalized follow-ups, our platform fosters engagement and loyalty, driving customer satisfaction.
                    </p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read
                        More</a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="items-col-3 customCol30">
            <div class="card-primary cardBlue mb-20">
              <h3>Simplify Document Handling</h3>
              <p>Streamline document management with our robust system. Securely store, organize, and retrieve
                mortgage-related
                documents with ease, ensuring efficient handling and access whenever needed.</p>
              <div class="readMore">
                <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
              </div>
            </div>
            <div class="card-primary cardBlue">
              <h3>Effortless Loan Management</h3>
              <p>Simplify loan servicing and payment processing with our platform. Manage accounts, process payments, and
                enhance borrower experience with features like automated processing and online account management.</p>
              <div class="readMore">
                <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="sectionDocumentManagement transformBg">
    <div class="container-main">
      <div class="row-flex imgOrder">
        <div class="items-lg-col-2">
          <h2>Transform Your Mortgage Operations: Empowering Tools for Success</h2>
          <!-- <div class="imgForMobile">
              <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
            </div> -->
          <ul class="regular mb-30 text-white">
            <li>Streamline your mortgage operations with our comprehensive suite of solutions designed to optimize every aspect of
              your lending process. From risk management to customer support</li>
            <li>Mitigate credit, operational, and market risks with advanced risk assessment models, stress testing, and scenario
              analysis.</li>
            <li>Unlock valuable insights into portfolio performance, borrower behavior, and market trends with powerful data
              analytics tools.</li>
            <li>And so much more...</li>
          </ul>
          <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
        </div>
        <!-- <div class="items-lg-col-2">
            <div class="imgWrapper">
              <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
            </div>
          </div> -->
      </div>
    </div>
  
  </section>
  
  
  
  
  <!-- <section class="sectionGradient secPaddingTop">
  
      <div class="container-main">
        <div class="row-flex imgOrder">
          <div class="items-lg-col-2 mb-0 textVCenter">
            <div class="">
              <h2>See all Applications in One Place</h2>
              <div class="imgForMobile">
                <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
              </div>
              <p class="mb-30">
                Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a
                story and let your users know a little more about you.
              </p>
              <button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue" aria-label="Request Demo">Request
                Demo</button>
            </div>
          </div>
          <div class="items-lg-col-2">
            <div class="imgWrapper">
              <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
            </div>
          </div>
        </div>
      </div>
  
    </section> -->
  
  
  
  <!-- <section class="createTemplatesSection bgLightBlue border-b">
      <div class="container-main">
        <div class="row-flex imgOrder">
          <div class="items-lg-col-2 textVCenter">
            <div class="">
              <h2>Create your own Communication Templates</h2>
              <div class="imgForMobile">
                <img src="assets/images/templates-bg.svg" alt="Create your own Communication Templates"  width="632" height="176">
              </div>
              <p class="mb-30">
                Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a story and let your users know a little more about you. 
              </p>
              <button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue" aria-label="Request Demo">Request
                Demo</button>
            </div>
          </div>
          <div class="items-lg-col-2">
            <div class="imgWrapper">
              <img src="assets/images/templates-bg.svg" alt="Create your own Communication Templates" width="632" height="176">
            </div>
          </div>
        </div>
      </div>
    </section> -->
  
  
  
  
  <!-- <section class="sectionGradient">
  
      <div class="container-main">
        <div class="row-flex imgOrder">
          <div class="items-lg-col-2">
            <h2>Document Management</h2>
            <div class="imgForMobile">
              <img src="assets/images/document-management-img.svg" alt="Document Management" width="635" height="372">
            </div>
            <ul class="regular mb-30">
              <li>Document Upload</li>
              <li>Annotations & User Logs</li>
              <li>OCR And Document Conversion Supported For Various Formats</li>
              <li>Version Control & Audit Trailsa</li>
              <li>Security & Access Control</li>
            </ul>
            <button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue" aria-label="Request Demo">Request Demo</button>
          </div>
          <div class="items-lg-col-2">
            <div class="imgWrapper">
              <img src="assets/images/document-management-img.svg" alt="Document Management" width="635" height="372">
            </div>
          </div>
        </div>
      </div>
  
    </section> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>