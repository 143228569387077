import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-seven',
  templateUrl: './merfx-sub-page-seven.component.html',
  styleUrls: ['./merfx-sub-page-seven.component.css']
})
export class MerfxSubPageSevenComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageSevenComponent) {
  context.title = 'Optimize Vehicle Management and Maintenance with Our Garage Module';
  context.description = 'Streamline your vehicle management and maintenance processes with our Garage Module. Enhance productivity and customer service with efficient task management, automated maintenance scheduling, and real-time service updates. Improve operational efficiency and client communication for a more profitable garage operation.';
  context.keywords = 'vehicle management, garage module, maintenance processes, task management, customer service, service requests, client communication, operational efficiency, vehicle records, maintenance schedules, service histories, maintenance reminders, technician task tracking, real-time updates, garage operations, workflow standardization, productivity, profitability, mobile application, tablet application, admin module.';
  context.seoTags();
}
