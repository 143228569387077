import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b21122',
  templateUrl: './blog-details-b21122.component.html',
  styleUrls: ['./blog-details-b21122.component.css']
})
export class BlogDetailsB21122Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB21122Component) {  
  context.title = 'Latest Trends in IT Product Experience and Design | Personalization, Mobile-First, VUI, AR/VR, Accessibility, Minimalism, Data Privacy & Security';
  context.description = 'Stay ahead of the competition with the latest trends in IT product experience and design. Learn about personalization, mobile-first approach, VUI, AR/VR, accessibility, minimalism, and data privacy & security';
  context.keywords = 'IT product experience, IT product design, personalization, mobile-first approach, VUI, voice user interface, AR, augmented reality, VR, virtual reality, accessibility, minimalism, data privacy, data security, digital experience, technology, digital landscape';
  context.seoTags();
}

