import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx',
  templateUrl: './productx.component.html',
  styleUrls: ['./productx.component.css']
})
export class ProductxComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxComponent) {
  context.title = 'Revolutionize Your Operations with AI-Powered Solutions for Workflow and Document Management';
  context.description = 'Discover how our intelligent solutions can revolutionize your business operations. Harness AI to optimize processes, ensure data transparency, and streamline document management. Our platform offers efficient document workflows, seamless workflow customization, and comprehensive reporting, all designed to enhance efficiency and compliance. Elevate your business with intuitive interfaces and advanced security features.';
  context.keywords = 'AI-powered solutions, process optimization, document management, data transparency, workflow optimization, intelligent solutions, document catalogue, workflow vault, lineage hub, contract creation, compliance, workflow customization, real-time data views, intuitive interface, comprehensive reporting, document ontology, security compliance, vault encryption, business efficiency.';
  context.seoTags();
}
