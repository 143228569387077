import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b12723',
  templateUrl: './blog-details-b12723.component.html',
  styleUrls: ['./blog-details-b12723.component.css']
})
export class BlogDetailsB12723Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BlogDetailsB12723Component) {  
  context.title = 'Key Enablers of Digital Transformation in SMEs: Strategies for Success';
  context.description = 'Discover the critical enablers of digital transformation for small and medium enterprises (SMEs). Learn how organisational strategy, technology capabilities, skilled personnel, and agile processes, along with external partnerships and digital technologies, drive successful digital initiatives. Stay competitive and innovative in the evolving business landscape.';
  context.keywords = 'digital transformation SMEs, business automation 2023, SME digital strategy, technology capabilities SMEs, skilled workforce digital transformation, agile business processes, SME partnerships, digital technology adoption, digital competition, SME innovation, organisational strategy digital, SME resilience, digital era success, SME digital initiatives.';
  context.seoTags();
}
