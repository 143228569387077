import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-customer-service-associate',
  templateUrl: './customer-service-associate.component.html',
  styleUrls: ['./customer-service-associate.component.css']
})
export class CustomerServiceAssociateComponent extends BaseClassUi{

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: CustomerServiceAssociateComponent) {  
  context.title = 'Customer Service Associate (Legal) - SignitiveAi';
  context.description = 'To provide administration and assistance to a Conveyancing Property Lawyer and Team in relation to the progression of active conveyancing files. To carry out further ad hoc duties as required by the management.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
