<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">                
                <div class="">
                  <h1>E-Learning Developer</h1>
                </div>
          
                <div class="job-details">
                  <ul>
                    <li>Location: <span>Essex, Basildon</span></li>
                    <li>Reports to: <span>Senior Editor</span></li>
                    <li>Salary: <span>Competitive</span></li>
                    <li>Update On: <span>10 Apr, 2021</span></li>
                  </ul>
                </div>
      
              <div class="work-mode">
                <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
              </div>
              </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">This is a new role for the organisation, SignitiveAi are now searching for a E-learning
                    developer who is motivated to join their well-capitalised and ambitious company for the next phase of
                    growth.
                </p>
                <hr class="border mb-20">
                <div class="">
                    <h2 class="text-black mb-5">Main Areas of Responsibility</h2>
                    <ul class="regular">
                      <li>Develop new eLearning courses using content typically provided in Word or PowerPoint format.</li>
                      <li>Develop and execute storyboards for new eLearning courses.</li>
                      <li>Amending existing eLearning courses.</li>
                      <li>Collaborate with team members to specify and manage the creation of multimedia assets, typically
                        illustrations, animations, videos and audio.</li>
                      <li>Amendments and translations of eLearning content using Articulate Storyline 360.</li>
                      <li>Creation of all marketing assets.</li>
                      <li>Creation of all company presentations.</li>
                      <li>Creation of new marketing materials using company branding.</li>
                      <li>Establish and maintain relationships with team leadership and subject matter experts.</li>
                      <li>Coordinate project tasks with management and team members as required.</li>
                    </ul>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">Knowledge and Skills</h2>
                    <ul class="regular">
                      <li>Working knowledge of Illustrator.</li>
                      <li>Working knowledge of articulate 360.</li>
                      <li>Knowledge of aftereffects and premiere pro.</li>
                      <li>Good communication skills between all departments.</li>
                      <li>Proven experience in the development of engaging eLearning materials.</li>
                      <li>Experience in the use of Articulate Storyline.</li>
                      <li>Solid command of and proven experience with eLearning instructional design theory, prototyping and
                        storyboarding.</li>
                      <li>Good design skills.</li>
                      <li>Previously worked with Photoshop and InDesign.</li>
                    </ul>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">Values and Behaviours</h2>
                    <ul class="regular">
                      <li>Strong sense of purpose, values and integrity.</li>
                      <li>Straightforward, positive and a team player.</li>
                      <li>Performance and delivery focused, can get things done.</li>
                      <li>Performance and delivery focused, can get things done.</li>
                      <li>Ability to produce visually creative concepts from an initial brief.</li>
                      <li>Be well organised with an excellent eye for detail.</li>
                      <li>Creativity and imagination.</li>
                      <li>Confident meeting tight deadlines.</li>
                      <li>Work effectively in a team or alone.</li>
                      <li>Willingness to learn new skills.</li>
                      <li>Engaging and outgoing personality.</li>
                    </ul>
                  </div>
          
                  <div class="">
                    <h2 class="text-black mb-5">Qualifications and Experience</h2>
                    <ul class="regular">
                      <li>Educated to degree level.</li>
                      <li>3+ years of design experience.</li>
                    </ul>
                  </div>

            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>