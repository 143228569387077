import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-features-entry',
  templateUrl: './features-entry.component.html',
  styleUrls: ['./features-entry.component.scss']
})
export class FeaturesEntryComponent extends BaseClassUi implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    // const currentUrl = this.router.url.replace('/', '');
    // if (!currentUrl) {
    //   this.commonFunctions.navigateWithReplaceUrlForParentRoutes(currentUrl);
    // } else {
    //   this.commonFunctions.navigateWithReplaceUrlForParentRoutes(Paths.PATH_MODULE_DAHSBOARD);
    // }
  }

}
