<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Expedite Contract Approval</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Speed up contract creation and approval with our Underwriting Process solution. Ensure compliance, optimize sales
                    processes, and simplify deposit verification and contract approval. Manage customer lists efficiently and
                    expedite sales workflows.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/happy-people-shaking-hands.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/happy-people-shaking-hands.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/underwriting-icon.svg" alt="Underwriting" width="90" height="90">
                        <p>Expedite Contract Creation and Approval with Our Underwriting Solution</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/compliance-icon.svg" alt="Compliance" width="90" height="90">
                        <p>Ensure Compliance and Risk Management Effortlessly</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/processes-icon.svg" alt="Processes" width="90" height="90">
                        <p>Optimize Sales Processes with Efficient Customer List Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/verification-icon.svg" alt="Verification" width="90" height="90">
                        <p>Simplify Deposit Verification and Contract Approval</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Accelerate contract creation and approval processes using our underwriting solution. </li>
                            <li>Ensure smooth collaboration between teams involved in the underwriting process.</li>
                            <li>Ensure compliance with regulatory requirements and mitigate risks effortlessly.</li>
                            <li>Utilize built-in compliance checks and risk assessment tools to streamline processes.</li>
                            <li>Enhance sales efficiency by optimizing customer list management.</li>
                            <li>Utilize data insights to prioritize leads and improve conversion rates</li>
                            <li>Automate deposit verification steps and streamline contract approval workflows.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-three.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>