import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Paths } from '@constants';


const routes: Routes = [

  { path: Paths.PATH_BLANK, loadChildren: () => import('@modules/features/features-routing.module').then(m => m.FeaturesRoutingModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking', useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
