import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-three',
  templateUrl: './productx-sub-page-three.component.html',
  styleUrls: ['./productx-sub-page-three.component.css']
})
export class ProductxSubPageThreeComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxSubPageThreeComponent) {
  context.title = 'Transform Your Operations with AI-Powered Process Optimization';
  context.description = 'Elevate your business with AI-driven process revolution. Streamline workflows, unlock insights, and receive personalized recommendations for unparalleled efficiency and innovation.';
  context.keywords = 'AI-driven process revolution, process optimization, AI-powered process cognition, workflow configuration, optimal pathways, process performance insights, customized recommendations, process simulations, efficiency, innovation, artificial intelligence, personalized recommendations, seamless workflow optimization.';
  context.seoTags();
  }
