<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">                
                <div class="">
                  <h1>Customer Service Associate (Legal)</h1>
                </div>
          
                <div class="job-details">
                  <ul>
                    <li>Location: <span>Essex ,Basildon</span></li>
                    <li>Reports to: <span>Project Operations Manager</span></li>
                    <li>Salary: <span>Competitive</span></li>
                    <li>Work Mode: <span>35 hrs per week</span></li>
                    <li>Salary: <span>Up to £10.00 per hour</span></li>
                    <li>Update On: <span>16 Jul, 2021</span></li>
                  </ul>
                </div>
      
              <div class="work-mode">
                <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
              </div>
              </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">To provide administration and assistance to a Conveyancing Property Lawyer and Team
                    in relation to the progression of active conveyancing files. To carry out further ad hoc duties as
                    required by the management.</p>

                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black mb-5">Duties & Responsibilities</h2>
                    <ul class="regular">
                      <li>Contacting Clients, Agents, Referrers, Search providers and other parties Solicitors to progress active
                        conveyancing transactions.</li>
                      <li>Requesting documentation fundamental to the transaction.</li>
                      <li>Ensuring documents are returned by Clients and work providers, prompting them if required and
                        progressing transactions.</li>
                      <li>Dealing with sale, purchase and re-mortgage matters to the extent delegated by the Fee Earner, to
                        include:
                        <ul class="list-roman">
                          <li>Any preliminary matters on both sale and purchase files.</li>
                          <li>Obtaining all required documentation from the client, other-side, third parties (such as HMLR, HMRC
                            and Lenders).</li>
                          <li>Drafting sale contracts and re-mortgage documentation as required by the Property Lawyer.</li>
                          <li>Dealing with Lenders on both sale and purchase matters, checking redemption statements, mortgage
                            offers and dealing with the financial calculations relating to the same as required by the Property
                            Lawyer.</li>
                        </ul>
                      </li>
                      <li>Assisting with purchase files, including:
                        <ul class="list-roman">
                          <li>Obtaining initial documentation.</li>
                          <li>Collating contract packs.</li>
                          <li>Administrative duties relating to the title checks/title reports.</li>
                          <li>Assisting the Property Lawyer in raising enquiries.</li>
                          <li>Reviewing and reporting on mortgages.</li>
                          <li>Ordering (all appropriate) searches, review and reporting on the same as directed by the Property
                            Lawyer.</li>
                        </ul>
                      </li>
                      <li>Preparation of files for completion to include, final searches, requests for mortgage advances and
                        liaison with Clients as to receipt of balance funds.</li>
                      <li>Dealing with all appropriate Notices/Deeds – Post Completion.</li>
                      <li>Ensuring the timely transfer of files following completion to the Post Completion Team.</li>
                      <li>Updating the Referrer, Agent, other websites and portals.</li>
                      <li>Checking the Conveyancing Inbox daily as appropriate and dealing with any emails relating to current
                        files.</li>
                      <li>Clearing daily task list in Case Management Systems (e.g., Perfect portal, LEAP, OSPREY etc.).</li>
                      <li>Telephone enquiries as received into the office.</li>
                      <li>Making telephone calls as required in relation to redemptions, completion dates and search results.</li>
                      <li>This list is not exhaustive and other tasks may be requested by the Property Lawyer/Management.</li>
                    </ul>
                  </div>

            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>