import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CareersRoutingModule } from './careers-routing.module';
import { CareerComponent } from './components/career/career.component';
import { BigDataConsultantComponent } from './components/big-data-consultant/big-data-consultant.component';
import { CoreModule } from '@modules/core';
import { AllJobOpeningsComponent } from './components/all-job-openings/all-job-openings.component';
import { QualityEngineerComponent } from './components/quality-engineer/quality-engineer.component';
import { RouterModule } from '@angular/router';
import { GolangSoftwareEngineerComponent } from './components/golang-software-engineer/golang-software-engineer.component';
import { UiEngineersReactjsNodejsComponent } from './components/ui-engineers-reactjs-nodejs/ui-engineers-reactjs-nodejs.component';
import { SrEngineerDevopsComponent } from './components/sr-engineer-devops/sr-engineer-devops.component';
import { ProductOwnerUkComponent } from './components/product-owner-uk/product-owner-uk.component';
import { DotNetDeveloperComponent } from './components/dot-net-developer/dot-net-developer.component';
import { SrJavaDeveloperComponent } from './components/sr-java-developer/sr-java-developer.component';
import { JavaDeveloperComponent } from './components/java-developer/java-developer.component';
import { ChaosResiliencyTestEngineerComponent } from './components/chaos-resiliency-test-engineer/chaos-resiliency-test-engineer.component';
import { BusinessAnalystComponent } from './components/business-analyst/business-analyst.component';
import { DotNetTechnicalArchitectComponent } from './components/dot-net-technical-architect/dot-net-technical-architect.component';
import { TraineeProjectManagerComponent } from './components/trainee-project-manager/trainee-project-manager.component';
import { TraineeDataAnalystComponent } from './components/trainee-data-analyst/trainee-data-analyst.component';
import { CustomerServiceAssociateComponent } from './components/customer-service-associate/customer-service-associate.component';
import { ELearningDeveloperComponent } from './components/e-learning-developer/e-learning-developer.component';
import { ApplyFormComponent } from './components';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CareerComponent,
    BigDataConsultantComponent,
    AllJobOpeningsComponent,
    QualityEngineerComponent,
    GolangSoftwareEngineerComponent,
    UiEngineersReactjsNodejsComponent,
    SrEngineerDevopsComponent,
    ProductOwnerUkComponent,
    DotNetDeveloperComponent,
    SrJavaDeveloperComponent,
    JavaDeveloperComponent,
    ChaosResiliencyTestEngineerComponent,
    BusinessAnalystComponent,
    DotNetTechnicalArchitectComponent,
    TraineeProjectManagerComponent,
    TraineeDataAnalystComponent,
    CustomerServiceAssociateComponent,
    ELearningDeveloperComponent,
    ApplyFormComponent,
  ],
  imports: [
    CommonModule,
    CareersRoutingModule,
    CoreModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    CareerComponent,
    BigDataConsultantComponent,
    AllJobOpeningsComponent,
  ]
})
export class CareersModule { }
