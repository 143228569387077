<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Simplify Your Document Workflow</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
          <div class="itemsTwo">
              <p>Step into the future of document management with our cutting-edge solution. Seamlessly streamline your document
                  workflow with our advanced Document Catalogue. Elevate your operations to new heights by effortlessly updating
                  and validating documents, ensuring unparalleled accuracy and compliance.</p>
          </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/businesswoman-browsing.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/businesswoman-browsing.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/effortless-icon.svg" alt="Effortless" width="90" height="90">
                        <p>Effortless Data Capture</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-interpretation-icon.svg" alt="Seamless Interpretation" width="90" height="90">
                        <p>Seamless Interpretation and Validation</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/efficient-data-icon.svg" alt="Efficient Data" width="90" height="90">
                        <p>Efficient Data Enrichment and Allocation</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/simplified-validation-icon.svg" alt="Simplified Validation" width="90" height="90">
                        <p>Simplified Validation and Submission</p>
                    </div>
                </div>
            </div>
  
        </div>     
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Ability to capture and extract data from various document types and formats.</li>
                            <li>Features for interpreting and validating documents, including live document extraction and validation rules.</li>
                            <li>Assign tasks to technicians, track progress, and prioritize jobs for optimal efficiency.</li>
                            <li>Options to enrich and allocate data according to business needs, with meta-data driven warehouses and validation rule definitions.</li>
                            <li>Tools for validating and submitting documents, with real-time validation and auto-adjustment features.</li>
                            <li>Capability to update organizational document vocabulary, with access to a wide range of document types and meta-data from global ontologies.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-one.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
      
  
    </div>
  </section>
  
  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>