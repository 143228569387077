import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b8623',
  templateUrl: './blog-details-b8623.component.html',
  styleUrls: ['./blog-details-b8623.component.css']
})
export class BlogDetailsB8623Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB8623Component) {  
  context.title = 'Bridging the Technological Divide: Enhancing Digital Maturity in UK Adult Social Care';
  context.description = 'Explore the state of digital maturity in UK adult social care, identifying key challenges such as limited technology access and low digital record adoption. Learn how targeted strategies and investments can bridge the technological gap, enhancing care quality and operational efficiency.';
  context.keywords = 'digital maturity, UK adult social care, digital divide, healthcare technology, digital transformation, care home connectivity, digital social care records, technology adoption, operational efficiency, digital infrastructure, care provider challenges, digital upskilling, patient care improvement, digital tools in healthcare, broadband investment, care staff training, digital capabilities, healthcare digital records, digital best practices, digital collaboration.';
  context.seoTags();
}

