import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b4823',
  templateUrl: './blog-details-b4823.component.html',
  styleUrls: ['./blog-details-b4823.component.css']
})
export class BlogDetailsB4823Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB4823Component) {  
  context.title = "Business Automation in 2023: Essential for Growth and Competitiveness";
  context.description = "Explore why business automation is no longer a luxury but a necessity in 2023. Learn how AI, machine learning, and RPA technologies enhance efficiency, drive data-driven decisions, and support remote work. Discover the benefits, challenges, and strategic importance of integrating automation into your business.";
  context.keywords = 'business automation 2023, AI in business, machine learning, robotic process automation, RPA, business efficiency, data-driven decisions, remote work, digital transformation, automation benefits, business sustainability, customer service automation, real-time analytics, strategic planning, employee reskilling, operational costs, competitive edge, business innovation, automation tools, technological transformation.';
  context.seoTags();
}

