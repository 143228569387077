<div class="options-wrapper">
    <div class="social-wp">
        <p>Follow Us On:</p>
        <div class="social-icons">
          <a href="#" target="_blank" title="VK"><img src="assets/images/icons/vk-icon.svg" alt="vk icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Pinterest"><img src="assets/images/icons/pinterest-icon.svg" alt="Pinterest icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Instagram"><img src="assets/images/icons/instagram-icon.svg" alt="Instagram icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Twitter"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Facebook"><img src="assets/images/icons/facebook-icon.svg" alt="Facebook icon" width="24" height="24"></a>
        </div>
      </div>

    <div class="">
        <p class="heading">More Blogs</p>
        <ul class="regular">
            <li><a routerLink="/blog-details-30823">New Trends in Business Digital Transformation</a></li>
            <li><a routerLink="/blog-details-14823">Stages in Business Digital Transformation</a></li>
            <li><a routerLink="/blog-details-4823">Digitisation Vs Digitalisation Vs Digital Transformation</a></li>
            <!-- <li><a href="#">[BlogHeaderName]</a></li> -->
        </ul>
    </div>
</div>
