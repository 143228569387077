export class AppConstants {
    // validation error messages
    public static fullNameReq = "Full name is required."
    public static firstNameReq = "First name is required."
    public static emailReq = "Email is required."
    public static emailInvalid = "Email is invalid."
    public static phoneNumberReq = "Phone number is required."
    public static messageReq = "Enter a message.";
    public static phoneNumberPattern = "Must contain only numbers.";
    public static firstNamePattern = "Must only contain characters.";
    public static termReq = "Please Accept Terms And Condition To Continue";

    //
    //
}


