import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-all-job-openings',
  templateUrl: './all-job-openings.component.html',
  styleUrls: ['./all-job-openings.component.css']
})
export class AllJobOpeningsComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: AllJobOpeningsComponent) {
  context.title = "Join SignitiveAi Ltd's Team | Explore All Career Opportunities Here";
  context.description = "Browse SignitiveAi's current job openings and apply for exciting opportunities to join our team. We are looking for talented professionals with a passion for innovation and technology.";
  context.keywords = "Latest job openings at SignitiveAi, Join the SignitiveAi team, Exciting career opportunities at SignitiveAi, Apply for open positions at SignitiveAi, Work with SignitiveAi, Find your next job at SignitiveAi, SignitiveAi job vacancies, Join our dynamic team at SignitiveAi, We're hiring at SignitiveAi, Grow your career with SignitiveAi, Open positions at SignitiveAi - apply now, Careers at SignitiveAi - join us today";
  context.seoTags();
}

