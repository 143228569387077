import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent extends BaseClassUi{

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TermsOfUseComponent) {  
  context.title = 'Terms of Use - SignitiveAi';
  context.description = 'Contact SignitiveAi for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'contact us, SignitiveAi, technology solutions, consulting services, IT product development, modernization, digital transformation';
  context.seoTags();
}
