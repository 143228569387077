<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1>UI Engineers - ReactJS, NodeJS</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>UK</span></li>
                        <li>Experience: <span>3-9 years</span></li>
                        <li>Skills: <span>React Framework, NodeJS, Agile, Kafka, Java, Postgres, Postgresql, REST web services, Git (GitLab, GitHub, BitBucket, SVN), Docker, Javascript</span></li>
                        <!-- <li>Update On: <span>19 September, 2023</span></li> -->
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>                
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">We are looking for UI Engineers to join our team, who will work closely
                    with development team to develop new system capabilities as defined within the technical/
                    functional specifications.
                </p>

                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black">Required: </h2>
                    <ul class="regular">
                        <li>Demonstrable experience in developing UI using React framework and NodeJS.</li>
                        <li>Comfortable/experienced with micro-service architecture and communication, specifically REST
                            and asynchronous messaging services (e.g., Kafka, RabbitMQ etc.)
                        </li>
                        <li>Comfortable/experience within a Scrum framework working with as part of a team to deliver
                            business functions and customer journeys that are tested and automated throughout the CICD
                            pipeline to production.</li>

                    </ul>

                    <h2 class="text-black">Desired: </h2>
                    <ul class="regular">
                        <li>Bachelor’s degree in computer science, computer engineering, or other technical discipline,
                            or equivalent work experience.</li>
                        <li>Experience in professional software development.
                        </li>
                        <li>Solid understanding of test-driven development, including unit, component, functional,
                            system integration and regression tests.</li>
                        <li>Knowledge of software engineering methodology (Agile, incl Scrum, Kanban, SAFe, Test-Driven
                            Development (TDD), Behavior Driven Development (BDD) and Waterfall)</li>
                        <li>Knowledge of any or all of the following technologies is desired: React, NodeJS, Typescript,
                            JavaScript, Postgres, Git, Docker</li>
                        <li>Experienced in continuous integration (CI), continuous deployment (CD) and continuous
                            testing (CT), including tools such as Jenkins, Rally and/or JIRA and version control such as
                            GIT or SVN</li>

                    </ul>
                </div>

                <div class="">
                    <h2 class="text-black">What do we look for?</h2>
                    <ul class="regular">
                        <li>At least five years of experience as an SME in Business Intelligence disciplines.</li>
                        <li>At least two years of deep experience with Big Data solutions.</li>
                        <li>Basic expertise in working in a global environment.</li>
                        <li>Proven experience (minimum of 3 years) in managing complex, multi-dimensional demands.</li>
                        <li>Practical experience with customer onboarding, especially within the realm of financial
                            data.</li>
                    </ul>
                </div>

                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="list-black mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career.
                            By merging hands-on
                            client projects with vast resources, SignitiveAi offers a unique platform for growth, learning,
                            and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant
                            experience. We thank all
                            applicants for their interest, but only those selected for an interview will be contacted.
                            SignitiveAi Ltd is an
                            equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>