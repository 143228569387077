import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.css']
})
export class OurValuesComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: OurValuesComponent) {  
  context.title = "Our Values - Discover SignitiveAi Ltd's Core Values and Commitment to Innovative Solutions for Business Digital Transformation | Expert IT Product Engineering Services";
  context.description = "Learn about SignitiveAi’ s core values: client satisfaction, innovation, quality, integrity, and teamwork. We believe in delivering technology solutions that make a positive impact on our clients' businesses";
  context.keywords = 'SignitiveAi, our values, core values, client satisfaction, innovation, quality, integrity, teamwork, technology solutions, UK';
  context.seoTags();
}
