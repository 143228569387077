import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class LoaderService {
  private APIS_STACK: string[] = [];
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public get isLoading(): BehaviorSubject<boolean> {
    return this._isLoading;
  }



  showLoader(url: string) {



    this.addRequestToStack(url);
    this.isLoading.next(true);
  }
  
  hideLoader(url: string) {

    // console.log(req.method);
    this.removeRequestFromStack(url.includes('json') ? url : environment.base_url + url);
    if (this.APIS_STACK.length == 0)
      this.isLoading.next(false)
  }
  private addRequestToStack(url: string) {
    const index = this.APIS_STACK.indexOf(url);
    if (index == -1)
      this.APIS_STACK.push(url);
  }

  private removeRequestFromStack(url: string) {
    const index = this.APIS_STACK.indexOf(url);
    if (index > -1)
      this.APIS_STACK.splice(index, 1);
  }
}

