import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b141222',
  templateUrl: './blog-details-b141222.component.html',
  styleUrls: ['./blog-details-b141222.component.css']
})
export class BlogDetailsB141222Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB141222Component) {  
  context.title = 'Emerging Trends in ERP Systems: What Businesses Should Know';
  context.description = 'Discover the latest trends in ERP systems and learn how they can help your business streamline processes, integrate data, and improve overall operations. From cloud-based systems to AI and machine learning, stay ahead of the curve with these emerging trends';
  context.keywords = 'ERP systems, emerging trends, cloud-based ERP, mobile ERP, AI, machine learning, IoT integration, user experience, business operations, streamline processes.';
  context.seoTags();
}

