import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@modules/core';
import { BlogDetailsB30823Component } from './components/blog-details-b30823/blog-details-b30823.component';
import { BlogDetailsB14823Component } from './components/blog-details-b14823/blog-details-b14823.component';
import { BlogDetailsB4823Component } from './components/blog-details-b4823/blog-details-b4823.component';
import { BlogDetailsB12723Component } from './components/blog-details-b12723/blog-details-b12723.component';
import { BlogDetailsB19623Component } from './components/blog-details-b19623/blog-details-b19623.component';
import { BlogDetailsB8623Component } from './components/blog-details-b8623/blog-details-b8623.component';
import { BlogDetailsB17523Component } from './components/blog-details-b17523/blog-details-b17523.component';
import { BlogDetailsB5523Component } from './components/blog-details-b5523/blog-details-b5523.component';
import { BlogDetailsB19423Component } from './components/blog-details-b19423/blog-details-b19423.component';
import { BlogDetailsB29323Component } from './components/blog-details-b29323/blog-details-b29323.component';
import { BlogDetailsB22323Component } from './components/blog-details-b22323/blog-details-b22323.component';
import { BlogDetailsB15323Component } from './components/blog-details-b15323/blog-details-b15323.component';
import { BlogDetailsB8323Component } from './components/blog-details-b8323/blog-details-b8323.component';
import { BlogDetailsB1323Component } from './components/blog-details-b1323/blog-details-b1323.component';
import { BlogDetailsB22223Component } from './components/blog-details-b22223/blog-details-b22223.component';
import { BlogDetailsB15223Component } from './components/blog-details-b15223/blog-details-b15223.component';
import { BlogDetailsB8223Component } from './components/blog-details-b8223/blog-details-b8223.component';
import { BlogDetailsB1223Component } from './components/blog-details-b1223/blog-details-b1223.component';
import { BlogDetailsB25123Component } from './components/blog-details-b25123/blog-details-b25123.component';
import { BlogDetailsB18123Component } from './components/blog-details-b18123/blog-details-b18123.component';
import { BlogDetailsB11123Component } from './components/blog-details-b11123/blog-details-b11123.component';
import { BlogDetailsB4123Component } from './components/blog-details-b4123/blog-details-b4123.component';
import { BlogDetailsB141222Component } from './components/blog-details-b141222/blog-details-b141222.component';
import { BlogDetailsB91222Component } from './components/blog-details-b91222/blog-details-b91222.component';
import { BlogDetailsB71222Component } from './components/blog-details-b71222/blog-details-b71222.component';
import { BlogDetailsB301122Component } from './components/blog-details-b301122/blog-details-b301122.component';
import { BlogDetailsB161122Component } from './components/blog-details-b161122/blog-details-b161122.component';
import { BlogDetailsB21122Component } from './components/blog-details-b21122/blog-details-b21122.component';
import { BlogDetailsB261022Component } from './components/blog-details-b261022/blog-details-b261022.component';
import { BlogDetailsB191022Component } from './components/blog-details-b191022/blog-details-b191022.component';
import { BlogDetailsB51022Component } from './components/blog-details-b51022/blog-details-b51022.component';
import { BlogDetailsB28922Component } from './components/blog-details-b28922/blog-details-b28922.component';
import { BlogDetailsB21922Component } from './components/blog-details-b21922/blog-details-b21922.component';
import { BlogDetailsB7922Component } from './components/blog-details-b7922/blog-details-b7922.component';
import { BlogDetailsB17822Component } from './components/blog-details-b17822/blog-details-b17822.component';
import { BlogDetailsB10822Component } from './components/blog-details-b10822/blog-details-b10822.component';
import { BlogDetailsB3822Component } from './components/blog-details-b3822/blog-details-b3822.component';
import { BlogDetailsB20722Component } from './components/blog-details-b20722/blog-details-b20722.component';
import { BlogDetailsB6722Component } from './components/blog-details-b6722/blog-details-b6722.component';
import { BlogDetailsB15622Component } from './components/blog-details-b15622/blog-details-b15622.component';
import { BlogDetailsB1622Component } from './components/blog-details-b1622/blog-details-b1622.component';
import { BlogDetailsB26522Component } from './components/blog-details-b26522/blog-details-b26522.component';
import { BlogDetailsB19522Component } from './components/blog-details-b19522/blog-details-b19522.component';
import { BlogDetailsB15522Component } from './components/blog-details-b15522/blog-details-b15522.component';
import { BlogDetailsB12522Component } from './components/blog-details-b12522/blog-details-b12522.component';
import { BlogDetailsB8522Component } from './components/blog-details-b8522/blog-details-b8522.component';
import { BlogDetailsB4522Component } from './components/blog-details-b4522/blog-details-b4522.component';
import { BlogDetailsB28422Component } from './components/blog-details-b28422/blog-details-b28422.component';
import { BlogDetailsB24422Component } from './components/blog-details-b24422/blog-details-b24422.component';



@NgModule({
  declarations: [  
    BlogDetailsB30823Component,
    BlogDetailsB14823Component,
    BlogDetailsB4823Component,
    BlogDetailsB12723Component,
    BlogDetailsB19623Component,
    BlogDetailsB8623Component,
    BlogDetailsB17523Component,
    BlogDetailsB5523Component,
    BlogDetailsB19423Component,
    BlogDetailsB29323Component,
    BlogDetailsB22323Component,
    BlogDetailsB15323Component,
    BlogDetailsB8323Component,
    BlogDetailsB1323Component,
    BlogDetailsB22223Component,
    BlogDetailsB15223Component,
    BlogDetailsB8223Component,
    BlogDetailsB1223Component,
    BlogDetailsB25123Component,
    BlogDetailsB18123Component,
    BlogDetailsB11123Component,
    BlogDetailsB4123Component,
    BlogDetailsB141222Component,
    BlogDetailsB91222Component,
    BlogDetailsB71222Component,
    BlogDetailsB301122Component,
    BlogDetailsB161122Component,
    BlogDetailsB21122Component,
    BlogDetailsB261022Component,
    BlogDetailsB191022Component,
    BlogDetailsB51022Component,
    BlogDetailsB28922Component,
    BlogDetailsB21922Component,
    BlogDetailsB7922Component,
    BlogDetailsB17822Component,
    BlogDetailsB10822Component,
    BlogDetailsB3822Component,
    BlogDetailsB20722Component,
    BlogDetailsB6722Component,
    BlogDetailsB15622Component,
    BlogDetailsB1622Component,
    BlogDetailsB26522Component,
    BlogDetailsB19522Component,
    BlogDetailsB15522Component,
    BlogDetailsB12522Component,
    BlogDetailsB8522Component,
    BlogDetailsB4522Component,
    BlogDetailsB28422Component,
    BlogDetailsB24422Component,
  ],
  imports: [
    CommonModule,
    CoreModule,
  ]
})
export class BlogsModule { }
