import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b19522',
  templateUrl: './blog-details-b19522.component.html',
  styleUrls: ['./blog-details-b19522.component.css']
})
export class BlogDetailsB19522Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB19522Component) {  
  context.title = "Latest Trends in Robotic Process Automation (RPA) | What's New in RPA";
  context.description = 'Explore the latest trends in robotic process automation (RPA) and how they can improve your business processes. Discover hyperautomation, cloud-based RPA, process discovery, citizen developers, intelligent OCR, RPA and analytics, and human-robot collaboration';
  context.keywords = 'RPA, Robotic Process Automation, hyperautomation, cloud-based RPA, process discovery, citizen developers, intelligent OCR, RPA and analytics, human-robot collaboration, automation workflows, AI, ML, OCR, business processes, market size, trends';
  context.seoTags();
}

