import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-golang-software-engineer',
  templateUrl: './golang-software-engineer.component.html',
  styleUrls: ['./golang-software-engineer.component.css']
})
export class GolangSoftwareEngineerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: GolangSoftwareEngineerComponent) {  
  context.title = 'Golang Software Engineer Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'SignitiveAi, Golang Software Engineer, Go Developer, Backend Engineer, Software Development, Programming, Go Language, Golang, RESTful APIs, Microservices, Web Development, Distributed Systems, Concurrency, Algorithms, Data Structures, Database Design, SQL, NoSQL, Git, Agile Development, Scrum, Test-Driven Development, Continuous Integration, Deployment, DevOps, Linux, Docker, Kubernetes, Cloud Computing, AWS, GCP, Azure, Problem Solving, Communication Skills, Teamwork, Time Management, Full-time, Part-time, Remote, On-site, Job Opportunities, Hiring, Tech Industry, IT Jobs.';
  context.seoTags();
}
