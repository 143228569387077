<section class="contactSectionTop topMargin">
    <div class="container-main">
        <h1 class="mb-0">Privacy Policy</h1>   
    </div>
  </section>

  <section class="commonSection border-b sectionTopPd">
    <div class="container-main bgWhite">

      <div class="mb-20">
        <h2 class="text-black">Background:</h2>
        <P>SignitiveAi Ltd understands that your privacy is important to you and that you care about how your personal data
          is used. We respect and value the privacy of all our customers and staff and will only collect and use
          personal data in ways that are described here, and in a way that is consistent with our obligations and your
          rights under the law.</P>
      </div>


      <div class="mb-20">
        <h2 class="text-black">Information About Us:</h2>
        <h3 class="text-black">SignitiveAi Ltd.</h3>
        <div class="table-scroller">
          <table class="regular">
            <tbody>
              <tr>
                <td colspan="3">Limited Company registered in England under company number 13087133</td>
              </tr>
              <tr>
                <td>Registered address</td>
                <td>:</td>
                <td>124 City Road, London, England, EC1V 2NX</td>
              </tr>
              <tr>
                <td>VAT number</td>
                <td>:</td>
                <td>GB389587514</td>
              </tr>
              <tr>
                <td>Data Protection Officer</td>
                <td>:</td>
                <td>Hari Krishnan</td>
              </tr>
              <tr>
                <td>Email address:</td>
                <td>:</td>
                <td><a href="mailto:hari@signitiveai.com">hari@signitiveai.com</a></td>
              </tr>
              <tr>
                <td>Telephone number</td>
                <td>:</td>
                <td><a href="tel:4401268533827">(+44) 01268 533 827</a></td>
              </tr>
              <tr>
                <td>Postal address</td>
                <td>:</td>
                <td>124 City Road, London, England, EC1V 2NX</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="anchor-wp mb-100">
        <h2  class="text-black">THE CONTENT</h2>
        <a role="button" (click)="scroll(hyperlinkOne)">What Does This Notice Cover?</a><br>
        <a role="button" (click)="scroll(hyperlinkTwo)">What Is Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkThree)">What Are Your Rights?</a><br>
        <a role="button" (click)="scroll(hyperlinkFour)">What Personal Data Do We Collect and How?</a><br>
        <a role="button" (click)="scroll(hyperlinkFive)">How Do We Use Your Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkSix)">How Long Will We Keep Your Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkSeven)">How and Where Do We Store or Transfer Your Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkEight)">Do We Share Your Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkNine)">How Can You Access Your Personal Data?</a><br>
        <a role="button" (click)="scroll(hyperlinkTen)">How Do You Contact Us?</a><br>
        <a role="button" (click)="scroll(hyperlinkEleven)">Changes to this Privacy Notice</a>
      </div>

      <div class="forTarget">

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkOne>What Does This Notice Cover?</h2>
          <p>This Privacy Information explains how we use your personal data: how it is collected, how it is held, and
            how
            it is processed. It also explains your rights under the law relating to your personal data.</p>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkTwo>What Is Personal Data?</h2>
          <p>Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the Data
            Protection
            Legislation”) as ‘any information relating to an identifiable person who can be directly or indirectly
            identified by reference to an identifier.</p>
          <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal
            data covers obvious information such as your name and contact details, but it also covers less obvious
            information such as identification numbers, electronic location data, and other online identifiers.</p>
          <p>The personal data that we use is set out in Part 5, below.</p>
        </div>


        <div class="mb-20">
          <h2 class="text-black" #hyperlinkThree>What Are Your Rights?</h2>
          <p class="mb-5">Under the Data Protection Legislation, you have the following rights, which we will always
            work
            to uphold:</p>
          <ul class="regular">
            <li>The right to be informed about our collection and use of your personal data. This Privacy Notice should
              tell you everything you need to know, but you can always contact us to find out more or to ask any
              questions
              using the details in Part 11.</li>
            <li>The right to access the personal data we hold about you. Part 10 will tell you how to do this.</li>
            <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or
              incomplete. Please contact us using the details in Part 11 to find out more.</li>
            <li>The right to be forgotten, i.e., the right to ask us to delete or otherwise dispose of any of your
              personal data that we hold. Please contact us using the details in Part 11 to find out more.</li>
            <li>The right to restrict (i.e., prevent) the processing of your personal data.</li>
            <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
            <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for
              using your personal data, you are free to withdraw that consent at any time.</li>
            <li>The right to data portability. This means that, if you have provided personal data to us directly, we
              are
              using it with your consent or for the performance of a contract, and that data is processed using
              automated
              means, you can ask us for a copy of that personal data to re-use with another service or business in many
              cases.</li>
            <li>Rights relating to automated decision-making and profiling. Part 6 explains more about how we use your
              personal data, including automated decision-making<strong> AND </strong>profiling</li>
          </ul>
          <p>For more information about our use of your personal data or exercising your rights as outlined above,
            please
            contact us using the details provided in Part 11.</p>
          <p>It is important that your personal data is kept accurate and up to date. If any of the personal data we
            hold
            about you changes, please keep us informed as long as we have that data.</p>
          <p>Further information about your rights can also be obtained from the Information Commissioner’s Office or
            your
            local Citizens Advice Bureau.</p>
          <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a
            complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your
            concerns ourselves, however, so please contact us first, using the details in Part 11.</p>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkFour>What Personal Data Do We Collect and How?</h2>
          <p>We may collect and hold some or all of the personal and non-personal data set out in the table below, using
            the methods also set out in the table. We do not collect any ‘special category’ or ‘sensitive’ personal
            data<strong> OR </strong>personal data relating to children<strong> OR </strong>data relating to criminal
            convictions and/or offences<strong>.</strong></p>
          <div class="table-scroller">
            <table class="regular fullWidth">
              <tbody>
                <tr>
                  <td><strong>Data Collected</strong></td>
                  <td><strong>How We Collect the Data</strong></td>
                </tr>
                <tr>
                  <td>Name, Title, Date of Birth, Gender</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Contact information including address, email address, Telephone Number</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Business information including Business Name, Job Title, and Profession.</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Payment information including card details and bank account numbers</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Profile information including preferences, interests, purchase history</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Data from third parties including contact information, profile information</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Staff and contractors</strong></td>
                </tr>
                <tr>
                  <td>Identity Information including Name, Title, Date of Birth, Gender.</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Contact information including Address, Email Address, Phone Number</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format</td>
                </tr>
                <tr>
                  <td>Work information including information from the CV.</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Other identity information includes National Insurance Number, Nationality, Passport / Visa
                    information for the purpose of checking the right to work.</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
                <tr>
                  <td>Sensitive personal information including disability or health information for health and safety
                    purposes.</td>
                  <td>Online registration and business forms, over the phone - both paper and electronic format.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>Please refer to section 6 for data description, categories, and details on how we collect and use these
            data.
          </p>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkFive>How Do We Use Your Personal Data?</h2>
          <p class="mb-5">Under the Data Protection Legislation, we must always have a lawful basis for using personal
            data. The
            following table describes how we will OR may use your personal data, and our lawful bases for doing so:</p>

          <div class="">
            <ol class="regular">
              <li>In this Section we have set out:
                <ul>
                  <li>the general categories of personal data that we may process.</li>
                  <li>in the case of personal data that we did not obtain directly from you, the source, and specific
                    categories of that data.</li>
                  <li> the purposes for which we may process personal data; and</li>
                  <li>the legal bases of the processing.</li>
                </ul>
              </li>              
              <li>We may process data about your use of our website and services (“<strong>usage data</strong>“). The
                usage
                data may include your IP address, geographical location, browser type and version, operating system,
                referral source, length of visit, page views and website navigation paths, as well as information about
                the
                timing, frequency, and pattern of your service use. The source of the usage data is our analytics
                tracking
                system. This usage data may be processed for the purposes of analysing the use of the website and
                services.
                The legal basis for this processing is consent OR our legitimate interests, namely monitoring and
                improving
                our website and services.</li>
              <li>We may process your account data (“<strong>account data</strong>“). The account data may include your
                name
                and email address. The source of the account data is you or your employer. The account data may be
                processed
                for the purposes of operating our website, providing our services, ensuring the security of our website
                and
                services, maintaining back-ups of our databases, and communicating with you. The legal basis for this
                processing is consent OR our legitimate interests, namely the proper administration of our website and
                business OR the performance of a contract between you and us and/or taking steps, at your request, to
                enter
                such a contract.</li>
              <li>We may process your information included in your personal profile on our website] (“<strong>profile
                  data</strong>“). The profile data may include your name, address, telephone number, email address,
                profile
                pictures, gender, date of birth, relationship status, interests and hobbies, educational details, and
                employment details. The profile data may be processed for the purposes of enabling and monitoring your
                use
                of our website and services. The legal basis for this processing is consent OR our legitimate interests,
                namely the proper administration of our website and business OR the performance of a contract between
                you
                and us and/or taking steps, at you request, to enter such a contract.</li>
              <li>We may process your personal data that are provided during the use of our services (“<strong>service
                  data</strong>“). The service data may include<em> usage and account data</em>. The source of the
                service
                data is you or your employer. The service data may be processed for the purposes of operating our
                website,
                providing our services, ensuring the security of our website and services, maintaining back-ups of our
                databases, and communicating with you. The legal basis for this processing is consent OR our legitimate
                interests, namely the proper administration of our website and business OR the performance of a contract
                between you and us and/or taking steps, at your request, to enter such a contract.</li>
              <li>We may process information that you post for publication on our website or through our services
                (“<strong>publication data</strong>“). The publication data may be processed for the purposes of
                enabling
                such publication and administering our website and services. The legal basis for this processing is
                consent
                OR our legitimate interests, namely the proper administration of our website and business OR the
                performance
                of a contract between you and us and/or taking steps, at your request, to enter such a contract.</li>
              <li>We may process information contained in any enquiry you submit to us regarding goods and/or services
                (“<strong>enquiry data</strong>“). The enquiry data may be processed for the purposes of offering,
                marketing, and selling relevant goods and/or services to you. The legal basis for this processing is
                consent.</li>
              <li>We may process information relating to our customer relationships, including customer contact
                information
                (“<strong>customer relationship data</strong>“). The customer relationship data may include your name,
                your
                employer, your job title or role, your contact details, and information contained in communications
                between
                us and you or your employer. The source of the customer relationship data is you or your employer. The
                customer relationship data may be processed for the purposes of managing our relationships with
                customers,
                communicating with customers, keeping records of those communications, and promoting our products and
                services to customers. The legal basis for this processing is consent OR our legitimate interests,
                namely
                the proper management of our customer relationships.</li>
              <li>We may process information relating to transactions, including purchases of goods and services, that
                you
                enter with us and/or through our website (“<strong>transaction data</strong>“). The transaction data may
                include your contact details, your card details and the transaction details. The transaction data may be
                processed for the purpose of supplying the purchased goods and services and keeping proper records of
                those
                transactions. The legal basis for this processing is the performance of a contract between you and us
                and/or
                taking steps, at your request, to enter such a contract and our legitimate interests, namely the proper
                administration of our website and business.</li>
              <li>We may process information that you provide to us for the purpose of subscribing to our email
                notifications and/or newsletters (“<strong>notification data</strong>“). The notification data may be
                processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis
                for
                this processing is consent OR the performance of a contract between you and us and/or taking steps, at
                your
                request, to enter such a contract.</li>
              <li>We may process information contained in or relating to any communication that you send to us
                (“<strong>correspondence data</strong>“). The correspondence data may include the communication content
                and
                metadata associated with the communication. Our website will generate the metadata associated with
                communications made using the website contact forms. The correspondence data may be processed [for the
                purposes of communicating with you and record-keeping. The legal basis for this processing is our
                legitimate
                interests, namely the proper administration of our website and business and communications with users.
              </li>
              <li>We may process any of your personal data identified in this policy where necessary for the
                establishment,
                exercise, or defence of legal claims, whether in court proceedings or in an administrative or
                out-of-court
                procedure. The legal basis for this processing is our legitimate interests, namely the protection and
                assertion of our legal rights, your legal rights and the legal rights of others.</li>
              <li>We may process any of your personal data identified in this policy where necessary for the purposes of
                obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal
                basis for this processing is our legitimate interests, namely the proper protection of our business
                against
                risks.</li>
              <li>In addition to the specific purposes for which we may process your personal data set out in this
                Section
                3, we may also process any of your personal data where such processing is necessary for compliance with
                a
                legal obligation to which we are subject, or in order to protect your vital interests or the vital
                interests
                of another natural person.</li>
              <li>Please do not supply any other person’s personal data to us unless we prompt you to do so.</li>
            </ol>
          </div>

          <div class="">
            <p>With your permission and/or where permitted by law, we may also use your personal data for marketing
              purposes, which may include contacting you by email<strong> AND/OR </strong>telephone<strong> AND/OR
              </strong>text message<strong> AND/OR </strong>post with information, news, and offers on our
              products<strong>
                AND/OR </strong>services. You will not be sent any unlawful marketing or spam. We will always work to
              fully
              protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy
              and
              Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt
              out. We will always obtain your express opt-in consent before sharing your personal data with third
              parties
              for marketing purposes and you will be able to opt out at any time.</p>
            <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we
              reasonably believe that another purpose is compatible with that or those original purpose(s) and need to
              use
              your personal data for that purpose. If we do use your personal data in this way and you wish us to
              explain
              how the new purpose is compatible with the original, please contact us using the details in Part 11.</p>
            <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the
              purpose(s)
              for which it was originally collected, we will inform you and explain the legal basis which allows us to
              do
              so.</p>
            <p>In some circumstances, where permitted or required by law, we may process your personal data without your
              knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your
              legal rights.</p>
          </div>
        </div>






        <div class="mb-20">
          <h2 class="text-black" #hyperlinkSix>How Long Will We Keep Your Personal Data?</h2>
          <p class="mb-5">We will not keep your personal data any longer than is necessary in light of the reason(s) for
            which it was first collected. Your personal data will therefore be kept for the following periods (or, where
            there is no fixed period, the following factors will be used to determine how long it is kept):</p>
          <div class="table-scroller">
            <table class="regular halfWidth">
              <tbody>
                <tr>
                  <td><strong>Type of Data</strong></td>
                  <td><strong>How Long We Keep It</strong></td>
                </tr>
                <tr>
                  <td>Identity Information including Name, Title, Date of Birth and Gender</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Contact information including address, email address, Telephone Number</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Business information including Business Name, Job Title, and Profession.</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Payment information including card details and bank account numbers</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Profile information including preferences, interests, purchase history</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Data from third parties including contact information, profile information</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td><strong>Staff and contractors</strong></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Identity Information including Name, Title, Date of Birth, Gender.</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Contact information including Address, Email Address, Phone Number</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Payment information including bank account numbers</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Work information including information from the CV.</td>
                  <td>6 years</td>
                </tr>
                <tr>
                  <td>Other identity information includes National Insurance Number, Nationality, Passport/Visa
                    information for the purpose of checking the right to work.</td>
                  <td>2 years after employment ends</td>
                </tr>
                <tr>
                  <td>Sensitive personal information including disability or health information for health and safety
                    purposes.</td>
                  <td>6 years</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="mb-20">
          <h2 class="text-black" #hyperlinkSeven>How and Where Do We Store or Transfer Your Personal Data?</h2>
          <p>We will only store your personal data in the UK. This means that it will be fully protected under the Data
            Protection Legislation.</p>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkEight>Do We Share Your Personal Data?</h2>
          <p>We will not share any of your personal data with any third parties for any purposes, subject to the
            following
            exceptions.</p>
          <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a
            third party. Any new owner of our business may continue to use your personal data in the same way(s) that we
            have used it, as specified in this Privacy Policy.</p>
          <p>In some limited circumstances, we may be legally required to share certain personal data, which might
            include
            yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the
            instructions of a government authority.</p>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkNine>How Can You Access Your Personal Data?</h2>
          <p>If you want to know what personal data we have about you, you can ask us for details of that personal data
            and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
          </p>
          <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in
            Part
            11. To make this as easy as possible for you, a Subject Access Request Form is available for you to use. You
            do not have to use this form, but it is the easiest way to tell us everything we need to know to respond to
            your request as quickly as possible.</p>
          <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or
            excessive (for example, if you make repetitive requests) a fee may be charged to cover our administrative
            costs in responding.</p>
          <p>We will respond to your subject access request within 21 days and, in any case, not more than one month of
            receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within
            that time. In some cases, however, particularly if your request is more complex, more time may be required
            up
            to a maximum of three months from the date we receive your request. You will be kept fully informed of our
            progress.</p>
        </div>

        <div class="bgWhite mb-20">
          <h2 class="text-black" #hyperlinkTen>How Do You Contact Us?</h2>
          <p>To contact us about anything to do with your personal data and data protection, including making a subject
            access request, please use the following details (for the attention of Hari Krishnan):</p>
          <div class="table-scroller">
            <table class="regular halfWidth">
              <tbody>
                <tr>
                  <td>Email address</td>
                  <td>:</td>
                  <td><a href="mailto:hari@signitiveai.com">hari@signitiveai.com</a></td>
                </tr>
                <tr>
                  <td>Telephone number</td>
                  <td>:</td>
                  <td><a href="tel:4401268533827">(+44) 01268 533 827</a></td>
                </tr>
                <tr>
                  <td>Postal Address</td>
                  <td>:</td>
                  <td>124 City Road, London, England, EC1V 2NX</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="">
          <h2 class="text-black" #hyperlinkEleven>Changes to this Privacy Notice</h2>
          <p class="mb-0">We may change this Privacy Notice from time to time. This may be necessary, for example, if the law
            changes,
            or if we change our business in a way that affects personal data protection. Any changes will be made
            available through our website. This Privacy Notice was last updated on 04/01/2022.</p>
        </div>
      </div>
    </div>
  </section>
