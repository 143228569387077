<section class="bannerSectionAbout topMargin">
    <div class="container-main">
        <div class="">
            <h1>We Monitor Technologies</h1>
            <button type="button" class="btn btn-primary btn-outLine-primary" aria-label="Contact Us">Contact Us</button>
        </div>
    </div>
</section>


<section class="aboutUsSectionInner border-b">

    <div class="container-main">
        <div class="row-flex aboutImgOrder">
          <div class="items-col-8">
            <h2>About Us</h2>
            <div class="forMobileOnly">
              <img src="assets/images/about-us-img.png" alt="About Us image" width="408" height="408">
            </div>
            <p>We help you with our expertise across various industry verticals to provide you with an edge with digital
                transformation in the markets you excel. We deploy our in-depth experience to aid clients in progressing and enable
                them to respond efficiently and effectively to a dynamic market. We also use data assets to discover and unlock new
                value sources for companies, develop flexible technology platforms that abide with the business plans, and help
                swiftly create, deliver, and transform excellent digital products and experiences on different scales.</p>
          </div>
          <div class="items-col-3">
            <div class="forWebOnly">
              <img src="assets/images/about-us-img.png" alt="About Us image" width="408" height="408">
            </div>
          </div>
        </div>
      </div>

</section>


<section class="aboutUsSectionInner bgLightBlue border-b">

    <div class="container-main">
        <div class="row-flex aboutImgOrder">
            <div class="items-col-3">
                <div class="forWebOnly itemLeft">
                  <img src="assets/images/our-mission-img.png" alt="Document Management" width="408" height="408">
                </div>
              </div>
          <div class="items-col-8">
            <h2>Our Mission</h2>
            <div class="forMobileOnly">
              <img src="assets/images/our-mission-img.png" alt="Document Management" width="408" height="408">
            </div>
            <p>Our mission is to build a seamless journey for our customers with maximum business value without disrupting existing
                business. Our team works with companies to understand the needs and offer insights into the core environment and
                financial workflows through research and development, creating a value proposition on quality, functionality,
                usability, and cost.</p>
          </div>
        </div>
      </div>

</section>


<section class="aboutUsSectionInner border-b">

    <div class="container-main">
        <div class="row-flex aboutImgOrder">
          <div class="items-col-8">
            <h2>Our Purpose</h2>
            <div class="forMobileOnly">
                <img src="assets/images/our-purpose-img.png" alt="Our Purpose image" width="408" height="408">
            </div>
            <p>Organizations require adequate technical expertise and infrastructure to implement AI and Big Data in their digital
                operations. Our mission is to build and deploy intelligent solutions to support financial institutions to create
                decisions which are best for the company, quicker in creation and execution, and at different scales with Data and
                AI.</p>
          </div>
          <div class="items-col-3">
            <div class="forWebOnly">
                <img src="assets/images/our-purpose-img.png" alt="Our Purpose image" width="408" height="408">
            </div>
          </div>
        </div>
      </div>

</section>




<!--work with starts-->
<app-contact-form></app-contact-form>
