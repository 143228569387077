import { Component, Input, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';
import { DataTransferService } from '@utils';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.css']
})
export class PopupModalComponent extends BaseClassUi implements OnInit {

  messages = [
    { key: 'contactUs', header: 'Message Received Successfully', message: 'We have successfully received your message and will get back to you quickly as possible.' },
    { key: 'applyNow', header: 'Form Submitted Successfully', message: 'We have successfully received your application form and will get back to you quickly as possible. Thankyou.' }
  ];
  selectedMessages: { key: string; header: string; message: string; };
  sub: Subscription;
  constructor(public dataService: DataTransferService) { super() }

  ngOnInit(): void {
    getSharedMessage(this);
  }

  ngOnDestroy() {
    if (this.sub && !this.sub.closed)
      this.sub.unsubscribe();
  }

}

function getSharedMessage(context: PopupModalComponent) {
  context.sub = context.dataService.shareDataObservable.subscribe(data => {
    console.log(data);
    if (data)
      context.selectedMessages = context.messages.find(e => e.key === data);
  })
}