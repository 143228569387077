<div class="social-wp mb-30">
    <p>Follow Us On:</p>
    <div class="social-icons">
        <a href="#" target="_blank" title="VK"><img src="assets/images/icons/vk-icon.svg" alt="vk icon"
                width="24" height="24"></a>
        <a href="#" target="_blank" title="Pinterest"><img src="assets/images/icons/pinterest-icon.svg"
                alt="Pinterest icon" width="24" height="24"></a>
        <a href="#" target="_blank" title="Instagram"><img src="assets/images/icons/instagram-icon.svg"
                alt="Instagram icon" width="24" height="24"></a>
        <a href="#" target="_blank" title="Twitter"><img src="assets/images/icons/twitter-icon.svg"
                alt="Twitter icon" width="24" height="24"></a>
        <a href="#" target="_blank" title="Facebook"><img src="assets/images/icons/facebook-icon.svg"
                alt="Facebook icon" width="24" height="24"></a>
    </div>
</div>
<button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue btn-100" (click)="scrollInto('goToApplyForm')"
    aria-label="Go to Apply Form">Go to Apply Form</button>
