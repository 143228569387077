import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  showCopied: boolean = false;

  
  ngOnInit(): void {
    this.gotoTopFunction();
    window.addEventListener('scroll', this.showScrollToTopBtn, true);    
  }
  showScrollToTopBtn() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      document.getElementById("gototop-btn").style.display = "block";
    } else {
      document.getElementById("gototop-btn").style.display = "none";
    }
  }
  // When the user clicks on the button, scroll to the top of the document
  gotoTopFunction() {
    window.scroll({top: 0,left: 0,behavior: 'smooth'});
  }

  copyToClipboard(text: string) {
    this.copyTextToClipboard(text);
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000); // Hide the message after 2 seconds
  }

  copyTextToClipboard(text: string) {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
  
  showQuickChatModal() {
    (document.querySelector('.siteBackdrop') as HTMLElement).style.display = "block";
    document.querySelector('.slide-quick-chat')!.classList.add("show-chat");
  }
 

}
