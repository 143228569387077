import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorMessages } from '@constants';
import * as moment from 'moment';
import { anyDropdownSelection, checkBoolean, checkRadioButtonSelected, formArrayMinLength, minLengthValidator, minMaxLengthValidator, minMaxValidation, minValidation, onlyNumeric, passwordConfirming, pattern, requiredField, requiredFieldWithZero, validateDate, validateDateAndTime } from '../utils/CustomValidators';
import { WhiteSpaceValidator } from './WhiteSpaceValidator';
@Injectable({
  providedIn: 'root'
})
export class ValidationsService {
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  constructor() { }


  public addRequiredValidation() {
    return Validators.compose([requiredField()]);
  }
  public addRadioButtonValidation() {
    return Validators.compose([checkRadioButtonSelected()]);
  }

  public addMinLengthFormArrayValidation(length: number) {
    return Validators.compose([formArrayMinLength(length)]);
  }

  public addRequiredWithWhitespaceValidation() {
    return Validators.compose([requiredField(), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addRangeValidator(minLength: number, maxLength: number) {
    return Validators.compose([minMaxValidation(minLength, maxLength)]);
  }

  public addOnlyMinValidator(minLength: number) {
    return Validators.compose([minValidation(minLength)]);
  }

  public addTextFieldValidation() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 100), pattern(/^[A-Za-z ]+$/, ErrorMessages.errorInvalidText), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addMinMaxNoSpacialValidation(minLength: number, maxLength: number) {
    var re = /^[A-Za-z0-9 ]+$/;
    return Validators.compose([requiredField(), minMaxLengthValidator(minLength, maxLength), pattern(re, ErrorMessages.errorInvalidText), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addMaxMinLengthValidation(minLength: number, maxLength: number) {
    return Validators.compose([requiredField(), minMaxLengthValidator(minLength, maxLength)]);
  }

  public addNonRequiredValidation() {
    return Validators.compose([WhiteSpaceValidator.cannotContainSpace]);
  }

  public checkAge(): ValidatorFn | null {
    return Validators.compose([this.validateIfAge18()]);

  }

  public addTextFieldValidationWithSpecialCharacter() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 100), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addTextFieldValidationsLength200() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 200), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addTextareaValidation() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 500), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addTextareaValidationWithoutRequired() {
    return Validators.compose([minMaxLengthValidator(2, 500), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addMaxLengthValidation() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 4000), WhiteSpaceValidator.cannotContainSpace]);
  }
  public passwordMatchValidation() {
    return Validators.compose([requiredField(), passwordConfirming(), WhiteSpaceValidator.cannotContainSpace]);

  }
  public addEmailValidation() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Validators.compose([requiredField(), pattern(re, ErrorMessages.errorInvalidEmailAddress)]);
  }

  public addEmailValidationNonRequired() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Validators.compose([pattern(re, ErrorMessages.errorInvalidEmailAddress)]);
  }


  public addNoSpeicalCharacterInStringValidation() {
    var re = /^[A-Za-z0-9 ]+$/;
    return Validators.compose([pattern(re, ErrorMessages.errorInvalidText), minMaxLengthValidator(2, 100), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addOnlyAlphabetsValidation() {
    var re = /^[A-Za-z ]+$/;
    return Validators.compose([pattern(re, ErrorMessages.errorInvalidText), WhiteSpaceValidator.cannotContainSpace]);
  }

  public checkButtonCheckedOrnot() {
    return Validators.compose([this.validateIfChecked()]);
  }

  public addOnlyLengthValidation(minLength: number, maxlength: number) {
    return Validators.compose([minMaxLengthValidator(minLength, maxlength)]);
  }

  public addPhoneNumberValidation(minLength: number, maxlength: number = 14) {
    return Validators.compose([requiredField(), minMaxLengthValidator(minLength, maxlength), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addNoRequiredOnlyNumericValidation() {
    var re = /^[0-9]+$/;
    return Validators.compose([pattern(re, ErrorMessages.onlyNumbers), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addOnlyNumericValidation(minLength: number, maxLength: number) {
    var re = /^[0-9]+$/;
    return Validators.compose([requiredField(), onlyNumeric(minLength, maxLength), pattern(re, ErrorMessages.onlyNumbers), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addOnlyNumericWithNonZeroValidation(minLength: number, maxLength: number) {
    var re = /^[1-9]+$/;
    return Validators.compose([requiredField(), onlyNumeric(minLength, maxLength), pattern(re, ErrorMessages.onlyNumbers), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addTextFieldValidationWithNumericValue() {
    return Validators.compose([requiredField(), minMaxLengthValidator(2, 100), WhiteSpaceValidator.cannotContainSpace]);
  }
  public addNoZeroNumericValidation() {
    var re = /^[1-9]+$/;
    return Validators.compose([pattern(re, ErrorMessages.errorMsgForZero), requiredField()]);
  }

  public addTextFieldValidationWithAlphaNumericValue() {
    var re = /^[A-Za-z0-9 ]+$/;
    return Validators.compose([pattern(re, ErrorMessages.patternErrorSpecialCharacters), requiredField(), minMaxLengthValidator(2, 100), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addNoRequiredTextFieldValidationWithAlphaNumericValue() {
    var re = /^[A-Za-z0-9 ]+$/;
    return Validators.compose([pattern(re, ErrorMessages.patternErrorSpecialCharacters), WhiteSpaceValidator.cannotContainSpace]);
  }

  public negetiveValueRestriction() {
    var re = /^\d+(?:\.\d{0,2})?$/;

    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([requiredField(), pattern(re, ErrorMessages.errorDecimal), WhiteSpaceValidator.cannotContainSpace]);
  }

  public ZeroError() {
    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([requiredFieldWithZero(), WhiteSpaceValidator.cannotContainSpace]);
  }

  public negetiveValueRestrictionWithRequired() {
    var re = /^\d+(?:\.\d{0,2})?$/;

    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([pattern(re, ErrorMessages.errorDecimal), WhiteSpaceValidator.cannotContainSpace]);
  }


  public negetiveValueWithZeroRestriction() {
    var re = /^\d+(?:\.\d{0,2})?$/;

    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([requiredFieldWithZero(), pattern(re, ErrorMessages.errorDecimal), WhiteSpaceValidator.cannotContainSpace]);
  }

  public negetiveValueRestrictionnonemptywithdecimal() {
    var re = /^\d+(?:\.\d{1,2})?$/;
    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([pattern(re, ErrorMessages.errorDecimal), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addTextFieldValidationWithAlphaNumericspaceallowedValue() {
    var re = /^[A-Za-z0-9]+$/;
    return Validators.compose([pattern(re, ErrorMessages.patternErrorSpecialCharacters), requiredField(), minMaxLengthValidator(2, 100)]);
  }

  public addTextFieldValidationWithAlphaNumericspaceallowedpostcodeValue() {
    var re = /^[A-Za-z0-9]+$/;
    return Validators.compose([pattern(re, ErrorMessages.patternErrorSpecialCharacters)]);
  }

  public norequiredNegativeValueRestriction() {
    var re = /^\d+(?:\.\d{1,2})?$/;
    //var dec = /^[0-9]+(\.[0-9]{1,2})?$/;
    return Validators.compose([pattern(re, ErrorMessages.errorDecimal), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addOnlyNumericValidationNotMandatory(minLength: number, maxLength: number) {
    //var re = /^[0-9]+$/;
    return Validators.compose([onlyNumeric(minLength, maxLength), WhiteSpaceValidator.cannotContainSpace]);
  }

  public addBooleanCheck() {
    return Validators.compose([checkBoolean()]);
  }

  public addAnyDropdownSelectionValidation() {
    return Validators.compose([anyDropdownSelection()]);
  }

  public addDateValidation() {
    return Validators.compose([requiredField(), validateDate()])
  }
  public addDateTimeValidation() {
    return Validators.compose([requiredField(), validateDate()])
  }

  public addDateWithoutRequiredValidation() {
    return Validators.compose([validateDate()])
  }

  public addDateTimeWithoutRequiredValidation() {
    return Validators.compose([validateDateAndTime()])
  }


  validateIfChecked() {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control.value;
      if (!v) {
        return {
          'err': "checkboxinvalid"
        };
      }
      return null;
    }
  }
  validateIfAge18(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let v: any = control?.value;
      let age = moment().diff(v, 'years', false)
      if (age < 18) {
        return {
          'agenoteligible': ErrorMessages.agenoteligible
        };
      }
      return null;
    }
  }

}




