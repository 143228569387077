import { HttpClient, HttpParams } from '@angular/common/http';
import { Apis } from '@constants';
import { AppInjector } from '@utils';
import { Observable, Subject, forkJoin } from 'rxjs';
import { HttpMethods, ResponseType } from '../enum';
import { IApiResponseCallback } from '../interfaces';
import { ErrorModel, RequestOptions, SuccessResponseModel } from "../models";
import { LoaderService } from '../services';
import { BaseClassParent } from "./base-class-parent";

export class BaseClassApi extends BaseClassParent {
    private _httpClient: HttpClient;
    private _responseSubject: Subject<SuccessResponseModel> = new Subject();
    private _loaderService: LoaderService;

    public get loaderService(): LoaderService {
        if (!this._loaderService)
            this._loaderService = AppInjector.injector.get(LoaderService);
        return this._loaderService;
    }



    // ***** CALLING GET APIS  ***** //
    protected hitHttpGetRequest<T>(apiUrl: string, apiResponseCallBack: IApiResponseCallback<T>, params?: HttpParams, headers?: any) {
        let requestOptions: RequestOptions = this.getRequestOptions();
        if (params)
            requestOptions.params = params;
        if (headers)
            requestOptions.headers = headers;
        this.request(HttpMethods.GET, apiUrl, apiResponseCallBack, requestOptions);
    }


    // ***** CALLING POST APIS  ***** //
    protected hitHttpPostRequest<T>(apiUrl: string, requestBody: any, apiResponseCallBack: IApiResponseCallback<T>, params?: HttpParams, headers?: any) {
        let requestOptions: RequestOptions = this.getRequestOptions();
        if (params)
            requestOptions.params = params;
        if (headers)
            requestOptions.headers = headers;
        return this.request(HttpMethods.POST, apiUrl, apiResponseCallBack, requestOptions, requestBody);

    }

    // ***** CALLING PUT APIS  ***** //
    protected hitHttpPutRequest<T>(apiUrl: string, requestBody: any, apiResponseCallBack: IApiResponseCallback<T>, headers?: any) {
        let requestOptions: RequestOptions = this.getRequestOptions();
        requestOptions.headers = headers;
        return this.request(HttpMethods.PUT, apiUrl, apiResponseCallBack, requestOptions, requestBody);
    }

    // ***** CALLING DELETE APIS  ***** //
    protected hitHttpDeleteRequest<T>(apiUrl: string, apiResponseCallBack: IApiResponseCallback<T>, params?: HttpParams) {
        let requestOptions: RequestOptions = this.getRequestOptions();
        requestOptions.params = params;
        this.request(HttpMethods.DELETE, apiUrl, apiResponseCallBack, requestOptions);
    }


    // ***** CALLING MULTIPLE APIS PARALLELY USING API URLS ***** //
    protected hitMultipleRequestForApiUrls<T>(apis: string[], apiResponseCallBack: IApiResponseCallback<T>, httpParams?: HttpParams) {
        let observables: Observable<any>[] = [];
        for (let index = 0; index < apis.length; index++) {
            const element = apis[index];
            observables.push(this.getHttpGetRequestObservable(element, httpParams));
        }
        this.hitMultipleRequestForObservables(observables, apis, apiResponseCallBack);
    }


    protected getHttpGetRequestObservable(apiUrl: string, params?: HttpParams): Observable<any> {
        let requestOptions: RequestOptions = this.getRequestOptions();
        requestOptions.params = params;
        return this.createRequest(HttpMethods.GET, apiUrl, requestOptions);
    }

    // ***** CALLING MULTIPLE APIS PARALLELY USING RXJS/OBSERVALES ***** //
    protected hitMultipleRequestForObservables<T>(observables: Observable<any>[], apis: string[], apiResponseCallBack: IApiResponseCallback<T>) {
        forkJoin(observables).subscribe({
            next: (response: any) => {
                this._hideLoaderForJoin(apis);
                apiResponseCallBack.handleResponse(response);
            },
            error: (errorModel: ErrorModel) => {
                this._hideLoaderForJoin(apis);
                if (!this._hideErrorToast(apis))
                    this.commonFunctions.showErrorSnackBar(errorModel.message);
                if (apiResponseCallBack.handleError)
                    apiResponseCallBack?.handleError(errorModel);
            }
        });
    }

    private _hideLoaderForJoin(apis: string[]) {
        for (let index = 0; index < apis.length; index++) {
            const element = apis[index];
            this.loaderService.hideLoader(element);
        }
    }


    private request<T>(method: string, url: string, apiResponseCallback: IApiResponseCallback<T>, requestOptions?: RequestOptions, requestBody?: any) {
        let req = this.createRequest(method, url, requestOptions, requestBody);
        req.subscribe({
            next: (response: any) => {
                this.loaderService.hideLoader(url)
                apiResponseCallback.handleResponse(response);
            },
            error: (errorModel: ErrorModel) => {
                this.loaderService.hideLoader(url)
                this.commonFunctions.showErrorSnackBar(errorModel.message);
                if (apiResponseCallback.handleError)
                    apiResponseCallback.handleError(errorModel);
            }
        });
    };

    private createRequest<T>(method: string, url: string, requestOptions?: RequestOptions, requestBody?: any): Observable<T> {
        const completeUrl = url;
        let requestObservable!: Observable<T>;
        switch (method) {
            case HttpMethods.GET:
                requestObservable = this.httpClient.get<T>(completeUrl, { headers: requestOptions?.headers, params: requestOptions?.params, reportProgress: requestOptions?.reportProgress, withCredentials: requestOptions?.withCredentials, responseType: requestOptions?.responseType });
                break;
            case HttpMethods.POST:
                requestObservable = this.httpClient.post<T>(completeUrl, requestBody, { headers: requestOptions?.headers, params: requestOptions?.params, reportProgress: requestOptions?.reportProgress, withCredentials: requestOptions?.withCredentials, responseType: requestOptions?.responseType });
                break;
            case HttpMethods.PUT:
                requestObservable = this.httpClient.put<T>(completeUrl, requestBody, { headers: requestOptions?.headers, params: requestOptions?.params, reportProgress: requestOptions?.reportProgress, withCredentials: requestOptions?.withCredentials, responseType: requestOptions?.responseType });
                break;
            case HttpMethods.DELETE:
                requestObservable = this.httpClient.delete<T>(completeUrl, { headers: requestOptions?.headers, params: requestOptions?.params, reportProgress: requestOptions?.reportProgress, withCredentials: requestOptions?.withCredentials, responseType: requestOptions?.responseType });
                break;
            default:
                break;
        }
        return requestObservable;


        //return this.httpClient.request<T>(new HttpRequest("POST", environment.base_url + url, requestOptions.body, requestOptions));
    }
    private getRequestOptions(): RequestOptions {
        return { body: {}, headers: {}, params: {}, reportProgress: false, context: {}, withCredentials: false, responseType: ResponseType.JSON };
    }

    protected sendDataToComponent(functionName: any, response: any) {
        const successResponseModel: SuccessResponseModel = { functionName, response };
        this.responseSubject.next(successResponseModel);
        return successResponseModel;
    }

    protected get httpClient() {
        if (!this._httpClient) {
            this._httpClient = AppInjector.injector.get(HttpClient);
        }
        return this._httpClient;
    }



    protected loadNavItems() {
        //   this.store.dispatch(loadNavItems.trigger());
    }
    public get responseSubject(): Subject<any> {
        return this._responseSubject;
    }

    protected get apis() {
        return Apis;
    }
    private _hideErrorToast(urls: string[]) {
        let hideToast: boolean = false;
        // for (let index = 0; index < urls.length; index++) {
        //     const element = urls[index];
        //     hideToast = this.dontShowErrorMsgApis.map(e => element.includes(e))[0];

        //     if (hideToast) {
        //         break;
        //     }

        // }

        return hideToast;
    }
}
