import { AppInjector, CommonFunctionsService, DataHoldingService, DataTransferService, MyStorage, ValidationsService } from "@utils";
import { Observable, Subject, takeUntil } from "rxjs";
import { AppConstants, Paths } from "@constants";

export class BaseClassParent {

    private _commonFunctions: CommonFunctionsService;
    private _myStorage: MyStorage;
    private _dataTransferService: DataTransferService;
    private _dataHoldingService: DataHoldingService;
    private _validationService: ValidationsService;
    private _killerSubject: Subject<any> = new Subject();


    public get commonFunctions() {
        if (!this._commonFunctions)
            this._commonFunctions = AppInjector.injector.get(CommonFunctionsService);
        return this._commonFunctions;
    }

    protected get constants() {
        return AppConstants;
    }

    public get paths(){
        return Paths;
    }

    protected get myStorage(): MyStorage {
        if (!this._myStorage)
            this._myStorage = AppInjector.injector.get(MyStorage);
        return this._myStorage;
    }

    protected get dataTransferService(): DataTransferService {
        if (!this._dataTransferService)
            this._dataTransferService = AppInjector.injector.get(DataTransferService);
        return this._dataTransferService;
    }

    protected get dataHoldingService(): DataHoldingService {
        if (!this._dataHoldingService)
            this._dataHoldingService = AppInjector.injector.get(DataHoldingService);
        return this._dataHoldingService;
    }

    public get validationService(): ValidationsService {
        if (!this._validationService)
            this._validationService = AppInjector.injector.get(ValidationsService);
        return this._validationService;
    }


    protected get killerSubject(): Subject<any> {
        return this._killerSubject;
    }



    protected getObservableWithTakeUntil(observable: Observable<any>) {
        return observable.pipe(takeUntil(this.killerSubject));
    }


    protected unsuscribeAllSubject() {
        this.killerSubject.next(null);
    }

}
