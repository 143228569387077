import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b17523',
  templateUrl: './blog-details-b17523.component.html',
  styleUrls: ['./blog-details-b17523.component.css']
})
export class BlogDetailsB17523Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB17523Component) {  
  context.title = 'Building a Culture of Compliance in the Tech Industry: Addressing Ethical Challenges and Organizational Success';
  context.description = "Explore how creating a culture of compliance in the tech industry goes beyond legal obligations. Learn the importance of embedding ethics into your organization's culture to attract talent, enhance productivity, and drive success. Discover practical steps for building and sustaining a strong compliance culture.";
  context.keywords = 'compliance culture, tech industry compliance, ethical challenges, organizational success, compliance training, employee engagement, regulatory compliance, talent attraction, ethics in tech, culture of compliance, compliance management, ethical behavior, regulatory standards, business ethics, workplace compliance, tech company compliance, compliance initiatives, employee empowerment, compliance best practices, ethical standards.';
  context.seoTags();
}


