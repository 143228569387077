import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b15522',
  templateUrl: './blog-details-b15522.component.html',
  styleUrls: ['./blog-details-b15522.component.css']
})
export class BlogDetailsB15522Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB15522Component) {  
  context.title = 'Hyperautomation in Business: Benefits, Challenges, and Examples';
  context.description = 'Learn how Hyperautomation can benefit your organization by automating complex business processes and achieving digital transformation. Discover the challenges of implementing Hyperautomation and the examples of successful applications';
  context.keywords = 'Hyperautomation, business automation, artificial intelligence, machine learning, robotic process automation, digital transformation, software development life cycle, collaboration, productivity, challenges, job displacement';
  context.seoTags();
}

