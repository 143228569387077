<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Workflow Optimization</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
    
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
        <div class="itemsTwo">
            <p>Embrace the future of workflow management with our innovative Workflow Vault. Revolutionize your processes by
                effortlessly defining and adapting workflows, eliminating manual errors, and boosting efficiency. Stay ahead of
                the curve and drive your business forward with our state-of-the-art solution.</p>
        </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/software-developer.webp" alt="Software developer" width="278"
                        height="262" onerror="this.src='assets/images/software-developer.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/comprehensive-process-icon.svg" alt="Comprehensive Process" width="90" height="90">
                        <p>Comprehensive Process Components</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/efficient-workflow-icon.svg" alt="Efficient Workflow" width="90" height="90">
                        <p>Efficient Workflow Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-configuration-icon.svg" alt="Seamless Configuration" width="90" height="90">
                        <p>Seamless Configuration and Testing</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/process-vault-icon.svg" alt="Process Vault" width="90" height="90">
                        <p>Stay Updated with Process Vault</p>
                    </div>
                </div>
            </div>
  
        </div>   
  
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Pre-defined workflow processes that serve as a foundation for managing business processes.</li>
                            <li>Ability to create custom workflows tailored to specific business needs, with options to upload existing workflows.</li>
                            <li>Tools for configuring and testing workflows, including live scenario configurations and real-time testing.</li>
                            <li>Seamless integration with downstream applications and systems to streamline workflow management.</li>
                            <li>Access to an updated organizational process vault and open workflows from a global community.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-two.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
  
  
    </div>
  </section>
  
  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>