import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseClassUi } from '@modules/core/base';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

  export class HeaderComponent extends BaseClassUi implements OnInit {

    constructor(private _router: Router) { super() }
    currentSubMenu: HTMLElement;
    selectedMenuIndex: number = 0;
    isHome: boolean = true;
    private _HOME_PATHS: string[] = [""];
    private _SERVICES_PATHS: string[] = ["product-engineering", "leadership-and-management", "learning-services"];
    private _TECHNOLOGY_PATHS: string[] = ["case-studies", "mobility", "service-now"];
    private _ABOUT_PATHS: string[] = ["who-we-are", "our-values", "esg", "career", "blogs"];
    private _CONTACT_PATHS: string[] = ["contact-us"];
  
  
    ngOnInit(): void {
      window.addEventListener('scroll', this.removeTransparentHeder, true);
      this._router.events.subscribe((event: any) => {        
        if (event instanceof NavigationEnd) {          
          if (this._router.url == "/") {
            this.isHome = true;
            window.addEventListener('scroll', this.removeTransparentHeder, true);
          }
          else if (this._router.url == "/home") {
            this.isHome = true;
            window.addEventListener('scroll', this.removeTransparentHeder, true);
          }
          else {
            this.isHome = false;
            window.removeEventListener('scroll', this.removeTransparentHeder, true);
          }
  
          const actualPath = event.url.substring(event.url.indexOf("/") + 1, event.url.length);
          console.log(actualPath);
          if (this._HOME_PATHS.includes(actualPath)) {
            this.selectedMenuIndex = 0;
          }
          else if (this._SERVICES_PATHS.includes(actualPath)) {
            this.selectedMenuIndex = 1;
          }
          else if (this._TECHNOLOGY_PATHS.includes(actualPath)) {
            this.selectedMenuIndex = 2;
          }
          else if (this._ABOUT_PATHS.includes(actualPath)) {
            this.selectedMenuIndex = 3;
          }
          else if (this._CONTACT_PATHS.includes(actualPath)) {
            this.selectedMenuIndex = 4;
          }
  
  
        }
  
      });
    }
  
    //show mobile menu function here
    showMenu() {
      (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "block";
      (document.querySelector('.mobileMenuList') as HTMLElement)!.style.right = "0px";
      document.querySelector('body').classList.add("openModal");
    }
    //close mobile menu function here
    closeMenu() {
      (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "none";
      (document.querySelector('.mobileMenuList') as HTMLElement)!.style.right = "-100%";
      document.querySelector('body').classList.remove("openModal");
    }
  
    openSubMenu(event: MouseEvent) {
      this.currentSubMenu = ((event.target as HTMLElement).querySelector('ul'));
      this.currentSubMenu.style.display = 'block';
    }
  
    closeSubMenu(event: MouseEvent) {
      if (this.currentSubMenu)
        this.currentSubMenu.style.display = 'none';
    }
  
    onSubMenuClick(index: number) {
      this.selectedMenuIndex = index;
      if (this.currentSubMenu)
        this.currentSubMenu.style.display = 'none';
    }
  
    //mobile menu dropdown function here
    showDropdownMenu(e: any) {
      var elems = document.querySelector(".active");
      if (elems !== null) {
        elems.classList.remove("active");
      }
      e.target.className = "active";
    }
  
  

  }
  