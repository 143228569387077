import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b21922',
  templateUrl: './blog-details-b21922.component.html',
  styleUrls: ['./blog-details-b21922.component.css']
})
export class BlogDetailsB21922Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB21922Component) {  
  context.title = 'Latest Trends in Architecture Design for IT Product Development ';
  context.description = 'Explore the latest trends in architecture design for IT product development, including cloud-native, microservices, event-driven, serverless, and data-driven architectures. Learn how these trends can help businesses create efficient and resilient applications';
  context.keywords = 'architecture design, IT product development, cloud-native architecture, microservices architecture, event-driven architecture, serverless architecture, data-driven architecture, scalability, efficiency, resilience, agility, cost savings';
  context.seoTags();
}

