import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent extends BaseClassUi implements OnInit{

  constructor() {
    super()
   }

  ngOnInit(): void {
    init(this);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}

function init(context: PrivacyPolicyComponent) {  
  context.title = 'Privacy Policy - SignitiveAi';
  context.description = 'SignitiveAi Ltd understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of all our customers and staff and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
