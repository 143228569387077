import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx',
  templateUrl: './merfx.component.html',
  styleUrls: ['./merfx.component.css']
})
export class MerfxComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxComponent) {
  context.title = 'Transform Car Finance Operations: Streamline, Secure, and Optimize with Product-X';
  context.description = 'Discover how Product-X revolutionizes car finance operations with a unified platform for enhanced efficiency and security. Manage data across departments, track vehicle and customer journeys, and ensure error-free processes from acquisition to contract creation. Features include repossession management, lead tracking, advanced reporting, and a dedicated customer portal. Streamline operations, safeguard data privacy, and drive business growth with customizable solutions.';
  context.keywords = 'Car finance operations, streamlined car finance, secure car finance, Product-X, car finance management, vehicle tracking, customer journey tracking, repossession management, lead tracking, data privacy, cloud-based CRM, advanced reporting, customer portal, self-service options, contract approval, procurement processes, payment management, data-driven decision-making, digital transformation in finance.';
  context.seoTags();
}



