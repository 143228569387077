<!-- main container beginning here -->
<section class="section-santriDoc-banner topPadding">
    <div class="innerSpace productxBg">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-lg-col-2 itemTitle">
            <h1>Revolutionize Your Operations with Intelligent Solutions</h1>
            <p>Harness the power of AI to optimize processes and drive success.</p>
            <div class="btn-flex">
              <button type="button" class="btn btn-rounded-white" aria-label="Request Demo">Request Demo</button>
              <button type="button" class="btn btn-rounded-navyBlue" aria-label="Get Started">Get Started</button>
            </div>
          </div>
          <!-- <div class="items-lg-col-2 itemImg">
            <div class="img-wp">
              <img src="assets/images/mortgage-solution-login-img.svg" alt="We automate your business processes" width="270" height="560">
            </div>                     
          </div> -->
        </div>      
      </div>
    </div>         
  </section>


  <section class="section-details">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-1">
          <div class="card-details">
            <div class="items">
              <p>Efficient Document Management</p>
            </div>
            <div class="items">
              <p>Data Transparency</p>
            </div>
            <div class="items">
              <p>AI-Powered Process Improvement</p>
            </div>
            <div class="items">
              <p>Workflow Optimization</p>
            </div>
          </div>
        </div>
      </div>      
    </div>         
</section>
  
 
  <section class="sectionPrimaryCard">
    <div class="card-inner">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-col-3 flexVcenter">
            <div class="titleFixed">
              <h2>Our Systems</h2>
            </div>
            <div class="card-primary cardBlue card-next-level--">
              <h3>Simplify Your Document Workflow</h3>
              <p>Simplify your document workflow with our intuitive Document Catalogue. Our solution streamlines document management
                effortlessly, allowing you to update and validate documents with ease, ensuring accuracy and compliance every step
                of the way. Say goodbye to manual document handling and hello to a more efficient and organized approach.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" class="whiteIcon" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_ONE)">Read More</a>
                </div>
            </div>
          </div>
          <div class="items-col-8">
            <div class="leftSpace">
              <div class="row-flex">
                <div class="items-col-2">
                  <div class="card-primary blueLight mb-20 cardMarginTop">
                    <h3>Optimize Your Workflows</h3>
                    <p>Optimize your workflows with our Workflow Vault. Our solution empowers you to achieve seamless workflow management by
                      defining and adapting workflows effortlessly. By minimizing manual errors</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_TWO)">Read More</a>
                    </div>
                  </div>
                  <div class="card-primary">
                    <h3>Gain Insights into Your Data Journey</h3>
                    <p>Gain invaluable insights into your data journey with our Lineage Hub. Our solution allows you to track data origin
                      and transformations, providing you with the information needed for informed decision-making and compliance. With our
                    </p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button"  (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_THREE)">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="items-col-2 cardFlex">
                  <div class="card-primary orderOne mb-20">
                    <h3>Elevate Your Processes with AI</h3>
                    <p>Speed up contract creation and approval with our Underwriting Process solution. Ensure compliance, optimize sales
                      processes, and simplify deposit verification and contract approval. Manage customer lists efficiently and expedite
                      sales workflows.</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_FOUR)">Read More</a> 
                    </div>
                  </div>
                  <div class="card-primary blueLight orderTwo cardMarginBtm">
                    <h3>Effortless Workflow Customization</h3>
                    <p>Experience effortless configuration with our intuitive platform. Seamlessly configure workflows and monitor
                      parameters to ensure optimal performance. With our user-friendly interface, you can adapt workflows to suit your
                      specific needs and make real-time</p>
                    <div class="readMore">
                      <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_TWO)">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




  <section class="sectionDashboard">
    <div class="container-main">
      <div class="row-flex imgOrder">
        <div class="items-lg-col-5 textVCenter">
          <div class="">
            <h2>Efficient Document Management</h2>
            <div class="imgForMobile">
              <img src="assets/images/efficient-document-management.svg" alt="Efficient Document Management" width="515" height="470">
            </div>
            <p class="mb-25">Step into the future of document management with our cutting-edge solution. Seamlessly streamline
              your document workflow with our advanced Document Catalogue. Elevate your operations to new heights by effortlessly
              updating and validating documents, ensuring unparalleled accuracy and compliance.</p>
            <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
          </div>
        </div>
        <div class="items-lg-col-7">
          <div class="imgWrapper">
            <img src="assets/images/efficient-document-management.svg" alt="Efficient Document Management" width="515" height="470">
          </div>
        </div>
      </div>
    </div>  

  </section>



  <section class="sectionSecondaryCard">
    <div class="cardInner">
      <div class="container-main">
        <div class="row-flex"> 
          <div class="items-col-8 customCol70 relative">
            <div class="itemInner">
              <div class="titleFixed">
                <h2>Features</h2>
              </div>
              <div class="row-flex">
                <div class="items-col-2">
                  <div class="card-primary blueLight mb-20">
                    <h3>Interactive Visualization</h3>
                    <p>Share real-time data views across your organization with ease.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary">
                    <h3>Comprehensive Reporting</h3>
                    <p>Gain insights into process performance with visual representations.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary mb-20 forCardBg1">
                    <h3>Intuitive Interface</h3>
                    <p>Short learning curve for users at all levels of your organization.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div> -->
                  </div>
                </div>
                <div class="items-col-2">
                  <div class="card-primary blueLight forCardBg2">
                    <h3>Customizable Workflows</h3>
                    <p>Define business steps, dependencies, and adapt workflows as needed.</p>
                    <!-- <div class="readMore">
                      <a href="javascript:void(0)" role="button">Read More</a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="items-col-3 customCol30">
            <div class="card-primary cardBlue mb-20">
              <h3>Global Document Ontology</h3>
              <p>Access a vast range of document vocabulary for improved document management.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
                </div>
            </div>
            <div class="card-primary cardBlue">
              <h3>Enhanced Security</h3>
              <p>Prioritize security compliance with industry best practices and highest-level vault encryption</p>
                <div class="readMore">
                  <a href="javascript:void(0)" class="whiteIcon" role="button">Read More</a>
                </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="sectionDocumentManagement lineageBg">
    <div class="container-main">
      <div class="row-flex imgOrder">
        <div class="items-lg-col-2">
          <h2>Lineage Hub: Gain Insights into Your Data Journey</h2>
          <!-- <div class="imgForMobile">
            <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
          </div> -->
          <ul class="regular mb-30 text-white">
            <li>Tracking the origin and journey of data to its current state, with detailed reporting and export functionalities.</li>
            <li>Automated search features for discovering data across multiple systems, with indexing and de-normalization capabilities.</li>
            <li>Tracking Granular And Aggregated Data Lineage, With Drill-Back Functionality And Integration Options.</li>
            <li>And so much more...</li>
          </ul>
          <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
        </div>
        <!-- <div class="items-lg-col-2">
          <div class="imgWrapper">
            <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
          </div>
        </div> -->
      </div>
    </div>
  
  </section>


  



  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>
