import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MyStorage {
    // static encryptionKey: string = "5401710921050239";
    constructor() { }
    /**
     * getItem
     */
    public getItem(key: string): string | null {
        //  key = this.encryptUsingAES256(key);
        let value = sessionStorage.getItem(key);

        return value;
    }
    /**
     * setItem
     */
    public setItem(key: string, value: string) {
        // key = this.encryptUsingAES256(key);
        // value = this.encryptUsingAES256(value);
        sessionStorage.setItem(key, value);
    }
    /**
  * clear
  */
    public removeItem(key: string) {
        // key = this.encryptUsingAES256(key);
        sessionStorage.removeItem(key);
    }
    /**
     * clearAll
     */
    public clear() {
        sessionStorage.clear();
    }

    public clearByKey(key: string) {
        sessionStorage.removeItem(key);
    }

    // public encryptUsingAES256(decrypted: any) {
    //     if (!MyStorage.encryptionKey)
    //         return decrypted;

    //     if (decrypted && (typeof decrypted != 'string')) {
    //         decrypted = decrypted.toString();
    //     }

    //     let encrypted = CryptoJS.AES.encrypt(decrypted, MyStorage.encryptionKey.trim());
    //     return encrypted.toString();
    // }
    // public decryptUsingAES256(encrypted: string) {
    //     if (!MyStorage.encryptionKey)
    //         return encrypted;

    //     if (encrypted) {
    //         //encrypted = encrypted.substr(0, encrypted.length-1);
    //         let decrypted = CryptoJS.AES.decrypt(encrypted, MyStorage.encryptionKey.trim());
    //         return decrypted.toString(CryptoJS.enc.Utf8);
    //     }
    //     else {
    //         return encrypted;
    //     }
    // }
}
