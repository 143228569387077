
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorMessages } from '@constants';

import * as moment from 'moment';
import { DataHoldingService } from './data-holding.service';
// import { isString, isNumber } from 'util';



export function onlyNumeric(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v: number = +control.value;
            if (!v) {
                return null;
            }
            let numberInString = v.toString();
            if (isNaN(v)) {
                return { 'onlyNumericError': ErrorMessages.onlyNumeric }
            }
            // else if (v < 0) {
            //     return { 'negativeNumberError': ErrorMessages.negativeNumberError }
            // }
            else if (v > 0) {

                if (numberInString.length < minLength) {
                    return { 'minLengthError': "length can not be less than " + minLength }
                }
                else if (numberInString.length > maxLength) {
                    return { 'maxLengthError': "length can not be more than " + maxLength }
                }

            }
        } catch (error) {
            return { 'minLengthError': "length can not be less than " + minLength }
        }

        return null;

    }

}

export function checkRadioButtonSelected() {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v: any = control.value;
            if (v == null || v == undefined)
                return null;
            if (v == true || v == false)
                return null;
            else
                return { 'radionButtonSelected': ErrorMessages.errorNotSelectedRadioButton }
        } catch (error) {
            return { 'radionButtonSelected': ErrorMessages.errorNotSelectedRadioButton }
        }
    }
}



export function minMaxLengthValidator(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v = control.value ? control.value : "";
            let numberInString = v.toString();
            if (numberInString.length == 0)
                return null;

            if ((numberInString.length < minLength) && (+numberInString != 0) && (numberInString)) {
                return { 'minLengthError': "length can not be less than " + minLength + " characters" }
            }
            else if ((numberInString.length > maxLength) && (+numberInString != 0)) {
                return { 'maxLengthError': "length can not be more than " + maxLength + " characters" }
            }
        } catch (error) {
            return { 'minLengthError': "length can not be less than " + minLength + " characters" }
        }
        return null;

    }

}
export function minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v: number = control.value;
            if (!v)
                return null;
            let numberInString = v.toString();
            if ((numberInString.length < minLength) && (+numberInString != 0) && (numberInString)) {
                return { 'minLengthError': "length can not be less than " + minLength }
            }
            if ((control.value != undefined) && (control.value != null) && (control.value != '') && (+numberInString == 0)) {
                return { 'minLengthError': "length can not be less than " + minLength }
            }
            // else if ((numberInString.length > maxLength) && (+numberInString !=0)) {
            //     return { 'maxLengthError': "length can not be more than " + minLength }
            // }
        } catch (error) {
            return { 'minLengthError': "length can not be less than " + minLength }
        }
        return null;

    }

}

export function validateDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            if (control && control.value) {
                const value = control.value;
                let momentDate = moment(value, DataHoldingService.dateFormat, true);
                if (!momentDate.isValid()) {
                    return { 'dateValidator': true };
                }
                else if (momentDate.isBefore(DataHoldingService.minDate)) {
                    // console.log(minDate)
                    return { 'dateValidator': true };
                }
                else if (momentDate.isAfter(DataHoldingService.maxDate)) {
                    // console.log(maxDate)
                    return { 'dateValidator': true };
                }
                else
                    return null;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }
}


export function validateDateAndTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            if (control && control.value) {
                const value = control.value;
                let momentDate = moment(value, DataHoldingService.dateFormatWithTime, true);
                if (!momentDate.isValid()) {
                    return { 'dateValidator': true };
                }
                else if (momentDate.isBefore(DataHoldingService.minDate)) {
                    // console.log(minDate)
                    return { 'dateValidator': true };
                }
                else if (momentDate.isAfter(DataHoldingService.maxDate)) {
                    // console.log(maxDate)
                    return { 'dateValidator': true };
                }
                else
                    return null;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }
}

export function formArrayMinLength(minLength: number): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
        const controlArray = control as FormArray;
        try {
            if (controlArray.length >= minLength) {
                return null;
            } else {
                return { 'minLengthError': "Select some values" }
            }
        } catch (error) {
            return { 'minLengthError': "Select some values" }
        }
    }
}

export function requiredField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v: any = control.value;
            if (v == null) {
                return { 'requiredField': ErrorMessages.errorEmpty }
            }
            v = control.value.toString().trim();
            if ((v)) {
                if (v <= 0) {
                    return { 'requiredField': ErrorMessages.errorEmpty }
                }
            } else if (!v || v.length <= 0) {
                return { 'requiredField': ErrorMessages.errorEmpty }
            }
        } catch (error) {
            return { 'requiredField': ErrorMessages.errorEmpty }
        }
        return null;
    }
}

export function requiredFieldWithZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;
        try {
            if (!v || v.length <= 0) {
                return { 'zeroError': ErrorMessages.zeroError }
            }
        } catch (error) {
            return { 'zeroError': ErrorMessages.zeroError }
        }
        return null;
    }
}

export function pattern(pattern: any, errorMsg: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            let v: string = control.value ? control.value.toString() : "";
            if (v.length == 0)
                return null;
            if (v != null && v != "") {
                v = (v) ? v : v.toString();
                if (!v || !v.match(pattern)) {
                    return { 'pattern': errorMsg }
                }
            }
        } catch (error) {
            return { 'pattern': errorMsg }
        }
        return null;
    }
}


export function getErrorMsgArray(formGroup: FormGroup) {
    let errorsArray: any[] = [];
    console.log("form group custom")
    console.log(formGroup);
    Object.keys(formGroup.controls).forEach(parentKey => {
        let valueForParentKey = formGroup.get(parentKey);
        if (valueForParentKey instanceof FormArray) {
            for (let index = 0; index < valueForParentKey.length; index++) {
                const element: any = valueForParentKey.controls[index];
                if (!element.valid) {
                    let array = getErrorMsgArray(element);
                    if (array && array.length > 0)
                        errorsArray = errorsArray.concat(array);
                }
            }
        } else {
            let errors = formGroup.get(parentKey)!.errors;
            if (errors) {
                for (let key in errors) {
                    let error = errors[key];
                    if (error != true) {
                        errorsArray.push(getErrorString(parentKey) + ' ' + getFieldName(parentKey)?.toLowerCase());
                        // errorsArray.push(formatFieldName(parentKey) + ' ' + errors[key]);
                    }
                }
            }
        }

    });

    return errorsArray;
}

export function passwordConfirming(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        if (control.get('password')!.value !== control.get('confirmPassword')!.value) {
            return { invalid: true };
        }
        return null;
    }
}

export function checkBoolean() {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;
        if (v != true) {
            return { 'checkBoolean': ErrorMessages.errorBooleanCheck }
        }
        return null;
    }
}

export function anyDropdownSelection() {
    return (control: AbstractControl): ValidationErrors | null => {
        let v: any = control.value;
        if (v == null) {
            return { 'checkBoolean': ErrorMessages.errorBooleanCheck }
        }
        return null;
    }
}

export function minValidation(min: number): ValidatorFn | null {
    return (control: AbstractControl) => {
        let val: number = control.value;
        if (val != null && val != undefined) {
            if (val < min) {
                return { 'minLengthError': "value can not be less than " + min }
            }
            else
                return null;
        }
        else
            return { 'minLengthError': "value can not be less than " + min };
    };
}

export function minMaxValidation(min: number, max: number): ValidatorFn | null {
    return (control: AbstractControl) => {
        let val: number = control.value;
        if (val) {
            if (val < min) {
                return { 'minLengthError': "value can not be less than " + min }
            } else if (val > max) {
                return { 'minLengthError': "value can not be more than " + max }
            }
            else
                return null;
        }
        else
            return { 'minLengthError': "value can not be less than " + min };;
    };
}


export function getErrorObjList(formGroup: FormGroup) {
    let errorsArray: any[] = [];
    let errorsKeyList: any[] = [];
    console.log(formGroup);
    Object.keys(formGroup.controls).forEach(parentKey => {
        let valueForParentKey = formGroup.get(parentKey);
        if (valueForParentKey instanceof FormArray) {
            for (let index = 0; index < valueForParentKey.length; index++) {
                const element: any = valueForParentKey.controls[index];
                if (!element.valid) {
                    let array = getErrorMsgArray(element);
                    if (array && array.length > 0)
                        errorsArray = errorsArray.concat(array);
                }
            }
        } else {
            let errors = formGroup.get(parentKey)!.errors;
            if (errors) {
                for (let key in errors) {
                    let error = errors[key];
                    if (error != true) {
                        errorsKeyList.push(parentKey)
                    }
                }
            }
        }

    });

    return errorsKeyList;
}

function formatFieldName(str: string) {

    var split = str.split(/(?=[A-Z])/);

    var actualString = "";
    for (let i = 0; i < split.length; i++) {
        let subString = split[i];
        if (subString.toLowerCase() != 'fk' && subString.toLowerCase() != 'id') {
            if (i == 0)
                actualString += subString[0].toUpperCase() + subString.slice(1);
            else
                actualString += " " + subString[0].toUpperCase() + subString.slice(1);;
        }
    }
    return actualString;
}

function getErrorString(key: string) {


    switch (key.toLowerCase()) {
        case 'firstname': case 'surname': case 'postcode': case 'county':
            return ErrorMessages.errorInputEmpty;
        case 'preferredtype': case 'drivinglicence':
            return ErrorMessages.errorSelectEmpty;
        case 'yearsataddress':
            return ErrorMessages.errorSelectWithoutA;
        case 'employmentstatus':
            return ErrorMessages.errorSelectAnEmpty;
        case 'dateofbirth': case 'contactnumber': case 'email':
        case 'residentialstatus':
            return ErrorMessages.errorValidEmpty;
        case 'addressline':
            return ErrorMessages.errorEnterSelectValid;
        case 'iscurrentlybankrupt':
            return ErrorMessages.errorBankruptcy;
        case 'term':
            return ErrorMessages.errorTerms;
        default:
            return "";
            break;
    }
}

function getFieldName(key: string) {
    switch (key.toLowerCase()) {
        case 'preferredtype':
            return 'preferred vehicle type';
        case 'email':
            return 'email address';
        case 'dateofbirth':
            return 'date of birth';
        case 'drivinglicence':
            return 'licence type';
        case 'addressline':
            return 'address';
        case 'yearsataddress':
            return 'time at address';
        case 'employmentstatus':
            return 'occupation status';
        case 'contactnumber':
            return 'phone number';
        case 'iscurrentlybankrupt': case 'term':
            return '';
        default:
            return key;
    }
}