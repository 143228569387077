import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.css']
})
export class CookiesPolicyComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CookiesPolicyComponent) {  
  context.title = 'Cookies Policy - SignitiveAi';
  context.description = 'Contact SignitiveAi for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'contact us, SignitiveAi, technology solutions, consulting services, IT product development, modernization, digital transformation';
  context.seoTags();
}
