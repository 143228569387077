import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b15622',
  templateUrl: './blog-details-b15622.component.html',
  styleUrls: ['./blog-details-b15622.component.css']
})
export class BlogDetailsB15622Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB15622Component) {  
  context.title = 'Good Practices in IT Product Development | Best Practices for Success';
  context.description = 'Learn about the essential best practices for successful IT product development, including agile approaches, user experience, data-driven decision-making, and collaboration';
  context.keywords = 'IT product development, agile approach, user experience, data-driven decision-making, quality assurance, collaboration, continuous improvement, customer needs, value proposition, technical expertise, competitive market';
  context.seoTags();
}

