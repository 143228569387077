import { Injectable } from '@angular/core';

import { MyStorage } from './myStorage';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataHoldingService {

  public static dateFormat: string = 'YYYY-MM-DD';
  public static dateFormatWithTime: string = 'YYYY-MM-DD HH:mm:ss.SSS';
  public uiDateFormat: string = 'MM-DD-YYYY';
  public static dateMinMax: number = 100;
  public static minDate = moment().add(-DataHoldingService.dateMinMax, 'years');
  public static maxDate = moment().add(+DataHoldingService.dateMinMax, 'years');
  constructor(private myStorage: MyStorage) { }

}
