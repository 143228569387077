<section class="commonSection topMargin pt-20">
    <div class="container-main">

        <div class="row-flex">
            <div class="items-lg-col-8">
                <div class="">
                  <h1>Java Developer</h1>
                </div>
              
                <div class="job-details">
                  <ul>
                    <li>Location: <span>India</span></li>
                    <li>Reports to: <span>Project Manager</span></li>
                    <li>Salary: <span>Competitive</span></li>
                    <li>Update On: <span>24 Jan, 2023</span></li>
                  </ul>
                </div>
              
                <div class="work-mode">
                  <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>
              </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
              <app-follow-us-on></app-follow-us-on>
             </div>

            <div class="items-lg-col-8 mb-0">
                <hr class="border mb-15">

                <p class="lg-text">We are looking for a Java Developer with 2-3 years of core development experience.</p>

                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black">Skill Required</h2>
                    <p class="mb-5">Required Skill Set</p>
                    <ul class="regular">
                      <li>Java 8/ Java 11</li>
                      <li>Spring boot</li>
                      <li>Hibernate</li>
                      <li>Kafka</li>
                      <li>Jenkins</li>
                      <li>Git</li>
                      <li>Good understanding of Rest APIs</li>
                      <li>Good knowledge of SQL</li>
                      </ul>
                  </div>
            
                  <div class="">
                    <p class="mb-5">Desirable Skills:</p>
                    <ul class="regular">
                      <li>Oracle/Postgres/Couchbase/DB2</li>
                      <li>Go</li>
                      <li>Splunk</li>
                      <li>CI/CD tooling</li>
                      <li>Microservice</li>
                      </ul>
                  </div>
            
                  <div class="">
                    <h2 class="text-black">Salary and Benefits</h2>
                    <ul class="regular">
                      <li>Competitive salary depending on skills and experience</li>
                      <li>Flexible working hours</li>
                      <li>Remote working available for this role</li>
                    </ul>
                  </div> 
            </div>

        </div>
    </div>
</section>

<section class="gradientTopTo">
    <section class="commonSectionInner marginNone border-b">
        <div class="container-main">
            <div class="mb-60">
              <app-apply-form></app-apply-form>
            </div>
            <!-- Why Work with Us shared component -->
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<!--work with starts-->
<app-contact-form></app-contact-form>