
<!-- main container beginning here -->
<section class="section-merfTop topPadding">
    <div class="innerBanner-wp">
      <div class="container-main">
        <div class="row-flex">
          <div class="items-lg-col-8">
            <div class="banner-content">
              <h1>Transform Car Finance Operations: Streamline, Secure, and Stay Ahead</h1>
              <p class="mb-30">Empower Your Car Finance Operations with Comprehensive Data Management</p>
            <!-- <ul>
              <li>Cars</li>
              <li>Customers</li>
              <li>Contracts</li>
            </ul> -->
            <div class="btnFlex">
              <button type="button" class="btn btn-rounded-white" aria-label="Request Demo">Request Demo</button>
              <button type="button" class="btn btn-rounded-navyBlue" aria-label="View Features">View Features</button>
            </div>
            </div>  
          </div>
          <div class="items-lg-col-3">
            <div class="img-wp">
              <img src="assets/images/merf-banner.webp" alt="An Integrated System for your 3C's" width="263" height="563"
                onerror="this.src='assets/images/merf-banner.png'">
            </div>
          </div>
        </div>
        <div class="position-carIcon">
          <img src="assets/images/car-img.webp" alt="car img" width="527" height="144"
                onerror="this.src='assets/images/car-img.png'">
        </div>      
      </div>
    </div>
  </section>

  <section class="section-details">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-1">
          <div class="card-details grayBg">
            <div class="items">
              <p>Customization and Scalability</p>
            </div>
            <div class="items">
              <p>Advanced Reporting and Analytics </p>
            </div>
            <div class="items">
              <p>Customer Portal and Self-service Options</p>
            </div>
            <!-- <div class="items">
              <p>A scalable cloud-based CRM system </p>
            </div> -->
          </div>
        </div>
      </div>      
    </div>         
</section>

<section class="sectionPrimaryCard">
  <div class="card-inner">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-3 flexVcenter">
          <div class="titleFixed">
            <h2>Our Systems</h2>
          </div>
          <div class="card-primary cardBlue card-next-level--">
            <h3>A Unified Platform for Enhanced Efficiency and Security</h3>
            <p>Your All-in-One Solution for Streamlined Car Finance Operations. Securely manage data across departments, track vehicle and customer journeys, and ensure error-free processes from acquisition to contract creation. With advanced features like repossession management and lead tracking, stay ahead while safeguarding data privacy.</p>
          </div>
        </div>
        <div class="items-col-8">
          <div class="leftSpace">
            <div class="row-flex">
              <div class="items-col-2">
                <div class="card-primary blueLight mb-20 cardMarginTop">
                  <h3>Simplify Post-Contract Activities</h3>
                  <p>Effortlessly manage post-contract activities with our Collection System. Our solution provides customers with access to a dedicated portal for payments and contract details, ensuring seamless communication and transaction tracking. </p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_ONE)">Read More</a>
                  </div>
                </div>
                <div class="card-primary">
                  <h3>Gain Actionable Insights</h3>
                  <p>Unlock actionable insights with our customizable Reporting System. Track performance metrics, key indicators, and make informed decisions based on comprehensive data analysis. Drive business growth through data-driven decision-making and strategic planning.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_TWO)">Read More</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2 cardFlex">
                <div class="card-primary orderOne mb-20">
                  <h3>Expedite Contract Approval</h3>
                  <p>Speed up contract creation and approval with our Underwriting Process solution. Ensure compliance, optimize sales processes, and simplify deposit verification and contract approval. Manage customer lists efficiently and expedite sales workflows.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_THREE)">Read More</a> 
                  </div>
                </div>
                <div class="card-primary blueLight orderTwo cardMarginBtm">
                  <h3>Optimize Procurement Processes</h3>
                  <p>Improve inventory control and cost optimization with Purchase Management. Streamline asset acquisition and cost management, enhancing procurement processes for better financial management. Optimize purchase processes to drive operational efficiency.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_FOUR)">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="sectionDashboard">
  <div class="container-main">
    <div class="row-flex imgOrder">
      <div class="items-lg-col-5 textVCenter">
        <div class="">
          <h2>Collection Dashboard</h2>
          <div class="imgForMobile">
            <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
          </div>
          <p class="mb-25">Effortlessly manage post-contract activities with our Collection System. Our solution provides
            customers with access to a dedicated portal for payments and contract details, ensuring seamless communication and
            transaction tracking. Stay organized and responsive with streamlined payment management tools</p>
          <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
        </div>
      </div>
      <div class="items-lg-col-7">
        <div class="imgWrapper largeImg">
          <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="container-main">
    <div class="row-flex">
      <div class="items-col-5">
        <div class="content-wp">
          <h2>Dynamic Dashboard</h2>
          <p class="mb-25">Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I'm a great
            place for you to tell a story and let your users know a little more about you. </p>
          <button type="button" class="btn btn-primary btn-rounded-blue2" aria-label="Request Demo">Request Demo</button>
        </div>
      </div>
      <div class="items-col-7">
        <div class="graph-wp">
          <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
        </div>
      </div>
    </div>
  </div> -->
</section>



<!-- <div class="container-main">
  <div class="row-flex imgOrder">
    <div class="items-lg-col-2 textVCenter">
      <div class="">
        <h2>See all Applications in One Place</h2>
        <div class="imgForMobile">
          <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
        </div>
        <p class="mb-30">
          Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a
          story and let your users know a little more about you.
        </p>
        <button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue" aria-label="Request Demo">Request
          Demo</button>
      </div>
    </div>
    <div class="items-lg-col-2">
      <div class="imgWrapper">
        <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
      </div>
    </div>
  </div>
</div> -->



<section class="sectionSecondaryCard">
  <div class="cardInner">
    <div class="container-main">
      <div class="row-flex"> 
        <div class="items-col-8 customCol70 relative">
          <div class="itemInner">
            <div class="titleFixed">
              <h2>Features</h2>
            </div>
            <div class="row-flex">
              <div class="items-col-2">
                <div class="card-primary blueLight mb-20">
                  <h3>Collection Dashboard</h3>
                  <p>Track arrear payments and manage customer payment details.
                    Streamline follow-up processes for outstanding payments.
                  </p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_ONE)">Read More</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary">
                  <h3>IMS</h3>
                  <p>Resolve issues promptly with our Incident Management System. Maximize operational efficiency by streamlining incident...</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_FIVE)">Read More</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary mb-20 forCardBg1">
                  <h3>Customer Portal</h3>
                  <p>Provide customers with a dedicated website for payments and contract-related information.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_SIX)">Read More</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary blueLight forCardBg2">
                  <h3>Reporting</h3>
                  <p>Create custom reports by selecting pre-existing categories and sub-categories. Modify data, add filters, and
                    share reports securely with others.
                  </p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_TWO)">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="items-col-3 customCol30">
          <div class="card-primary cardBlue mb-20">
            <h3>Underwriting</h3>
            <p>Speed up contract creation and approval with our Underwriting Process solution. Ensure compliance, optimize sales
              processes, and simplify deposit verification and contract approval. Manage customer lists efficiently and expedite
              sales workflows.</p>
              <div class="readMore">
                <a href="javascript:void(0)" class="whiteIcon" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_THREE)">Read More</a>
              </div>
          </div>
          <div class="card-primary cardBlue">
            <h3>Garage</h3>
            <p>Optimize vehicle management and maintenance process with our garage module which includes app for Worker and
              client, as well as the admin module which controls and manages both entities.</p>
              <div class="readMore">
                <a href="javascript:void(0)" class="whiteIcon" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_SEVEN)">Read More</a>
              </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</section>


<section class="sectionDocumentManagement underwritingBg">
  <div class="container-main">
    <div class="row-flex imgOrder">
      <div class="items-lg-col-2">
        <h2>Underwriting: Expedite Contract Approval</h2>
        <!-- <div class="imgForMobile">
          <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
        </div> -->
        <ul class="regular mb-30 text-white">
          <li>Expedite Contract Creation and Approval with Our Underwriting Solution</li>
          <li>Ensure Compliance and Risk Management Effortlessly</li>
          <li>Optimize Sales Processes With Efficient Customer List Management</li>
          <li>Simplify Deposit Verification and Contract Approval</li>
        </ul>
        <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
      </div>
      <!-- <div class="items-lg-col-2">
        <div class="imgWrapper">
          <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
        </div>
      </div> -->
    </div>
  </div>

</section>


<!--work with starts-->
<app-contact-form></app-contact-form>

  
