import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  navigateWithReplaceUrl(path: string) {
    this.router.navigate([path], { replaceUrl: true });
  }

  navigateByUrl(path: string) {
    this.router.navigateByUrl(path);
  }


  navigateWithoutReplaceUrl(path: string) {
    this.router.navigate([path]);
  }

  navigateWithParamsWithoutRelation(path: string, params: any) {
    this.router.navigate([path], { queryParams: params });
  }

  navigateWithParamsReplaceUrl(path: string, params: any) {
    this.router.navigate([path], { queryParams: params, replaceUrl: true });
  }

  navigateWithReplaceUrlForParentRoutes(path: string) {
    this.router.navigate([path], { replaceUrl: true, relativeTo: this.activeRoute.firstChild });
  }

  navigateWithoutReplaceUrlForParentRoutes(path: string) {
    this.router.navigate([path], { relativeTo: this.activeRoute.firstChild });
  }

  navigateWithParamsForParentRoutes(path: string, params: any) {
    this.router.navigate([path], { queryParams: params, relativeTo: this.activeRoute.firstChild });
  }




  public showErrorSnackBar(message: string) {
    //  this.messageService.add({ severity: 'error', detail: message, life: 3000 });
  }

  public showSuccessSnackBar(message: string) {
    //  this.messageService.add({ severity: 'success', detail: message, life: 3000 });
  }


}
