import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b22223',
  templateUrl: './blog-details-b22223.component.html',
  styleUrls: ['./blog-details-b22223.component.css']
})
export class BlogDetailsB22223Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB22223Component) {  
  context.title = 'Applications of Edge Computing in Businesses | SignitiveAi';
  context.description = 'Explore how edge computing is being used in businesses to improve speed, accuracy, and performance for IoT, video analytics, AR and VR, retail analytics, and predictive maintenance applications';
  context.keywords = 'edge computing, IoT, video analytics, augmented reality, virtual reality, retail analytics, predictive maintenance, business operations, real-time data processing, improved performance, reduced latency, faster response times, cost savings';
  context.seoTags();
}

