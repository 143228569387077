import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b301122',
  templateUrl: './blog-details-b301122.component.html',
  styleUrls: ['./blog-details-b301122.component.css']
})
export class BlogDetailsB301122Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB301122Component) {  
  context.title = 'Cloud-Native Development in Product Engineering: Benefits and Best Practices';
  context.description = 'Learn about cloud-native development, an approach that enables businesses to build and deliver software applications faster and more efficiently. Discover the benefits of cloud-native development, including improved scalability, resilience, and customer experience. Explore best practices for cloud-native development in product engineering.';
  context.keywords = 'cloud-native development, IT product development, software applications, cloud computing, scalability, resilience, efficiency, customer experience, best practices, DevOps, Agile methodologies';
  context.seoTags();
}

