import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/core';
import { FeaturesEntryComponent } from './components';
import { FeaturesRoutingModule } from './features-routing.module';
import { SharedModule } from '@modules/shared';
import { AboutModule } from './modules';
import { BlogsModule } from './modules/blogs/blogs.module';
import { ContactModule } from './modules/contact/contact.module';
import { CookiesModule } from './modules/cookies/cookies.module';
import { PrivacyModule } from './modules/privacy/privacy.module';
import { TermsModule } from './modules/terms/terms.module';
import { SolutionsModule } from './modules/solutions/solutions.module';
import { CareersModule } from './modules/careers/careers.module';

@NgModule({
  declarations: [
    FeaturesEntryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    FeaturesRoutingModule,
    AboutModule,
    BlogsModule,
    ContactModule,
    CookiesModule,
    PrivacyModule,
    TermsModule,
    SolutionsModule,
    CareersModule,
  ]
})
export class FeaturesModule { }
