<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Optimize Procurement Processes</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Improve inventory control and cost optimization with Purchase Management. Streamline asset acquisition and cost
                    management, enhancing procurement processes for better financial management. Optimize purchase processes to
                    drive operational efficiency.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/apprentice-engineer.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/apprentice-engineer.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/optimization-icon.svg" alt="Improve Inventory " width="90" height="90">
                        <p>Improve Inventory Control and Cost Optimization</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/streamline-icon.svg" alt="Streamline Asset" width="90" height="90">
                        <p>Streamline Asset Acquisition and Cost Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/enhance-procurement-icon.svg" alt="Enhance Procurement" width="90" height="90">
                        <p>Enhance Procurement Processes with Our Solution</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/optimize-purchase-icon.svg" alt="Optimize Purchase" width="90" height="90">
                        <p>Optimize Purchase Processes for Better Financial Management</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Utilize real-time inventory tracking to prevent stockouts and reduce carrying costs.</li>
                            <li>Analyze purchasing patterns to identify cost-saving opportunities and negotiate better deals with suppliers</li>
                            <li>Streamline asset acquisition processes and manage costs efficiently.</li>
                            <li>Automate procurement workflows from requisition to receipt to reduce manual errors and delays.</li>
                            <li>Enhance procurement processes with our solution to drive efficiency.</li>
                            <li>Simplify vendor management and streamline communication for faster procurement cycles.</li>
                            <li>Implement approval workflows and spend controls to prevent overspending and unauthorized purchases.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-four.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>